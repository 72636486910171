import {
  Container,
  Grid,
  Box,
  Typography,
} from '@mui/material'
import _ from 'lodash'

import { IPageAnswersInfo, IQuestionAnswersInfo } from '@/Models/answer'
import { QuestionType } from '@/Models/page'
import { StickyTitle } from '@/Pages/Admin/SurveyInfoPage/AnswerTab/Components/StickyTitle'
import { DiagramQuestionView } from '@/Pages/Admin/SurveyInfoPage/AnswerTab/Components/DiagramQuestionView'
import { TableQuestionView } from '@/Pages/Admin/SurveyInfoPage/AnswerTab/Components/TableQuestionView'
import { htmlToTextWithNoImage } from '@/Helpers/htmlToTextCustom'
import htmlSafeParse from '@/Helpers/htmlSafeParseHelper'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnAnswerTab = bem()('answer-tab')

export enum DisplayMode {
  Admin,
  Expert,
  User,
}

// TODO: улучшить тайпинги
interface IPageAnswers {
  displayMode: DisplayMode
  pageAnswersInfo: IPageAnswersInfo
  order: number
  isTableViewMode: boolean
  setIsTableViewMode: (isTableViewMode: boolean) => void
}

export interface IPage {
  id: string
  staticId: string
  surveyId: string
  pageId: string
  title: string
  description: string
  category: string
  serviceId: string
  imageId: string
  isDeleted: boolean
  isDeprecated: boolean
  isRequired: boolean
  order: number
  questions: any[]
  surveyVersion: number
  version: number
}

export enum AnswersExtraDataTypes {
  Comments,
  CustomVariant,
}

export const PageAnswers = ({
  displayMode,
  pageAnswersInfo,
  order,
  isTableViewMode,
  setIsTableViewMode,
}: IPageAnswers) => {
  const isFullView =
    displayMode === DisplayMode.Admin || displayMode === DisplayMode.Expert
  const rowTypeQuestions = [QuestionType.text, QuestionType.attachment]

  return (
    <Grid item xs={12} className={cnAnswerTab('answers-page')}>
      <Box
        display='flex'
        paddingY={1}
        flexDirection='row'
      >
        <Typography variant='subtitle1' marginLeft='20px' marginRight='20px'>
          Страница {order}
        </Typography>
        <Typography variant='h6' fontWeight='700' marginRight='40px' className={cnAnswerTab('headers')}>
          {pageAnswersInfo.pageTitle}
        </Typography>
      </Box>
      {isFullView && (
        <StickyTitle
          isTableViewMode={isTableViewMode}
          setIsTableViewMode={setIsTableViewMode}
        />
      )}
      <Container maxWidth={false}>
        <Box display='flex' flexDirection='column' marginBottom={2}>
          {pageAnswersInfo.questionAnswers.map((question: IQuestionAnswersInfo, questionIdx: number) => (
            <>
              <Box key={questionIdx} marginY={2}>
                <Typography className={cnAnswerTab('question-text')} variant='h6' gutterBottom>
                  {htmlSafeParse(htmlToTextWithNoImage(question.questionText))}
                </Typography>
                {(function() {
                  const isEmpty = question.options.length && question.additionInfo.headers.length > 0 && question.comments.length > 0
                  const isRowTypeQuestions = rowTypeQuestions.includes(question.questionType)

                  if (isRowTypeQuestions && !isFullView) {
                    return <div className={cnAnswerTab('no-data-text')}>Отсутствует графическое представление для данного типа вопроса</div>
                  }

                  // если режим показа в виде таблиц
                  if (isTableViewMode || isEmpty) {
                    return (
                      <TableQuestionView
                        questionInfo={question}
                      />
                    )
                  } else {
                    // если режим показа в виде диаграмм
                    return (
                      <DiagramQuestionView
                        questionInfo={question}
                        displayMode={displayMode}
                        showDiagram={!isRowTypeQuestions}
                      />
                    )
                  }
                })()}
              </Box>
            </>
          ))}
        </Box>
      </Container>
    </Grid>
  )
}

export default PageAnswers
