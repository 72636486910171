import { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Typography, Skeleton } from '@mui/material'

import { ICounterWithDate } from '@/Models/counter'
import { apiGetVisitsStatisticByDate } from '@/Api/analytics'
import bem from '@/Helpers/BemClass'

import './style.scss'

Chart.register(ChartDataLabels, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend)

const cnVisitsStatistic = bem()('visits-statistic')

interface IVisitsStatistic {
  surveyId: string
}

const VisitsStatistic = ({ surveyId }: IVisitsStatistic) => {
  const [data, setData] = useState<ICounterWithDate[]>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    (async () => {
      const counters = await apiGetVisitsStatisticByDate(surveyId)
      setData(counters)
      setTotalCount(counters.map(x => x.count).reduce((a, b) => a + b, 0))
      setIsLoading(false)
    })()
  }, [surveyId])


  const getChartData = (counters?: ICounterWithDate[]) => {
    return {
      labels: counters?.map(x => (new Date(x.date)).toLocaleDateString()),
      datasets: [
        {
          label: 'Посещение опроса',
          backgroundColor: '#5969c3',
          borderColor: '#000274',
          borderWidth: 1,
          hoverBackgroundColor: '#d7d7d7',
          hoverBorderColor: '#5969c3',
          data: counters?.map(x => x.count),
        },
      ],
    }
  }

  return (
    <div className={cnVisitsStatistic()}>
      {isLoading ? <Skeleton
        variant='rectangular'
        className={cnVisitsStatistic('chart-container-skeleton')}
      /> : <div
        className={cnVisitsStatistic('chart-container')}
      >
        <Line data={getChartData(data)} options={{ maintainAspectRatio: false }}/>
      </div>}
      {isLoading ? <Skeleton
        variant='rectangular'
        className={cnVisitsStatistic('total-counter-skeleton')}
        height='24px'
        width='540px'
      /> : <Typography
        className={cnVisitsStatistic('total-counter')}
      >
        {`Посещений за период действия опроса: ${totalCount}`}
      </Typography>}
      {isLoading ? <Skeleton
        variant='rectangular'
        className={cnVisitsStatistic('total-counter-skeleton')}
        height='24px'
        width='540px'
      /> : <Typography
        className={cnVisitsStatistic('total-counter')}
      >
        {`Посещений за текущий день: ${data.length === 0 ? 0 : data[data.length - 1].count}`}
      </Typography>}
    </div>)
}

export default VisitsStatistic
