import { ChangeEvent, useCallback, useRef } from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { arrayMoveImmutable } from 'array-move'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { v4 } from 'uuid'

import { IServiceInstance } from '@/Stores/AdminStores/adminServicesStore'
import { IQuestion } from '@/Pages/Admin/EditPage/Models/pageModel'
import Error from '@/Components/Error'
import DndSortableList from '@/Components/DragAndDrop/DndSortableList'
import { QuestionType } from '@/Models/page'
import { helperText } from '@/Components/Questions/RatingQuestion'
import { ICreatePageModel } from '@/Pages/Admin/EditPage/Custom'
import Question from '@/Pages/Admin/EditPage/Components/Question'
import { AddQuestion } from '@/Pages/Admin/EditPage/Components/Question/AddQuestion'
import GvkPageHeader from '@/Pages/Admin/EditPage/Components/Page/GvkPageHeader'
import CustomPageHeader from '@/Pages/Admin/EditPage/Components/Page/CustomPageHeader'
import PageInfo from '@/Pages/Admin/EditPage/Components/Page/PageInfo'
import { checkValidationState } from '@/Helpers/validationState'

import './style.scss'

interface IPage {
  pageModel: ICreatePageModel
  service: IServiceInstance | null
  updatePage: (oldPage: ICreatePageModel, newPage: ICreatePageModel, WithChangeTracking: boolean) => void
  copyPage?: (page: ICreatePageModel) => void
  deletePage?: (page: ICreatePageModel) => void
  dragHandleProps?: DraggableProvidedDragHandleProps | undefined | null
  isGvk?: boolean
  isCardState: boolean
  isRepeatableSurveyed: boolean
  isArchived: boolean
  disableAccordion?: boolean
}

const Page = ({ pageModel, service, updatePage, copyPage, deletePage, isGvk, dragHandleProps, isCardState, isRepeatableSurveyed, isArchived, disableAccordion = false }: IPage) => {
  const questionRef = useRef<HTMLDivElement>(null)

  const updatePageField = useCallback((field: keyof ICreatePageModel, value: unknown, withChangeTracking: boolean = true) => {
    const validate = pageModel.validateResult ?? {}
    if (field === 'title') validate.titleError = false
    if (field === 'questions') validate.emptyQuestionCollectionError = false
    updatePage?.(pageModel, { ...pageModel, [field]: value, validateResult: validate }, withChangeTracking)
  }, [pageModel, updatePage])

  const handleChangeQuestion = useCallback((prevValue: IQuestion, newValue: IQuestion, withChangeTracking?: boolean) => {
    const newQuestions = [...pageModel.questions.map(x => (x !== prevValue ? x : newValue))]
    updatePageField('questions', newQuestions, withChangeTracking)
  }, [pageModel.questions, updatePageField])

  const handleAddQuestion = useCallback((prevValue: IQuestion, newValue: IQuestion, withChangeTracking?: boolean, e?: ChangeEvent<{ value: unknown }>) => {
    if (newValue.type === QuestionType.rating) {
      newValue.helperText = helperText
      newValue.options = Array.from(Array(5), (x, i) => ({ value: i.toString(), imageId: null, title: '', isCustom: false }))
    }
    updatePageField('questions', [...pageModel.questions, newValue])
    if (e?.target) e.target.value = ''
  }, [pageModel.questions, updatePageField])

  const handleCopyQuestion = useCallback((question: IQuestion) => {
    updatePageField('questions', [...pageModel.questions, { ...question, id: undefined, questionId: v4() }])

    if (questionRef.current) {
      const elementBottom = questionRef.current.getBoundingClientRect().bottom
      const offset = window.scrollY
      window.scrollTo({
        top: offset + elementBottom,
        behavior: 'smooth',
      })
    }
  }, [pageModel.questions, updatePageField])

  const handleDeleteQuestion = useCallback((question: IQuestion) => {
    updatePageField('questions', [...pageModel.questions.filter(x => x !== question)])
  }, [pageModel.questions, updatePageField])

  const handleQuestionDragEnd = useCallback((oldIndex: number, newIndex: number) => {
    updatePageField('questions', arrayMoveImmutable(pageModel.questions, oldIndex, newIndex))
  }, [pageModel.questions, updatePageField])

  const renderQuestion = useCallback((item: IQuestion, index: number, dragHandleProps: DraggableProvidedDragHandleProps | undefined | null) => {
    return <div ref={(index + 1) === pageModel.questions.length ? questionRef : undefined}><Question
      question={item}
      handleChangeQuestion={handleChangeQuestion}
      handleDeleteQuestion={handleDeleteQuestion}
      handleCopyQuestion={handleCopyQuestion}
      additionLine={false}
      draggableProvided={dragHandleProps}
      disabled={isArchived}
    /></div>
  }, [handleChangeQuestion, handleDeleteQuestion, handleCopyQuestion, isArchived])

  const { validateResult, expanded } = pageModel

  if (disableAccordion) {
    return <div className={`${(!checkValidationState(pageModel) && !expanded) ? 'error' : ''} page-info`}>
      <div className='page-info-header' style={{ backgroundColor: '#e8eaed' }}>
        {isGvk ? <GvkPageHeader/> : <CustomPageHeader
          pageModel={pageModel}
          updatePage={updatePageField}
          copyPage={copyPage}
          deletePage={deletePage}
          dragHandleProps={dragHandleProps}
          isDraggable={!isCardState}
          disabled={isArchived || isRepeatableSurveyed}
        />}
      </div>
      <div className='page-info-accordion-details'>
        <div className={`page-info-container ${isGvk && 'is-gvk'} ${((validateResult?.emptyQuestionCollectionError ?? false) || (validateResult?.titleError ?? false)) && 'error'}`}>
          <PageInfo pageModel={pageModel} service={service} updatePage={updatePageField} isGvk={isGvk} disabled={isArchived}/>
          <div className="question-info-container">
            <DndSortableList
              data={pageModel.questions}
              onDragEnd={handleQuestionDragEnd}
              renderItem={renderQuestion}
            />
            {!isArchived && <AddQuestion handleAddQuestion={handleAddQuestion}/>}
            <Error isVisible={pageModel.validateResult?.emptyQuestionCollectionError}>На странице должен быть хотя-бы один вопрос</Error>
          </div>
        </div>
      </div>
    </div>
  }

  return (
    <Accordion expanded={expanded} className={`${(!checkValidationState(pageModel) && !expanded) ? 'error' : ''} page-info page-info--accordion`} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary onClick={() => updatePageField('expanded', !expanded)}>
        {isGvk ? <GvkPageHeader/> : <CustomPageHeader
          pageModel={pageModel}
          updatePage={updatePageField}
          copyPage={copyPage}
          deletePage={deletePage}
          dragHandleProps={dragHandleProps}
          isDraggable={!isCardState}
          disabled={isArchived || isRepeatableSurveyed}
        />}
      </AccordionSummary>
      <AccordionDetails className='page-info-accordion-details'>
        <div className={`page-info-container ${isGvk && 'is-gvk'} ${((validateResult?.emptyQuestionCollectionError ?? false) || (validateResult?.titleError ?? false)) && 'error'}`}>
          <PageInfo pageModel={pageModel} service={service} updatePage={updatePageField} isGvk={isGvk} disabled={isArchived}/>
          <div className="question-info-container">
            <DndSortableList
              data={pageModel.questions}
              onDragEnd={handleQuestionDragEnd}
              renderItem={renderQuestion}
            />
            {!isArchived && <AddQuestion handleAddQuestion={handleAddQuestion}/>}
            <Error isVisible={pageModel.validateResult?.emptyQuestionCollectionError}>На странице должен быть хотя-бы один вопрос</Error>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>)
}

export default Page
