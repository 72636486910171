import { useCallback, useEffect, useState } from 'react'
import { useMount } from 'react-use'
import { useParams } from 'react-router'
import { observer } from 'mobx-react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { Button, Skeleton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { maxBy } from 'lodash'
import { arrayMoveImmutable } from 'array-move'

import { defaultImageHash } from '@/applicationConfig'
import { useMst } from '@/Stores/rootStore'
import StyledButton from '@/Components/Button'
import { Dialog, Error } from '@/Components'
import { apiGetActivePagesBySurveyId, apiSetCustomPages } from '@/Api/page'
import { IDialog } from '@/Components/Dialog'
import DndSortableList from '@/Components/DragAndDrop/DndSortableList'
import { CheckEnumIs } from '@/Helpers/enumHelper'
import { IAnswerValidationResult, QuestionType, Option } from '@/Models/page'
import { useStateWithChangeTracking } from '@/Components/useStateWithChangeTracking'
import { SurveyType } from '@/Stores/AdminStores/surveysStore'
import { isSurveyedWithMainQuestionHelper, isArchivedHelper } from '@/Helpers/isDisabledHelper'
import { htmlTagsIsEmpty } from '@/Helpers/htmlToTextCustom'
import { checkValidationState } from '@/Helpers/validationState'
import bem from '@/Helpers/BemClass'

import { EmptyPage, IPageModel, IQuestion, IQuestionValidationResult } from '../Models/pageModel'
import Page from '../Components/Page'

import './style.scss'

const cnEditPageCustom = bem()('edit-page-custom')

const updateOrderPagesHelper = (pages: ICreatePageModel[]): ICreatePageModel[] => {
  return pages.map((x, i) => ({ ...x, order: i + 1 }))
}

const normalizePageForSaveHelper = (page: IPageModel) => ({
  ...page,
  category: page.category ?? 'Прочее',
  questions: page.questions.map(x => (
    {
      ...x,
      payload: x?.payload,
      options: x.options,
    })),
})

interface IPageValidateResult {
  titleError?: boolean
  emptyQuestionCollectionError?: boolean
}

/**
 * Расширение интерфейса модели страницы
 * Добавил сюда результат валидации, т.к. сложно однозначно связать результат валидации и страницу
 * При создании страницы у нее может не быть Ид(если она не была еще сохранена на бэке),
 * а завязываться на порядок стараниц не удобно т.к. страницы могут удаляться и сортироваться
 */
export interface ICreatePageModel extends IPageModel {
  validateResult?: IPageValidateResult
  expanded: boolean
}

export interface ICustomEditPage {
  isCardState: boolean
}

const CustomEditPage = ({ isCardState }: ICustomEditPage) => {
  const { surveyId } = useParams<{ surveyId: string }>()
  const [isLoading, setIsLoading] = useState(true)
  const store = useMst().admin.surveysStore
  const trafficLightStateStore = useMst().admin.trafficLightStateStore
  const survey = store.surveys.find(x => x.surveyId === surveyId)
  const [pages, setPages, setPageWithChangeTracking, resetTracking] = useStateWithChangeTracking<ICreatePageModel[]>([])
  const [dialogState, setDialogState] = useState<IDialog | undefined>()
  const [defaultImageId, setDefaultImageId] = useState<string | null>(null)
  const [needScrollToError, setNeedScrollToError] = useState<boolean>(false)
  const isRepeatableSurveyed = isSurveyedWithMainQuestionHelper(survey)
  const isArchived = isArchivedHelper(survey)

  useMount(() => {
    (async () => {
      const data = (await apiGetActivePagesBySurveyId(surveyId)).sort((a, b) => a.order - b.order)
      setPages([...data.map(x => ({ ...x, expanded: false }))])

      setDefaultImageId(defaultImageHash)

      setIsLoading(false)
    })()
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [isLoading])

  useEffect(() => {
    if (!needScrollToError) return

    const scrollTarget = document.getElementsByClassName('error')

    if (scrollTarget.length <= 0) return

    const parent = scrollTarget.item(0)?.parentElement

    if (!parent) return

    const parentRect = parent.getBoundingClientRect()
    const parentOffset = parentRect.top - parentRect.height
    window.scrollBy({
      top: parentOffset,
      behavior: 'smooth',
    })

    setNeedScrollToError(false)
  }, [needScrollToError])

  const handlePageDragEnd = useCallback((oldIndex: number, newIndex: number) => {
    setPageWithChangeTracking(prev => updateOrderPagesHelper(arrayMoveImmutable(prev, oldIndex, newIndex)))
  }, [setPageWithChangeTracking])

  const handleUpdatePage = useCallback((oldPage: ICreatePageModel, newPage: ICreatePageModel, withChangeTracking: boolean = false) => {
    if (withChangeTracking) {
      setPageWithChangeTracking(prev => prev.map(x => { return x !== oldPage ? x : { ...newPage } }))
    } else {
      setPages(prev => prev.map(x => { return x !== oldPage ? x : { ...newPage } }))
    }
  }, [setPages, setPageWithChangeTracking])

  const handleAddPage = useCallback((page: ICreatePageModel) => {
    // Устанавливаем id для вопросов и для страницы как undefined, что-бы при сохранении в бд им были присвоенны новые id
    const newPage = {
      ...page,
      questions: [...(survey?.getMainQuestionsAsIQuestionVersion() as IQuestion[]), ...(page.questions.map(x => ({ ...x, id: undefined })))],
      order: (maxBy(pages, x => x.order)?.order ?? 0) + 1,
      id: undefined,
    }
    setPageWithChangeTracking(prev => updateOrderPagesHelper([...prev, newPage]))
  }, [survey, pages, setPageWithChangeTracking])

  const handleCopyPage = useCallback((page: ICreatePageModel) => {
    // Устанавливаем id для вопросов и для страницы как undefined, что-бы при сохранении в бд им были присвоенны новые id
    const newPage = {
      ...page,
      questions: [...(page.questions.map(x => ({ ...x, id: undefined })))],
      order: (maxBy(pages, x => x.order)?.order ?? 0) + 1,
      id: undefined,
      expanded: true,
    }
    setPageWithChangeTracking(prev => updateOrderPagesHelper([...prev.map(x => (x === page ? { ...x, expanded: false } : x)), newPage]))
  }, [pages, setPageWithChangeTracking])

  const handleDeletePage = useCallback((page: ICreatePageModel) => {
    setPageWithChangeTracking(prev => updateOrderPagesHelper(prev.filter(x => x !== page)))
  }, [setPageWithChangeTracking])

  const validateOption = (option: Option, question: IQuestion) => {
    const answerValidationResult: IAnswerValidationResult = {
      imageRequiredError: (CheckEnumIs(question.type, QuestionType.checkboxWithImage, QuestionType.radioWithImage) && ((!option.imageId) || option.value.length === 0)),
      duplicateError: question.options.filter(x => x.value === option.value).length > 1,
    }

    option.validationResult = answerValidationResult
  }

  const validateQuestion = (question: IQuestion) => {
    const questionValidationResult: IQuestionValidationResult = {
      emptyQuestionText: htmlTagsIsEmpty(question.text),
      emptyHelperText: (question.showHelperText && (question.helperText?.length ?? 0) === 0),
      emptySkipText: (question.payload?.showNotReadyToRate && !question.payload?.notReadyToRateText.trim()),
    }

    question.validationResult = questionValidationResult

    question.options.forEach((o) => validateOption(o, question))
  }

  const validatePage = (page: ICreatePageModel) => {
    const validateResult: IPageValidateResult = {
      emptyQuestionCollectionError: page.questions.length === 0,
      titleError: page.title.length === 0,
    }

    page.questions.forEach(validateQuestion)

    return { ...page, validateResult }
  }

  const hasNoError = (pages: ICreatePageModel[]) => {
    return pages.every(x => checkValidationState(x))
  }

  const validate = () => {
    const newPages = pages.map(validatePage)
    setPages(newPages)

    return hasNoError(newPages)
  }

  const handleSave = async () => {
    setIsLoading(true)
    let hasBranchingChanges = false
    if (validate()) {
      const res = pages
        .filter(x => x.questions.length !== 0)
        .map(normalizePageForSaveHelper)
      hasBranchingChanges = await apiSetCustomPages(surveyId, res)
      const data = await apiGetActivePagesBySurveyId(surveyId)
      setPages([...data])
      await trafficLightStateStore.createIfNotExistAndGet(surveyId).loadTrafficLightState()
    } else {
      setNeedScrollToError(true)
    }

    setIsLoading(false)

    if (survey?.type !== SurveyType.Repeatable && hasBranchingChanges) {
      setDialogState({
        open: true,
        handleClose: () => setDialogState(undefined),
        title: 'Некоторые правила могли перестать работать!',
        text: 'Необходимо скорректировать настройки на вкладке Ветвление',
        actions: [<Button onClick={() => setDialogState(undefined)} key="ok">ОК</Button>],
      })
    }
    resetTracking()
  }

  const renderPage = useCallback((item: ICreatePageModel, index: number, dragHandleProps: DraggableProvidedDragHandleProps | undefined | null) => {
    return <Page
      pageModel={item}
      service={null}
      updatePage={handleUpdatePage}
      copyPage={handleCopyPage}
      deletePage={handleDeletePage}
      isCardState={isCardState}
      dragHandleProps={dragHandleProps}
      isRepeatableSurveyed={isRepeatableSurveyed}
      isArchived={isArchived}
    />
  }, [handleUpdatePage, handleCopyPage, handleDeletePage, isCardState, isRepeatableSurveyed, isArchived])

  const addButton = (
    <div className={cnEditPageCustom('button', { add: true })}>
      <Button color='secondary' disabled={isLoading || !defaultImageId} fullWidth onClick={() => handleAddPage({ ...EmptyPage, imageId: defaultImageId ?? undefined, expanded: true })}>
        <AddIcon fontSize="large"/>
        <h4 className={cnEditPageCustom('button-text', { add: true })}>Добавить страницу</h4>
      </Button>
    </div>
  )

  const saveButton = (
    <div className={cnEditPageCustom('action', { 'save-button': true })}>
      <StyledButton disabled={isLoading || !defaultImageId || isArchived} onClick={handleSave} height="40px">Сохранить</StyledButton>
    </div>
  )

  return (<div className={cnEditPageCustom()}>
    {(isLoading || !defaultImageId) ? <>
      <Skeleton variant='rectangular' height={66} className={cnEditPageCustom('skeleton')} animation="wave"/>
      <Skeleton variant='rectangular' height={66} className={cnEditPageCustom('skeleton')} animation="wave"/>
    </> : <div className={cnEditPageCustom('pages-list-container')}>
      <DndSortableList
        data={pages}
        renderItem={renderPage}
        onDragEnd={handlePageDragEnd}
      />
    </div>}
    {!isArchived && !isRepeatableSurveyed && addButton}
    <div className={cnEditPageCustom('action', { save: true })}>
      <div className={cnEditPageCustom('action', { 'save-notification': true })}>
        <Error isVisible={!hasNoError(pages)}>Заполните все поля</Error>
      </div>
      {!isArchived && saveButton}
    </div>
    {!isLoading && dialogState && <Dialog {...dialogState} />}
  </div>)
}

export default observer(CustomEditPage)
