import { ChangeEvent } from 'react'
import { Checkbox, FormControlLabel, TextField } from '@mui/material'

import { IQuestionData } from '@/Pages/Admin/EditPage/Components/Question/SliderContainer'
import { CheckBoxContainer, RowContainer } from '@/Pages/Admin/EditPage/Components/Question/styled'

interface ITextContainerProps<TQuestionType extends IQuestionData> {
  handleChangeQuestion: (prevValue: TQuestionType, newValue: TQuestionType, e?: ChangeEvent<{ value: unknown }>) => void
  question: TQuestionType
  helperTextPlaceholder?: string
  helperTextLable?: string
  disabled?: boolean
  showAnonymBlock?: boolean
}

const SharedAdditionalSettings = <TQuestionType extends IQuestionData>(
  {
    handleChangeQuestion,
    question,
    helperTextPlaceholder = 'Введите текст подсказки',
    helperTextLable = 'Текст подсказки',
    disabled = false,
    showAnonymBlock = false,
  }: ITextContainerProps<TQuestionType>) => {

  const handleToggleCheckbox = (field: keyof TQuestionType) => () => {
    if (question.validationResult) {
      handleChangeQuestion(question, { ...question, [field]: !question[field], validationResult: { ...question.validationResult, emptyHelperText: false } })
    } else {
      handleChangeQuestion(question, { ...question, [field]: !question[field] })
    }
  }

  const handleEditHelperText = (e: ChangeEvent<{ value: string }>) => {
    const value = e.target.value

    if (question.validationResult) {
      handleChangeQuestion(question, { ...question, helperText: value, validationResult: { ...question.validationResult, emptyHelperText: false } }, e)
    } else {
      handleChangeQuestion(question, { ...question, helperText: value }, e)
    }
  }

  return <>
    <RowContainer>
      <CheckBoxContainer>
        <FormControlLabel
          labelPlacement='start'
          componentsProps={{ typography: { variant: 'subtitle2' } }}
          label='Подсказка'
          sx={{ margin: 0 }}
          disabled={disabled}
          control={<Checkbox
            disabled={disabled}
            checked={question.showHelperText ?? false}
            onChange={handleToggleCheckbox('showHelperText')}
          />}
        />
      </CheckBoxContainer>
      {showAnonymBlock && <CheckBoxContainer>
        <FormControlLabel
          labelPlacement='start'
          componentsProps={{ typography: { variant: 'subtitle2' } }}
          label='Анонимность'
          sx={{ margin: 0 }}
          disabled={disabled}
          control={<Checkbox
            disabled={disabled}
            checked={question.showIsAnonym ?? false}
            onChange={handleToggleCheckbox('showIsAnonym')}
          />}
        />
      </CheckBoxContainer>}
    </RowContainer>

    {question.showHelperText && <TextField
      className={question.validationResult?.emptyHelperText ? 'error' : ''}
      fullWidth
      label={helperTextLable}
      placeholder={helperTextPlaceholder}
      disabled={disabled}
      onFocus={e => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
      value={question.helperText}
      name='helperText'
      onChange={handleEditHelperText}
      error={question.validationResult?.emptyHelperText ?? false}
      variant='standard'
    />}
  </>
}

export default SharedAdditionalSettings
