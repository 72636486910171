import { ChangeEvent } from 'react'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'

import { QuestionType } from '@/Models/page'
import { CheckEnumIs } from '@/Helpers/enumHelper'
import { QuestionStyle, defaultPayload } from '@/Models/pageEdit'
import { helperText } from '@/Components/Questions/RatingQuestion'
import { IQuestion } from '@/Pages/Admin/EditPage/Models/pageModel'
import { AlignEnum, SizeEnum } from '@/Enums/ratingPayloadEnum'
import { notReadyToRateText } from '@/Helpers/globalConst'

const allQuestions = [
  QuestionType.rating,
  QuestionType.text,
  QuestionType.radio,
  QuestionType.checkbox,
  QuestionType.slider,
  QuestionType.radioWithImage,
  QuestionType.checkboxWithImage,
  QuestionType.attachment,
]

interface IQuestionTypeSelector {
  question: IQuestion
  disabled?: boolean
  onChangeQuestion: (prevValue: IQuestion, newValue: IQuestion, e?: ChangeEvent<{ value: unknown }>) => void
  allowQuestions?: QuestionType[]
}

const QuestionTypeSelector = ({ question, disabled, onChangeQuestion, allowQuestions = allQuestions }: IQuestionTypeSelector) => {
  const allQuestionNamesMap = new Map<QuestionType, string>([
    [QuestionType.rating, 'Рейтинг'],
    [QuestionType.text, 'Текст'],
    [QuestionType.radio, 'Один из множества'],
    [QuestionType.checkbox, 'Несколько из множества'],
    [QuestionType.slider, 'Шкала'],
    [QuestionType.radioWithImage, 'Изображение. Одно из множества'],
    [QuestionType.checkboxWithImage, 'Изображение. Несколько из множества'],
    [QuestionType.attachment, 'Вложение'],
  ])

  const handleChangeQuestionType = (e: SelectChangeEvent) => {
    const questionType = e.target.value as QuestionType
    if (questionType === question.type) return
    const newQuestion = { ...question, type: questionType }
    if (CheckEnumIs(questionType,
      QuestionType.text,
      QuestionType.rating,
      QuestionType.checkbox,
      QuestionType.radio,
      QuestionType.checkboxWithImage,
      QuestionType.radioWithImage)
    ) {
      newQuestion.showHelperText = false
      newQuestion.helperText = ''
      newQuestion.payload = defaultPayload
      newQuestion.options = []
      newQuestion.hasCustomAnswer = false
    }

    if (questionType === QuestionType.rating) {
      newQuestion.helperText = helperText
      newQuestion.options = Array.from(Array(5), (x, i) => ({ value: i.toString(), imageId: null, title: '', isCustom: false }))

      if (!newQuestion.payload) {
        newQuestion.payload = defaultPayload
      }

      newQuestion.payload.requireCommentValue = '2'
    }

    if (questionType === QuestionType.slider) {
      newQuestion.options = [
        { value: '0', imageId: null, title: null, isCustom: false },
        { value: '10', imageId: null, title: null, isCustom: false },
      ]
      newQuestion.payload = {
        helperEndText: 'Точно порекомендую',
        helperStartText: 'Точно не порекомендую',
        showNotReadyToRate: false,
        notReadyToRateText: notReadyToRateText,
        requireCommentValue: '5',
        isEnabledLoadFileButton: false,
        maxFileCount: 0,
        limitFileType: false,
        acceptFileTypes: [],
        adminFiles: [],
        questionStyle: QuestionStyle.Default,
        size: SizeEnum.medium,
        align: AlignEnum.left,
      }
    }
    onChangeQuestion(question, newQuestion)
  }

  return (<FormControl fullWidth variant="standard">
    <InputLabel shrink id="main-question-type-label">Тип вопроса</InputLabel>
    <Select
      labelId="main-question-type-label"
      value={question.type}
      onChange={handleChangeQuestionType}
      disabled={disabled}
    >
      {Array.from(allQuestionNamesMap.entries()).filter(([type, _]: [QuestionType, string]) => allowQuestions.includes(type))
        .map(([type, name]: [QuestionType, string], i) => <MenuItem key={i} value={type}>{name}</MenuItem>)}
    </Select>
  </FormControl>)
}

export default QuestionTypeSelector
