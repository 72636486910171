import { memo, ChangeEvent, useState } from 'react'
import { arrayMoveImmutable } from 'array-move'
import { Button, Grid } from '@mui/material'

import { IFile as INewFile } from '@/Models/file'

import { FileType, IFile } from '@/Models/page'
import { apiSetFile } from '@/Api/file'
import { IDialog } from '@/Components/Dialog'
import { Dialog } from '@/Components'
import FileItem, { DisplayFileMode } from '@/Components/FileItem'
import getTranslitWithoutSpace from '@/Helpers/transliterationHelper'

interface IAddFiles {
  files: IFile[]
  handleChangeFiles: (files: IFile[]) => void
}

const AddFiles = ({ files, handleChangeFiles }: IAddFiles) => {
  const [isLoading, setIsLoading] = useState(false)
  const maxFileSize = 30 * 1024 * 1024
  const [dialogState, setDialogState] = useState<IDialog | undefined>()

  const showMessage = (text: string) => {
    setDialogState({
      open: true,
      handleClose: () => setDialogState(undefined),
      title: 'Не удалось загрузить файл',
      text: text,
      actions: [<Button onClick={() => setDialogState(undefined)} key="ok">ОК</Button>],
    })
  }

  const handleAddFile = (file: IFile) => {
    handleChangeFiles([...files, file])
  }

  const handleDeleteAnswer = (file: IFile) => () => {
    handleChangeFiles(files.filter(x => x !== file))
  }

  const handleAnswerDragEnd = (oldIndex: number, newIndex: number) => {
    handleChangeFiles(arrayMoveImmutable(files, oldIndex, newIndex))
  }

  const handleFileAdd = async (file: INewFile) => {
    if (file.size > maxFileSize) {
      showMessage('Превышен лимит на размер файла в 30мб')
      return
    }
    setIsLoading(true)
    const fileNameWithoutSpaces = getTranslitWithoutSpace(file.fileName)
    const fileId = await apiSetFile(file)
    const newFile: IFile = { fileId: fileId, fileName: fileNameWithoutSpaces, fileType: file.contentType as FileType }
    handleAddFile(newFile)
    setIsLoading(false)
  }

  return (<div style={{ marginTop: '10px' }}>
    <Grid container spacing={2}>
      {files.map((file, i) => <Grid item key={file.fileId}>
        <FileItem
          fileId={file.fileId}
          mimeType={file.fileType ?? undefined}
          name={file.fileName}
          onDelete={handleDeleteAnswer(file)}
        />
      </Grid>)}
      {!isLoading && <Grid item>
        <FileItem
          displayFileMode={DisplayFileMode.Add}
          onInputClick={handleFileAdd}
        />
      </Grid>}
    </Grid>
    {isLoading && <FileItem
      displayFileMode={DisplayFileMode.PlaceHolder}
    />}
    {!isLoading && dialogState && <Dialog {...dialogState} />}
  </div>)
}

export default memo(AddFiles)
