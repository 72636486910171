import { IAnswerInstance, IAnswerIn, IUserPageOut } from '@/Stores/UserStores/pageStore'
import BackendAdapter from '@/Api/_adapter'

export const apiSetAnswer = async (page: IUserPageOut, answers: IAnswerInstance[], isAnonym: boolean, isConfirmed: boolean = false) => {
  try {
    const answer = {
      version: page.version,
      skipPage: false,
      saveEndExit: false,
      isConfirmed: isConfirmed,
      isAnonym: isAnonym,
      answers: answers.map(x => x.fullInfo),
    }
    return await BackendAdapter.put(`/api/surveys/${page.surveyId}/pages/${page.id}/answers`, answer)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

// TODO: это стоит выпилить и передавать флаг где надо (ручка идентична ручке выше)
export const apiSetSaveAndExitAnswer = async (page: IUserPageOut, answers: IAnswerInstance[], isAnonym: boolean) => {
  try {
    const surveyId = page.surveyId
    const pageId = page.id
    const answer = {
      version: page.version,
      skipPage: false,
      saveEndExit: true,
      isConfirmed: true,
      isAnonym: isAnonym,
      answers: answers.map(x => x.fullInfo),
    }
    return await BackendAdapter.put(`/api/surveys/${surveyId}/pages/${pageId}/answers`, answer)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiSetSkipPageAnswer = async (page: IUserPageOut, isAnonym: boolean, isConfirmed: boolean = false) => {
  try {
    const surveyId = page.surveyId
    const pageId = page.id
    const answer = {
      version: page.version,
      skipPage: true,
      saveEndExit: false,
      isConfirmed: isConfirmed,
      isAnonym: isAnonym,
      answers: [],
    }
    return await BackendAdapter.put(`/api/surveys/${surveyId}/pages/${pageId}/answers`, answer)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetAnswer = async (pageId: string): Promise<IAnswerIn[]> => {
  try {
    return await BackendAdapter.get(`/api/page/${pageId}/answers`)
      .then((res) => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetAllAnswersBySurvey = async (surveyId: string) => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/answers`)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetAnswersBySurveyPagesWithUserAnswers = async (surveyId: string) => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/answers`, {
      params: {
        byPassedPages: true,
      },
    }).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetAnswersBySurveyVersions = async (surveyId: string, versions: number[]) => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/answers`, {
      params: {
        versions,
      },
    }).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiDeleteAnswersByPages = async (surveyId: string, pageIds: string[]) => {
  try {
    return await BackendAdapter.delete(`/api/answers/survey/${surveyId}`, {
      params: {
        pageIds,
      },
    }).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}
