import { cast, flow, Instance, types as t } from 'mobx-state-tree'
import { take, orderBy } from 'lodash'

import { QuestionType } from '@/Models/page'
import { apiGetActivePagesBySurveyId } from '@/Api/page'
import { Payload } from '@/Stores/sharedModels/models'

export interface IPageInstance extends Instance<typeof Page> {
}

export interface IQuestionInstance extends Instance<typeof Question> {
}

const Option = t.model({
  value: t.string,
  imageId: t.optional(t.maybeNull(t.string), null),
  // отрефакторить сделать 1 общий опшенс (@Vidrochka)
  title: t.maybeNull(t.string),
  isCustom: t.boolean,
})

export const Question = t.model({
  id: t.identifier,
  text: t.string,
  type: t.enumeration<QuestionType>(Object.values(QuestionType)),
  options: t.maybe(t.array(Option)),
  isMain: t.boolean,
  isRequired: t.boolean,
  hasCustomAnswer: t.boolean,
  payload: t.maybe(Payload),
}).views(self => ({
  get optionValues() {
    return self.options?.map(x => x.value)
  },
}))

export const Page = t.model({
  id: t.identifier,
  title: t.string,
  description: t.maybeNull(t.string),
  questions: t.array(Question),
  serviceId: t.maybeNull(t.string),
  order: t.number,
})

export const PageStore = t.model({
  pages: t.array(Page),
}).views(self => ({
  getPage: (pageId?: string) => {
    return self.pages.find(x => x.id === pageId)
  },

  getQuestion: (pageId?: string, questionId?: string) => {
    const page = self.pages.find(x => x.id === pageId)
    return page?.questions.find(x => x.id === questionId)
  },

  getPagesBeforeTargetPage: (pageId: string) => {
    const targetPageIndex = self.pages.findIndex(x => x.id === pageId)
    if (targetPageIndex === -1) throw new Error(`Ошибка консистентности данные страница с id ${pageId} не найдена`)
    return take(self.pages, targetPageIndex)
  },
})).actions(self => ({
  load: flow(function * (surveyId: string) {
    const pages = (yield apiGetActivePagesBySurveyId(surveyId)).sort((a: any, b: any) => a.order - b.order)
    self.pages = cast(pages)
  }),
  loadWithSortByService: flow(function * (surveyId: string, serviceIds: string[]) {
    let pages = yield apiGetActivePagesBySurveyId(surveyId)
    pages = serviceIds.map(x => pages.find((p: { serviceId: string }) => p.serviceId === x))
      .filter(x => x !== undefined)
      .map(x => {
        x.questions = orderBy(x.questions, ['isMain'], ['desc'])
        return x
      })
    self.pages = cast(pages)
  }),
}))
