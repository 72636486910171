import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Container, Grid, Typography, Link } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { ReactComponent as MenuIcon } from '@/Images/menu_icon.svg'
import { ReactComponent as FeedbackIcon } from '@/Images/feedback_icon.svg'
import LabssPollsIcon from '@/Images/labss_polls.png'
import KeyIcon from '@/Images/key.svg'
import FeedbackModal from '@/Pages/User/QuestionsPage/FeedbackModal'
import { useRouteManager } from '@/Hooks/useRouteManager'
import { SurveyType } from '@/Stores/AdminStores/surveysStore'
import { IServiceInstance } from '@/Stores/UserStores/pageStore'
import { RouteEnum } from '@/Enums/route'
import htmlSafeParse from '@/Helpers/htmlSafeParseHelper'
import { getStaticsHybridUrl } from '@/Helpers/staticsHybridUrl'
import { getRedirectHybridPath } from '@/Helpers/redirectHybridPath'
import { isFeatureEnabled } from '@/Components/Feature'
import { FrontendFeature } from '@/applicationConfig'
import bem from '@/Helpers/BemClass'

import './style.scss'

interface IPageHeader {
  name?: string
  description?: string
  imageId?: string
  pageId?: string
  surveyId?: string
  surveyType?: SurveyType
  withCards?: boolean
  service: IServiceInstance | null
}
const cnSurveysList = bem()('surveys-list')
const cnPageHeader = bem()('page-header')

const PageHeader = ({ name, description, pageId, imageId, surveyId, surveyType, withCards, service }: IPageHeader) => {
  const muiTheme = useTheme()
  const UIisMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))
  const UIisTablet = useMediaQuery(muiTheme.breakpoints.down('md'))
  // пример использования для более мелких экранов
  // const UIisMobileXs = useMediaQuery(muiTheme.breakpoints.down('xs'))

  const [isModalFeedbackOpen, SetIsModalFeedbackOpen] = useState(false)
  const routeManager = useRouteManager()

  const isSelectable = surveyId && (surveyType === SurveyType.Gvk || ((surveyType === SurveyType.Custom || surveyType === SurveyType.Repeatable) && withCards))

  return (
    <Container maxWidth={false} disableGutters className={`${cnPageHeader('outer')} ${bem()('bg-gradient')()}`}>
      <Container maxWidth='lg' className={cnPageHeader({ mobile: UIisMobile })}>
        <Grid container direction='row' alignItems='center' rowSpacing={{ xs: 2, sm: 0, md: 0 }} columnSpacing={{ xs: 0, sm: 2, md: 1 }} className={cnPageHeader('wrapper', { mobile: UIisMobile })}>

          { UIisMobile && <Grid container item justifyContent='center' className={cnSurveysList('header', { mobile: UIisTablet })}>
            <Grid item xs={6} sm={5} className={cnSurveysList('header-logo')}>
              <Link color='inherit' component={RouterLink} to={getRedirectHybridPath('/')}>
                <img src={isFeatureEnabled(FrontendFeature.gvk) ? KeyIcon : LabssPollsIcon} alt='Опросы - логотип' loading='lazy' decoding='async'/>
              </Link>
            </Grid>
          </Grid>}

          {imageId && <Grid item xs={12} sm={1} className={cnPageHeader('image', { mobile: UIisMobile })}>
            <span style={{ background: `url('${getStaticsHybridUrl(`/api/file/image/${imageId}`)}') center / cover` }}/>
          </Grid>}

          {(name || description || service?.owner) && <Grid item xs={12} sm className={cnPageHeader('text', { mobile: UIisMobile })}>
            {name && <Typography variant='h4' className={cnPageHeader('text-title')}>
              { htmlSafeParse(name) }
            </Typography>}
            {service?.owner && <Typography variant='h4' className={cnPageHeader('text-owner')}>
              { service.owner }
            </Typography>}
            {description && <Typography variant='body1' component='p' className={cnPageHeader('text-description', { mobile: UIisMobile })}>
              { htmlSafeParse(description) }
            </Typography>}
          </Grid>}

          {isSelectable &&
          <Grid item xs container direction='column' alignItems='center' justifyContent='center'
            className={cnPageHeader('action', { select: true, mobile: UIisMobile })} onClick={() => routeManager.redirectToUrl(RouteEnum.startPage, surveyId)}>
            <MenuIcon/>
            <span>Выбор {surveyType === SurveyType.Gvk ? 'сервисов' : 'карточек'}</span>
          </Grid>
          }

          <Grid item xs container alignItems='center' justifyContent='center'
            className={cnPageHeader('action', { feedback: true, mobile: UIisMobile })} onClick={() => SetIsModalFeedbackOpen(true)}>
            <FeedbackIcon/>
            <span>Обратная связь</span>
          </Grid>

          <FeedbackModal isOpen={isModalFeedbackOpen} pageId={pageId ?? ''} handleClose={() => SetIsModalFeedbackOpen(false)}/>
        </Grid>
      </Container>
    </Container>
  )
}

export default PageHeader
