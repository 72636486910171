import { Dispatch, SetStateAction } from 'react'
import { Button } from '@mui/material'

import { IDialog } from '@/Components/Dialog'
import { useWaiter } from '@/Helpers/react-wait'
import { useMst } from '@/Stores/rootStore'
import { ISurveyInstance } from '@/Stores/AdminStores/surveysStore'

export const useStartStopDialog = (setDialogState: Dispatch<SetStateAction<IDialog | undefined>>) => {
  const store = useMst()
  const surveyStore = store.admin.surveysStore
  const trafficLightStateStore = store.admin.trafficLightStateStore
  const { startWait, stopWait } = useWaiter()

  return {
    startSurvey: (survey: ISurveyInstance) => {
      const handleConfirmStartClick = async () => {
        startWait(`change-state-${survey.surveyId}`)
        setDialogState(undefined)
        try {
          await survey.Start()
          await surveyStore.loadSurveyInfo(survey.surveyId)
        } finally {
          await trafficLightStateStore.createIfNotExistAndGet(survey.surveyId).loadTrafficLightState(true)
          stopWait(`change-state-${survey.surveyId}`)
        }
      }

      setDialogState({
        open: true,
        title: 'Запуск опроса',
        text: 'Вы действительно хотите запустить опрос?',
        handleClose: () => setDialogState(undefined),
        actions: [
          <Button onClick={() => setDialogState(undefined)} key="no">Нет</Button>,
          <Button onClick={async () => await handleConfirmStartClick()} key="yes">Да</Button>,
        ],
      })
    },
    stopSurvey: (survey: ISurveyInstance) => {
      const handleConfirmStopClick = async () => {
        startWait(`change-state-${survey.surveyId}`)
        setDialogState(undefined)
        try {
          await survey.Stop()
          await surveyStore.loadSurveyInfo(survey.surveyId)
        } finally {
          stopWait(`change-state-${survey.surveyId}`)
        }
      }

      setDialogState({
        open: true,
        title: 'Остановка опроса',
        text: 'Вы действительно хотите остановить опрос?',
        handleClose: () => setDialogState(undefined),
        actions: [
          <Button onClick={() => setDialogState(undefined)} key="no">Нет</Button>,
          <Button onClick={async () => await handleConfirmStopClick()} key="yes">Да</Button>,
        ],
      })
    },
  }
}
