import { MouseEvent } from 'react'
import { observer } from 'mobx-react'
import { Accordion, AccordionDetails, AccordionSummary, SelectChangeEvent } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import _ from 'lodash'

import bem from '@/Helpers/BemClass'

import { IQuestionFilterInstance } from '../../../../../Stores/AdminStores/SurveyInfoStores/branchingStore'
import CustomSelect from '../Components/CustomSelect'
import { Condition } from '../../../../../Models/rule'
import { questionFilterConditionItemsByQuestion } from '../../../../../Helpers/branchingHelper'
import AnswersSelector from '../Components/AnswersSelector'
import { getAdminRootStore, useMst } from '../../../../../Stores/rootStore'
import { AccordionSummaryContent, ActionsContainer } from '../Components/Accordion/styled'
import TruncatedText from '../Components/TruncatedText'
import DragLabel from '../../../../../Components/DragAndDrop/DragLabel'
import Error, { ErrorLevel } from '../../../../../Components/Error'
import DeleteButton from '../../../../../Components/IconButtons/Delete'
import { IQuestionInstance } from '../../../../../Stores/AdminStores/SurveyInfoStores/pagesStore'

import { QuestionFilterContainer } from './styled'

import '../Components/Accordion/style.scss'

const cnBranchingTab = bem()('branching-tab')

interface IQuestionFilterProps {
  filter: IQuestionFilterInstance
  index: number
  dragHandleProps: DraggableProvidedDragHandleProps | undefined | null
  question: IQuestionInstance
  disabled: boolean
}

const QuestionFilter = ({ filter, index, dragHandleProps, question, disabled }: IQuestionFilterProps) => {
  const tabStore = useMst().admin.tabStore
  const store = getAdminRootStore().surveyInfoRootStore.pagesStore
  const selectedPage = store.getPage(filter.pageId ?? '')
  const selectedQuestion = selectedPage?.questions.find(x => x.id === filter.targetQuestionId)

  const handleDelete = (e: MouseEvent) => {
    e.stopPropagation()
    filter.delete()
    tabStore.setWasChange(true)
  }

  const handleSelectQuestion = (e: SelectChangeEvent<string | number>) => {
    filter.setTargetQuestionId(e.target.value as string)
    tabStore.setWasChange(true)
  }

  const handleSelectCondition = (e: SelectChangeEvent<string | number>) => {
    filter.setCondition(e.target.value as Condition)
    tabStore.setWasChange(true)
  }

  return <Accordion className={cnBranchingTab('styled-accordion')} expanded={filter.isExpanded} onChange={filter.toggleExpanded}>
    <AccordionSummary
      className={cnBranchingTab('styled-accordion__summary', {
        'error': filter.errors.size > 0,
        'warning': filter.isDeprecated,
        'branching-item-title': true,
      })}
      expandIcon={<ExpandMoreIcon/>}
    >
      <AccordionSummaryContent>
        <TruncatedText text={`Фильтр ${index}`}/>
        <Error errorLevel={ErrorLevel.Warning} isVisible={filter.errors.size === 0 && filter.isDeprecated}>Устарел</Error>
        <Error isVisible={filter.errors.size > 0}/>
        <ActionsContainer>
          {!disabled && <DeleteButton onClick={handleDelete}/>}
          {!disabled && <DragLabel dragHandleProps={dragHandleProps}/>}
        </ActionsContainer>
      </AccordionSummaryContent>
    </AccordionSummary>
    <AccordionDetails>
      <QuestionFilterContainer>
        <CustomSelect
          label="Вопрос"
          value={filter.targetQuestionId ?? undefined}
          onChange={handleSelectQuestion}
          error={filter.errors.has('targetQuestionId')}
          errorText={filter.errors.get('targetQuestionId')}
          data={_.takeWhile(selectedPage?.questions ?? [], x => x.id !== question.id)}
          valueField="id"
          textField="text"
          disabled={disabled}
        />
        <CustomSelect
          label="Пользователь"
          value={filter.condition}
          onChange={handleSelectCondition}
          error={filter.errors.has('condition')}
          errorText={filter.errors.get('condition')}
          data={questionFilterConditionItemsByQuestion(selectedQuestion)}
          disabled={!selectedQuestion || disabled}
          valueField="value"
          textField="text"
        />
        <AnswersSelector question={selectedQuestion} logicItem={filter} disabled={disabled}/>
      </QuestionFilterContainer>
    </AccordionDetails>
  </Accordion>
}

export default observer(QuestionFilter)
