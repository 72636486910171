// Префикс для ответов с выбором "не готов оценить"
export const notEstimateValueTextPrefix = 'notEstimate;'

// Дефолтная текстовка для ответов "не готов оценить"
export const notReadyToRateText = 'Не готов оценить'

// Префикс для ответов с выбором своего ответа
export const customValueTextPrefix = 'custom;'

// Название куки отвечающей за id юзера/токен
export const cookieUserId = 'x-auth-user'
