import { flow, types as t } from 'mobx-state-tree'
import Cookies from 'js-cookie'
import { v4 } from 'uuid'

import { cookieUserId } from '@/Helpers/globalConst'
import { SelectedPagesStore } from '@/Stores/UserStores/selectedPagesStore'
import { PageStore } from '@/Stores/UserStores/pageStore'
import { UserSurveysStore } from '@/Stores/UserStores/userSurveysStore'
import { apiSyncUserTest } from '@/Api/user'
import BackendAdapter from '@/Api/_adapter'

const defaultState = {
  selectedPagesState: { pages: [], currentPage: '', count: 0 },
  pageStore: {
    page: { questions: [], description: '', serviceId: '', title: '', id: '', imageId: '', surveyId: '', version: 0 },
    errors: [],
    answers: [],
    currentSurvey: undefined,
  },
  userSurveysStore: { runningSurveys: [] },
  cookieUserId: Cookies.get(cookieUserId) ?? '',
}

const model = t.model({
  selectedPagesState: SelectedPagesStore,
  pageStore: PageStore,
  userSurveysStore: UserSurveysStore,
  cookieUserId: t.string,
  // Используется для чатов eXpress
  groupChatId: t.maybeNull(t.string),
}).actions(self => ({
  setCookieUserId: (value: string) => {
    Cookies.set(cookieUserId, value)
    self.cookieUserId = value
  },
  loadUserInfoFromExpress: flow(function * load() {
    const userInfo = yield BackendAdapter.post('/whoami').then(x => x.data)

    const userId = userInfo.adLogin as string
    const groupChatId = userInfo.groupChatId as string

    Cookies.set(cookieUserId, userId)
    self.cookieUserId = userId
    self.groupChatId = groupChatId
  }),
  generateUserWithRandomUserId: flow(function * generate() {
    const userId = v4()

    yield apiSyncUserTest(userId)

    Cookies.set(cookieUserId, userId)
    self.cookieUserId = userId
  }),
}))

export default {
  defaultState,
  model,
}
