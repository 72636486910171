import { memo, ChangeEvent } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { Checkbox, Typography } from '@mui/material'

import { ColorTheme } from '@/Models/colorTheme'
import { ToolTip } from '@/Components'

import { IPageModel } from '../../Models/models'
import DragLabel from '../../../../../../Components/DragAndDrop/DragLabel'

import { PageContainer, PageHeader, UseButtons, BoldText, PageNumberStyled } from './styled'

export interface IPageInfo extends IPageModel {
  index: number
  onIsRequiredChange: (id: string) => (e: ChangeEvent<HTMLInputElement>) => void
  disabled: boolean
}

const Page = ({ id, title, order, isRequired, index, onIsRequiredChange, disabled }: IPageInfo) => {
  const requiredPageText = isRequired ? <BoldText>Обязательная страница</BoldText> : <Typography style={{ fontSize: '18' }}>Обязательная страница</Typography>

  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(provided, snapshot) => (
        <PageContainer ref={provided.innerRef} {...provided.draggableProps}>
          <PageNumberStyled>Страница {order}</PageNumberStyled>
          <ToolTip title={title} arrow theme={ColorTheme.dark}>
            <PageHeader>{title}</PageHeader>
          </ToolTip>
          <UseButtons>
            {requiredPageText}
            <Checkbox color="primary"
              onChange={onIsRequiredChange(id)}
              checked={isRequired}
              disabled={disabled}
            />
            {!disabled && <DragLabel dragHandleProps={provided.dragHandleProps} />}
          </UseButtons>
        </PageContainer>
      )}
    </Draggable>)
}

export default memo(Page)
