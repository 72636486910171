import { intersection } from 'lodash'

import { Condition, Overlap } from '@/Models/rule'
import { notEstimateValueTextPrefix } from '@/Helpers/globalConst'

const customValueTextPrefix = 'custom;'

export const IsTrue = (condition: Condition, overlap: Overlap, actualAnswers: string[], isSkipped: boolean, expectedAnswers: string[] | null) => {
  switch (condition) {
    case Condition.Choose: {
      switch (overlap) {
        case Overlap.Or: {
          return !isSkipped && expectedAnswers &&
          (
            intersection(expectedAnswers, actualAnswers).length > 0 ||
            (
              expectedAnswers.includes(customValueTextPrefix) &&
              actualAnswers.some(x => x.startsWith(customValueTextPrefix))
            ) ||
            (
              expectedAnswers.includes(notEstimateValueTextPrefix) &&
              actualAnswers.some(x => x.startsWith(notEstimateValueTextPrefix))
            )
          )
        }
        case Overlap.And: {
          if (isSkipped || !expectedAnswers) return false

          let matchCount = intersection(expectedAnswers, actualAnswers).length

          // Ниже идут проверки "x !== customValueTextPrefix" и "x !== notEstimateValueTextPrefix", это нужно т.к. при полном совпадении ответа с префиксом, он посчтается как +1 при intersection
          // TODO: перепроверить, возможно отрефакторить
          if (expectedAnswers.includes(customValueTextPrefix) && actualAnswers.some(x => x.startsWith(customValueTextPrefix) && x !== customValueTextPrefix)) {
            matchCount++
          }

          if (expectedAnswers.includes(notEstimateValueTextPrefix) && actualAnswers.some(x => x.startsWith(notEstimateValueTextPrefix) && x !== notEstimateValueTextPrefix)) {
            matchCount++
          }

          return matchCount === expectedAnswers.length
        }
        default: {
          // Так не должно быть
          return false
        }
      }
    }
    case Condition.NotChoose: {
      switch (overlap) {
        case Overlap.Or: {
          if (isSkipped) return true
          return expectedAnswers && (
            !(intersection(expectedAnswers, actualAnswers).length > 0) &&
            !(
              expectedAnswers.includes(customValueTextPrefix) &&
              actualAnswers.some(x => x.startsWith(customValueTextPrefix))
            ) &&
            !(
              expectedAnswers.includes(notEstimateValueTextPrefix) &&
              actualAnswers.some(x => x.startsWith(notEstimateValueTextPrefix))
            )
          )
        }
        case Overlap.And: {
          if (isSkipped || !expectedAnswers) return true
          let matchCount = intersection(expectedAnswers, actualAnswers).length

          // Ниже идут проверки "x !== customValueTextPrefix" и "x !== notEstimateValueTextPrefix", это нужно т.к. при полном совпадении ответа с префиксом, он посчтается как +1 при intersection
          if (expectedAnswers.includes(customValueTextPrefix) && actualAnswers.some(x => x.startsWith(customValueTextPrefix) && x !== customValueTextPrefix)) {
            matchCount++
          }

          if (expectedAnswers.includes(notEstimateValueTextPrefix) && actualAnswers.some(x => x.startsWith(notEstimateValueTextPrefix) && x !== notEstimateValueTextPrefix)) {
            matchCount++
          }

          return matchCount !== expectedAnswers.length
        }
        default: {
          // Так не должно быть
          return false
        }
      }
    }
    case Condition.Skip: {
      return isSkipped
    }
    case Condition.Always: {
      return true
    }
    case Condition.AnswerEntered: {
      return !isSkipped && actualAnswers.some(x => !!x)
    }
    default: {
      // Так не должно быть
      return false
    }
  }
}
