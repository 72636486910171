import { useState } from 'react'
import { Route, Switch } from 'react-router'
import { useMount } from 'react-use'

import { isExpressSmartApp, loadConfig } from '@/applicationConfig'
import UserLayout from '@/Layouts/User'
import PrivateRoute from '@/Components/PrivateRoute'
import { RouteEnum } from '@/Enums/route'
import AdminLayout, { MenuAdminPage } from '@/Layouts/Admin'
import SurveyListPage, { SurveyListFilter } from '@/Pages/Admin/SurveyListPage'
import SurveyInfoPage from '@/Pages/Admin/SurveyInfoPage'
import OrganizationStructurePage from '@/Pages/Admin/OrganizationStructurePage'
import ServiceListPage from '@/Pages/Admin/ServiceListPage'
import EditPage from '@/Pages/Admin/EditPage'
import { useMst } from '@/Stores/rootStore'

const Pages = () => {
  const [isConfigLoading, setIsConfigLoading] = useState(true)
  const UserStore = useMst().user

  useMount(() => {
    (async () => {
      console.log('Подгрузка конфига с бэкенда')

      await loadConfig()
        .catch(x => console.error('Ошибка загрузки конфига:', x))

      if (isExpressSmartApp()) {
        console.log('[SmartappApp] Конфиг загружен. Запрашиваем информацию о пользователе')
        await UserStore.loadUserInfoFromExpress()
      } else {
        console.log('[WebApp] Конфиг загружен')
      }

      setIsConfigLoading(false)
    })()
  })

  if (isConfigLoading) return <></>

  return (<Switch>
    <Route path={`*/${RouteEnum.estimate}`}>
      <UserLayout routeEnum={RouteEnum.estimate}/>
    </Route>
    <Route path={`*/${RouteEnum.statistic}/:surveyId/`}>
      <UserLayout additionNavigation='Результаты' routeEnum={RouteEnum.statistic} />
    </Route>
    <Route path={`*/${RouteEnum.questionPage}/:surveyId/:pageId?`}>
      <UserLayout routeEnum={RouteEnum.questionPage}/>
    </Route>
    <Route path={`*/${RouteEnum.startPage}/:surveyId`}>
      <UserLayout routeEnum={RouteEnum.startPage}/>
    </Route>
    <Route path={`*/${RouteEnum.directLink}/:surveyId`}>
      <UserLayout routeEnum={RouteEnum.directLink}/>
    </Route>

    <PrivateRoute path={`*/${RouteEnum.pageEdit}/:surveyId/:serviceId`}>
      <AdminLayout menuItem={MenuAdminPage.pageServices} disableMainTabs>
        <EditPage/>
      </AdminLayout>
    </PrivateRoute>

    <PrivateRoute path={`*/${RouteEnum.survey}/:surveyId/:selectedTab?`}>
      <AdminLayout menuItem={MenuAdminPage.surveyList} disableMainTabs leftRightMargin={20}>
        <SurveyInfoPage/>
      </AdminLayout>
    </PrivateRoute>
    <PrivateRoute path={`*/${RouteEnum.survey}`}>
      <AdminLayout menuItem={MenuAdminPage.surveyList} leftRightMargin={50}>
        <SurveyListPage key='survey-list-not-archived' surveyListFilter={SurveyListFilter.NotArchived} showAddSurveyButton/>
      </AdminLayout>
    </PrivateRoute>
    <PrivateRoute path={`*/${RouteEnum.archive}/:surveyId/:selectedTab?`}>
      <AdminLayout menuItem={MenuAdminPage.archive} disableMainTabs leftRightMargin={20}>
        <SurveyInfoPage/>
      </AdminLayout>
    </PrivateRoute>
    <PrivateRoute path={`*/${RouteEnum.archive}`}>
      <AdminLayout menuItem={MenuAdminPage.archive} leftRightMargin={50}>
        <SurveyListPage key='survey-list-archived' surveyListFilter={SurveyListFilter.Archived}/>
      </AdminLayout>
    </PrivateRoute>
    <PrivateRoute path={`*/${RouteEnum.organizationStructure}`}>
      <AdminLayout menuItem={MenuAdminPage.organizationStructure} leftRightMargin={50}>
        <OrganizationStructurePage/>
      </AdminLayout>
    </PrivateRoute>
    <PrivateRoute path={`*/${RouteEnum.services}`}>
      <AdminLayout menuItem={MenuAdminPage.services} leftRightMargin={50}>
        <ServiceListPage/>
      </AdminLayout>
    </PrivateRoute>
    <Route path={`*/${RouteEnum.clientSurveys}`}>
      <UserLayout routeEnum={RouteEnum.clientSurveys}/>
    </Route>
  </Switch>)
}

export default Pages
