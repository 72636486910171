import { FormControl, Select, SelectChangeEvent } from '@mui/material'

import { htmlToTextWithNoImage } from '@/Helpers/htmlToTextCustom'
import Error from '@/Components/Error'
import { KeysOfType } from '@/Models/headCell'

import { LabelContainer } from '../SharedStyle'

import { SelectContainer, MenuItemStyled } from './styled'

interface ICustomSelect<T> {
  label: string
  value?: string | number
  data: T[]
  valueField: KeysOfType<T, string | number>
  textField: KeysOfType<T, string>
  onChange: (e: SelectChangeEvent<string | number>) => void
  error?: boolean
  errorText?: string
  disabled?: boolean
}

function CustomSelect<T>({ label, value, data, valueField, textField, onChange, error, errorText, disabled }: ICustomSelect<T>) {
  // TODO: отрефакторить тайпинги value и htmlToText @ Vidrochka
  return (
    <SelectContainer>
      <LabelContainer>{label}</LabelContainer>
      <FormControl size='small'>
        <Select
          variant="outlined"
          value={value ?? ''}
          onChange={onChange}
          error={error}
          disabled={disabled}
        >
          {data.map((x, i) => <MenuItemStyled key={i} value={(x[valueField] as string | readonly string[] | number | undefined)}>{htmlToTextWithNoImage((x[textField]) as unknown as string)}</MenuItemStyled>)}
        </Select>
      </FormControl>
      <Error isVisible={error}>{errorText}</Error>
    </SelectContainer>
  )
}

export default CustomSelect
