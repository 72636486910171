import { saveAs } from 'file-saver'

import BackendAdapter from '@/Api/_adapter'

export const apiGetAnswersReport = async (surveyId: string) => {
  try {
    await BackendAdapter.get(`/api/manage/${surveyId}/report`, { responseType: 'blob', timeout: 0 })
      .then(resp => {
        saveAs(new Blob([resp.data]), 'Ответы респондентов без вложений.zip')
      })
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetAnswersWithFilesReport = async (surveyId: string) => {
  try {
    await BackendAdapter.get(`/api/manage/${surveyId}/reportWithFiles`, { responseType: 'blob', timeout: 0 })
      .then(resp => {
        saveAs(new Blob([resp.data]), 'Ответы респондентов с вложениями.zip')
      })
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetFullReport = async (surveyId: string) => {
  try {
    await BackendAdapter.get(`/api/manage/${surveyId}/fullreport`, { responseType: 'blob', timeout: 0 })
      .then(resp => {
        saveAs(new Blob([resp.data]), 'Отчёт по респондентам.zip')
      })
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetFeedbackReportSurvey = async (surveyId: string) => {
  try {
    await BackendAdapter.get(`/api/manage/feedback/${surveyId}/report`, { responseType: 'blob', timeout: 0 })
      .then(resp => {
        saveAs(new Blob([resp.data]), 'Обратная связь.zip')
      })
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

// TODO: проверить актуальность
export const apiGetFeedbackReport = async () => {
  try {
    await BackendAdapter.get('/api/manage/feedback/report', { responseType: 'blob', timeout: 0 })
      .then(resp => {
        saveAs(new Blob([resp.data]), 'Обратная связь.csv')
      })
  } catch (err: any) {
    console.log(err)
    throw err
  }
}
/**
 * Отчёт "Рейтинг для сравнения" (по "основным" вопросам) в виде Excel (xlsx) файла, за указанные версии опроса
 * @param  {string} surveyId - ID опроса
 * @param {number[]} versions - список версий для сравнения
 * @returns {Promise<void>} Отдает браузеру файл с расширением `xlsx` для сохранения
 */
export const apiGetPeriodComparationReport = async (surveyId: string, versions: number[]) => {
  try {
    await BackendAdapter.get(`/api/manage/${surveyId}/surveyPeriodComparationReport`, {
      params: {
        versions,
      },
      responseType: 'blob',
      timeout: 0,
    }).then(resp => {
      saveAs(new Blob([resp.data]), 'Отчёт о сравнении периодов.xlsx')
    })
  } catch (err: any) {
    console.log(err)
    throw err
  }
}
/**
 * Отчёт "Рейтинг" (по "основным" вопросам) в виде Excel (xlsx) файла, за последнюю (текущую) версию опроса
 * @param  {string} surveyId - ID опроса
 * @returns {Promise<void>} Отдает браузеру файл с расширением `xlsx` для сохранения
 */
export const apiGetSurveyMainQuestionsStatisticReport = async (surveyId: string) => {
  try {
    await BackendAdapter.get(`/api/manage/${surveyId}/surveyMainQuestionsStatisticReport`, { responseType: 'blob', timeout: 0 })
      .then(resp => {
        saveAs(new Blob([resp.data]), 'Отчёт рейтинг.xlsx')
      })
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

/**
 * Архив отчётов типа "Рейтинг" в виде zip, содержащего xlsx файлы
 * @param  {string} surveyId - ID опроса
 * @returns {Promise<void>} Отдает браузеру файл с расширением `zip` для сохранения
 */
export const apiGetAggregatedReport = async (surveyId: string) => {
  try {
    await BackendAdapter.get(`/api/manage/${surveyId}/aggregatedReport`, { responseType: 'blob', timeout: 0 })
      .then(resp => {
        saveAs(new Blob([resp.data]), 'Архив отчётов.zip')
      })
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

/**
 * Отчёт "Ответы клиентов" в виде Excel (xlsx) файла, за последнюю (текущую) версию опроса
 * @param  {string} surveyId - ID опроса
 * @returns {Promise<void>} - Отдает браузеру файл с расширением `xlsx` для сохранения
 */
export const apiGetLastSurveyVersionAnswersReport = async (surveyId: string) => {
  try {
    await BackendAdapter.get(`/api/manage/${surveyId}/lastSurveyVersionAnswersReport`, { responseType: 'blob', timeout: 0 })
      .then(resp => {
        saveAs(new Blob([resp.data]), 'answers_report.xlsx')
      })
  } catch (err: any) {
    console.log(err)
    throw err
  }
}
