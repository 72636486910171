import styled from 'styled-components'

export const MainContainer = styled.div`
  width: 980px;
  overflow-x: hidden;
`


export const PageContainer = styled.div`
  & > h2 {
    font-family: Proxima Nova, monospace;
    font-style: normal;
    font-weight: bold;
    margin: 10px 10px 10px 20px;

    text-align: center;
  }

  margin-top: 30px;
`


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 0 40px;
`

export const InputRow = styled.div`
  width: 200px;
  margin-top: 20px;
`

export const RadioButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;

  .MuiRadio-root {
    padding: 1px 5px;
    margin-left: 20px;
  }

  & > label {
    font-weight: bold;
    font-size: 1.1em;
  }
`

export const MainContainerCalendar = styled.div`
  margin-top: 10px;

  .MuiPickersStaticWrapper-staticWrapperRoot {
    margin: 10px 20px;
  }
`

export const TitleContainer = styled.div`
  margin: 10px 20px 10px 20px;

  .MuiFormControl-root {
    display: flex;
    max-width: 650px !important;
  }

  .photoInputImage {
    border-radius: 8px;
  }
`

export const TextContainer = styled.div`
  margin-left: 20px;
  align-self: flex-start;
`

export const MainQuestionsContainer = styled.div`
  margin: 20px;
`
