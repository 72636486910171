import { useState, ChangeEvent } from 'react'
import { TextField } from '@mui/material'

import bem from '@/Helpers/BemClass'

import './style.scss'

const cnSearchInput = bem()('search-input')

interface SearchInputProps {
  id: string
  property: any
  label: string
  handleSearch?: (property: any, filter: string) => void
}

const SearchInput = ({ id, property, label, handleSearch }: SearchInputProps) => {
  const [searchLine, setSearchLine] = useState<string>('')
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchLine(e.target.value)
    handleSearch?.(property, e.target.value)
  }
  return (
    <TextField
      id={id}
      label={label}
      value={searchLine}
      onChange={handleChange}
      variant="standard"
      className={cnSearchInput()}
    />
  )
}

export default SearchInput
