import { useMount } from 'react-use'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { SnackbarKey, useSnackbar } from 'notistack'

import { errorNotifier } from '@/errorNotifier'

/**
 * Организует подписку на ошибки. Ошибки показывает с помощью Snackbar от notistack
 */
export const useNotifyUserForError = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const action = (key: SnackbarKey) => <IconButton onClick={() => closeSnackbar(key)}>
    <CloseIcon/>
  </IconButton>

  const notifyError = (message: string): void => {
    enqueueSnackbar(message, { persist: true, variant: 'error', action: action })
  }

  useMount(() => {
    const subId = errorNotifier.subscribe(notifyError)
    return () => {
      errorNotifier.unsubscribe(subId)
    }
  })
}
