import axios from 'axios'

import { isExpressSmartApp } from '@/applicationConfig'

export function configureAxios() {
  axios.defaults.headers.common.Pragma = 'no-cache'
  if (isExpressSmartApp()) {
    const prefix = window.location.pathname.split('/').splice(0, 6).join('/')
    axios.defaults.baseURL = `${window.location.origin}${prefix}/static/proxy`
  } else {
    // TODO: вынести в отдельный файл с настройками/константами
    axios.defaults.baseURL = `${window.location.origin}`
    axios.defaults.paramsSerializer = { indexes: true }
  }
}
