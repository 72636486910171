import { ChangeEvent } from 'react'
import { v4 } from 'uuid'

import { QuestionType } from '../../../../../../Models/page'
import Question from '../index'
import { IQuestion } from '../../../Models/pageModel'
import { defaultPayload } from '../../../../../../Models/pageEdit'

export interface IAddQuestionProps {
  handleAddQuestion: (prevValue: IQuestion, newValue: IQuestion, withChangeTracking?: boolean, e?: ChangeEvent<{ value: unknown }>) => void
}

export const AddQuestion = ({ handleAddQuestion }: IAddQuestionProps) => {
  return <Question
    question={{
      questionId: v4(),
      text: '<p><span style="font-size: 21px"><strong>​</strong></span></p>',
      type: QuestionType.rating,
      options: [],
      isMain: false,
      hasCustomAnswer: false,
      showIsAnonym: false,
      showHelperText: false,
      isRequired: false,
      payload: { ...defaultPayload, requireCommentValue: '2' },
    }}
    handleChangeQuestion={handleAddQuestion}
    additionLine={true}
    handleDeleteQuestion={() => {
    }}
    handleCopyQuestion={() => {
    }}
    draggableProvided={undefined}
    disabled={false}
    isNewQuestion={true}
  />
}
