import { ChangeEvent } from 'react'
import { Checkbox, FormControlLabel, MenuItem, Select, SelectChangeEvent, Switch, Typography } from '@mui/material'

import { defaultPayload, IPayload } from '@/Models/pageEdit'
import { ToolTip } from '@/Components'
import { FileType, IFile } from '@/Models/page'
import AddFiles from '@/Pages/Admin/EditPage/Components/Question/AttachmentContainer/AddFiles'
import CheckBoxList from '@/Pages/Admin/EditPage/Components/Question/AttachmentContainer/CheckboxList'
import HelperText from '@/Pages/Admin/EditPage/Components/Question/SharedAdditionalSettings'
import { IQuestion } from '@/Pages/Admin/EditPage/Models/pageModel'

import {
  CheckBoxContainer,
  Container,
  LabelStyled,
  ListContainer,
  SelectContainer,
  SwitchContainer,
  SwitchContainerCustom,
} from './styled'

interface IAttachmentContainer {
  handleChangeQuestion: (prevValue: IQuestion, newValue: IQuestion, e?: ChangeEvent<{ value: unknown }>) => void
  question: IQuestion
  disabled?: boolean
}

const AttachmentContainer = ({ handleChangeQuestion, question, disabled = false }: IAttachmentContainer) => {
  const handleChangeMax = (e: SelectChangeEvent<number>) => {
    const newPayload = { ...question.payload, maxFileCount: e.target.value }
    handleChangeQuestion(question, { ...question, payload: newPayload as IPayload })
  }

  const handleTogglePayloadCheckbox = (field: keyof IPayload, checked: boolean) => () => {
    const newPayload = { ...question.payload, [field]: checked }
    handleChangeQuestion(question, { ...question, payload: newPayload as IPayload })
  }

  const handleToggleSwitch = () => {
    const newPayload = { ...question.payload, limitFileType: !question.payload?.limitFileType }
    handleChangeQuestion(question, { ...question, payload: newPayload as IPayload })
  }

  const handleEditPayloadFileType = (type: FileType) => {
    const newPayload = question.payload ? { ...question.payload } : { ...defaultPayload }
    const newFileTypes = newPayload.acceptFileTypes.includes(type) ? newPayload.acceptFileTypes.filter(x => x !== type) : [...newPayload.acceptFileTypes, type]
    newPayload.acceptFileTypes = newFileTypes
    handleChangeQuestion(question, { ...question, payload: newPayload })
  }

  const handleEditFiles = (files: IFile[]) => {
    const newPayload = { ...question.payload }
    newPayload.adminFiles = files
    handleChangeQuestion(question, { ...question, payload: newPayload as IPayload })
  }

  return (<Container>
    <ToolTip arrow title={'Окончание интервала шкалы обязательного комментария'}>
      <>
        <HelperText
          handleChangeQuestion={handleChangeQuestion}
          question={question}
          disabled={disabled}
        />
      </>
    </ToolTip>
    <AddFiles files={question.payload?.adminFiles ?? []} handleChangeFiles={handleEditFiles}/>
    <CheckBoxContainer>
      <FormControlLabel
        componentsProps={{ typography: { variant: 'subtitle2' } }}
        control={<Checkbox
          disabled={disabled}
          checked={question.payload?.isEnabledLoadFileButton}
          onChange={handleTogglePayloadCheckbox('isEnabledLoadFileButton', !question.payload?.isEnabledLoadFileButton)}
        />}
        label='Добавить поле “Загрузить файл” у пользователя'
      />
    </CheckBoxContainer>
    {question.payload?.isEnabledLoadFileButton && <SelectContainer>
      <FormControlLabel
        labelPlacement='start'
        componentsProps={{ typography: { variant: 'subtitle2', sx: { marginRight: '10px' } } }}
        label='Максимально файлов:'
        sx={{ margin: 0 }}
        control={<Select
          labelId="main-question-type-label"
          value={question.payload?.maxFileCount ?? 1}
          onChange={handleChangeMax}
          disabled={disabled}
          variant='standard'
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
        </Select>}
      />
    </SelectContainer>}

    {question.payload?.isEnabledLoadFileButton && <SwitchContainerCustom>
      <Typography sx={{ color: '#666666', marginBottom: '10px' }}>Тип файла от пользователя:</Typography>
      <SwitchContainer>
        <LabelStyled className={!question.payload?.limitFileType ? 'selected left' : 'left'}
          onClick={handleToggleSwitch}>Любой</LabelStyled>
        <Switch
          disableRipple
          checked={question.payload?.limitFileType}
          onChange={handleToggleSwitch}
        />
        <LabelStyled className={question.payload?.limitFileType ? 'selected right' : 'right'}
          onClick={handleToggleSwitch}>Ограничить</LabelStyled>
      </SwitchContainer>
    </SwitchContainerCustom>}
    {question.payload?.isEnabledLoadFileButton && question.payload?.limitFileType && <ListContainer>
      <CheckBoxList checked={question.payload ? question.payload?.acceptFileTypes : []}
        onChange={handleEditPayloadFileType}/>
    </ListContainer>}
  </Container>)
}

export default AttachmentContainer
