import { useEffect, useState } from 'react'

import { HeadCell } from '@/Models/headCell'
import AgTable from '@/Components/AgGridTable'
import { IRespondentActivities } from '@/Models/analitycs'
import bem from '@/Helpers/BemClass'
import { apiGetSurveyPersonalisedStatistic } from '@/Api/analytics'
import { StatusStatistic } from '@/Enums/analytics'
import { useMst } from '@/Stores/rootStore'

import './style.scss'

const cnRespondentActivitiesTab = bem()('respondent-activities-tab')

interface IRespondentActivitiesTab {
  surveyId: string
}

const RespondentActivitiesTab = ({ surveyId }: IRespondentActivitiesTab) => {
  const [data, setData] = useState<IRespondentActivities[]>([])
  const survey = useMst().admin.surveysStore.getSurvey(surveyId)

  useEffect(() => {
    (async () => {
      const completion = await apiGetSurveyPersonalisedStatistic(surveyId)
      setData(completion)
    })()
  }, [surveyId])

  const statusToText = (status: StatusStatistic) => {
    switch (status) {
      case StatusStatistic.notStarted: return 'Не начал проходить'
      case StatusStatistic.start: return 'Начал проходить'
      case StatusStatistic.completed: return 'Завершил прохождение'
    }
  }

  const header: Array<HeadCell<IRespondentActivities>> = [
    {
      key: 'respondent',
      label: 'Респондент',
      isVisible: true,
      isSearchable: true,
      flex: 2,
      minWidth: 350,
      maxWidth: 600,
    },
    {
      key: 'lastVisit',
      label: 'Последнее время посещения опроса',
      createControl: obj => {
        if (obj.lastVisit) {
          const lastVisit = new Date(obj.lastVisit)
          return <>{`${lastVisit.toLocaleDateString()} ${lastVisit.toLocaleTimeString()}`}</>
        }

        return <></>
      },
      isVisible: true,
      isSearchable: true,
      flex: 1,
      cellClass: 'justify-content-center',
      minWidth: 200,
      maxWidth: 500,
    },
    {
      key: 'status',
      label: 'Статус прохождения',
      createControl: obj => {
        return (<div className={cnRespondentActivitiesTab('data-table')}><div className={cnRespondentActivitiesTab('column-status', {
          'not-started': obj.status === StatusStatistic.notStarted,
          'start': obj.status === StatusStatistic.start,
          'completed': obj.status === StatusStatistic.completed,
        })}>
          {statusToText(obj.status)}
        </div></div>)
      },
      isVisible: true,
      flex: 2,
      minWidth: 550,
      maxWidth: 600,
    },
  ]
  return (<div className={cnRespondentActivitiesTab()}>
    <div className={cnRespondentActivitiesTab('period')}>
      <div className={cnRespondentActivitiesTab('date-survey')}>Дата начала опроса:</div>
      <div className={cnRespondentActivitiesTab('date-survey', { text: true })}>{survey?.start ? (survey.start.getTime() < 0 ? '' : new Date(survey.start).toLocaleDateString()) : ''}</div>
      <div className={cnRespondentActivitiesTab('date-survey')}>Дата завершения опроса: </div>
      <div className={cnRespondentActivitiesTab('date-survey', { text: true })}>{survey?.end ? (new Date(survey.end).toLocaleDateString()) : ''}</div>
    </div>
    <AgTable
      data={data}
      headCells={header}
    />
  </div>)
}

export default RespondentActivitiesTab
