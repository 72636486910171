import { useEffect, useState } from 'react'
import { useMount } from 'react-use'
import { useParams } from 'react-router'
import { Button } from '@mui/material'

import { defaultImageHash } from '@/applicationConfig'
import StyledButton from '@/Components/Button'
import { useRouteManager } from '@/Hooks/useRouteManager'
import { ContentLoader } from '@/Components'
import Dialog, { IDialog } from '@/Components/Dialog'
import { useStateWithChangeTracking } from '@/Components/useStateWithChangeTracking'
import { apiGetPageByServiceId, apiSetGvkPage } from '@/Api/page'
import { useMst } from '@/Stores/rootStore'
import { RouteEnum } from '@/Enums/route'
import { IAnswerValidationResult, QuestionType } from '@/Models/page'
import { CheckEnumIs } from '@/Helpers/enumHelper'
import { SurveyStatus } from '@/Stores/AdminStores/surveysStore'
import { IServiceInstance } from '@/Stores/AdminStores/adminServicesStore'
import { isArchivedHelper } from '@/Helpers/isDisabledHelper'
import { defaultPayload } from '@/Models/pageEdit'
import { ICreatePageModel } from '@/Pages/Admin/EditPage/Custom'
import { EmptyPage } from '@/Pages/Admin/EditPage/Models/pageModel'
import Page from '@/Pages/Admin/EditPage/Components/Page'


import { SaveButtonContainer } from './styled'

const GvkEditPage = () => {
  const { surveyId, serviceId } = useParams<{ surveyId: string, serviceId: string }>()
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage, setPageWithChangeTracking, resetTracking] = useStateWithChangeTracking<ICreatePageModel | undefined>(undefined)
  const [service, setService] = useState<IServiceInstance>()
  const routeManager = useRouteManager()
  const store = useMst()
  const survey = store.admin.surveysStore.getSurvey(surveyId)
  const [defaultImageId, setDefaultImageId] = useState<string | null>(null)
  const isArchived = isArchivedHelper(survey)
  const [dialogState, setDialogState] = useState<IDialog | undefined>()

  useMount(() => {
    (async () => {
      const data = await apiGetPageByServiceId(surveyId, serviceId).catch((err: any) => {
        if (err?.status && err.status === 404) {
          return {}
        } else {
          throw err
        }
      })

      let service = store.admin.adminServicesStore.getService(serviceId)
      if (!service) {
        await store.admin.adminServicesStore.loadServices(surveyId)
        service = store.admin.adminServicesStore.getService(serviceId)
        if (!service) return
      }

      setService(service)

      setDefaultImageId(defaultImageHash)

      setPage({
        ...EmptyPage,
        ...data,
        imageId: data?.imageId ?? defaultImageHash,
        questions: [...(data?.questionsVersion && data?.questionsVersion?.some((x: any) => x.isMain) ? [] : (survey?.getMainQuestionsAsIQuestionVersion() ?? [])), ...(data?.questionsVersion ?? [])],
        title: service.name,
        description: service.description,
        category: service.category,
        expanded: false,
      })

      setIsLoading(false)
    })()
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [isLoading])

  if (!survey) {
    routeManager.redirectTo(RouteEnum.survey)
    return <></>
  }

  const handleUpdatePage = (oldPage: ICreatePageModel, newPage: ICreatePageModel) => {
    setPageWithChangeTracking({ ...newPage })
  }

  const validate = (page: ICreatePageModel) => {
    page.questions.forEach((q, qi) => {
      q.options.forEach((a, ai) => {
        const answerValidationResult: IAnswerValidationResult = { imageRequiredError: false }
        if (CheckEnumIs(q.type, QuestionType.checkboxWithImage, QuestionType.radioWithImage) && !a.imageId) {
          answerValidationResult.imageRequiredError = true
        }
        a.validationResult = answerValidationResult
      })
    })
    setPage({ ...page })
    return !page.questions.some(q => q.options.some(a => a.validationResult?.imageRequiredError))
  }

  const handleSave = async () => {
    if (!page) return
    setIsLoading(true)

    if (validate(page)) {
      const resultQuestions = [...page.questions]

      let hasBranchingChanges = false
      if (resultQuestions.length > 0) {
        hasBranchingChanges = await apiSetGvkPage({
          Id: page.pageId,
          surveyId: surveyId,
          serviceId: serviceId,
          imageId: page.imageId ?? '',
          title: page.title,
          description: page.description,
          category: page.category,
          questions: resultQuestions.map(x => (
            {
              ...x,
              id: x.questionId,
              payload: x?.payload ? x.payload : defaultPayload,
            }
          )),
          isRequired: page.isRequired,
          order: page.order,
        })
      }

      if (hasBranchingChanges) {
        setDialogState({
          open: true,
          handleClose: () => {
            setDialogState(undefined)
            routeManager.goBack()
          },
          title: 'Некоторые правила могли перестать работать!',
          text: 'Необходимо скорректировать настройки на вкладке Ветвление',
          actions: [<Button
            key='page-changes-ok-btn'
            onClick={() => {
              setDialogState(undefined)
              routeManager.goBack()
            }}
          >ОК</Button>],
        })
      } else {
        routeManager.goBack()
      }

      resetTracking()
      setIsLoading(false)
    }

    setIsLoading(false)
  }

  if (isLoading || !page || !defaultImageId) return <ContentLoader/>

  const saveButton = (
    <SaveButtonContainer>
      <StyledButton onClick={handleSave} height="40px">Сохранить</StyledButton>
    </SaveButtonContainer>
  )

  return (<>
    <Page pageModel={page} service={service ?? null} updatePage={handleUpdatePage} isGvk isCardState={false} isArchived={isArchived} isRepeatableSurveyed={false} disableAccordion/>
    {survey && survey.status !== SurveyStatus.Archived && saveButton}
    {!isLoading && dialogState && <Dialog {...dialogState} />}
  </>)
}


export default GvkEditPage
