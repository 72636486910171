import { ChangeEvent, useState } from 'react'
import { TextField, useMediaQuery, useTheme } from '@mui/material'
import { observer } from 'mobx-react'

import QuestionLayout from '@/Components/Questions/QuestionLayout'
import { useMst } from '@/Stores/rootStore'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnQuestion = bem()('question')

interface IRadioQuestion {
  questionId: string
  onChangesCommit: () => void
}

const RadioQuestion = ({ questionId, onChangesCommit }: IRadioQuestion) => {
  const muiTheme = useTheme()
  const UIisMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))
  const pageStore = useMst().user.pageStore
  const question = pageStore.getQuestion(questionId)
  const answer = pageStore.getAnswer(questionId)

  const [customValue, setCustomValue] = useState<string>(answer.singleValue?.includes('custom;') ? answer.singleValue?.substring('custom;'.length) : '')

  const handleChangeSelfVersionAnswer = (e: ChangeEvent<{ value: string }>) => {
    answer.setValue(`custom;${e.target.value}`)
    setCustomValue(e.target.value)
  }

  const handleChangeToggleAnswer = (value: string) => () => {
    if (answer.singleValue !== value) answer.setValue(value)
    else answer.setValue([])
    onChangesCommit()
  }

  const handleChangeToggleCustomAnswer = () => {
    if (!answer.singleValue?.includes('custom;')) answer.setValue(`custom;${customValue}`)
    else answer.setValue([])
    onChangesCommit()
  }

  return <QuestionLayout
    question={question}
  >
    <div className={cnQuestion({ radio: true })}>
      {question.options?.map((x, i) => <label className={cnQuestion('answer-label', { mobile: UIisMobile })} key={i} htmlFor={`quest_${question.id}_answ_${i}`}>
        <input
          id={`quest_${question.id}_answ_${i}`}
          type="radio"
          checked={answer.singleValue === x.value}
          onClick={handleChangeToggleAnswer(x.value)}
        />
        <span className={cnQuestion('check-mark')}/>
        {x.value}
      </label>
      )}
      {question.hasCustomAnswer && <div className={cnQuestion('custom-answer', { mobile: UIisMobile })}>
        <label className={cnQuestion('answer-label', { mobile: UIisMobile })} htmlFor={`quest_${question.id}_self_version_answer`}>
          <input
            id={`quest_${question.id}_self_version_answer`}
            type="radio"
            checked={answer.singleValue?.includes('custom;')}
            onClick={handleChangeToggleCustomAnswer}
          />
          <span className={cnQuestion('check-mark')}/>
        </label>
        <TextField className={cnQuestion('custom-answer_input')} variant="standard" placeholder="Введите свой вариант ответа" value={customValue}
          onChange={handleChangeSelfVersionAnswer}/>
      </div>}
    </div>
  </QuestionLayout>
}


export default observer(RadioQuestion)
