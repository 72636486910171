import { useState, useContext } from 'react'
import { useMount, useLongPress } from 'react-use'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Container, Grid, Typography, Link, Dialog } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { getRedirectHybridPath } from '@/Helpers/redirectHybridPath'
import bem from '@/Helpers/BemClass'
import { useMst } from '@/Stores/rootStore'
import { FrontendFeature } from '@/applicationConfig'
import SurveyPreview from '@/Pages/User/SurveyList/SurveyPreview'
import AuthView from '@/Components/AuthView'
import { ILoaderState, LoaderStateContext } from '@/Components/LoaderContext'
import { ApiErrorEnum } from '@/Enums/apiError'
import { RouteEnum } from '@/Enums/route'
import { isFeatureEnabled } from '@/Components/Feature'
import LabssPollsIcon from '@/Images/labss_polls.png'
import KeyIcon from '@/Images/key.svg'
import ImgCapybara from '@/Images/capybara.png'
import ImgKeyMascot from '@/Images/keyMascot.png'

import './style.scss'

const cnSurveysList = bem()('surveys-list')

export enum Direction {
  left = 0,
  right = 1,
}

interface ISurveyList {
  apiError?: ApiErrorEnum
  routeEnum: RouteEnum
}

const pagesWithUserSelection = [RouteEnum.clientSurveys]

const SurveyList = ({ apiError, routeEnum }: ISurveyList) => {
  const muiTheme = useTheme()
  // const UIisMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))
  const UIisTablet = useMediaQuery(muiTheme.breakpoints.down('md'))
  const loadingContext = useContext<ILoaderState>(LoaderStateContext)
  const store = useMst()
  const runningSurveys = store.user.userSurveysStore.runningSurveys
  const [isChangeUserOpen, setIsChangeUserOpen] = useState(false)

  useMount(() => {
    (async () => {
      if (!apiError) {
        loadingContext.changeIsLoading(true)
        await store.user.userSurveysStore.getRunningSurveysSortedBy('start', 'desc')
      }
      loadingContext.changeIsLoading(false)
    })()
  })

  const getContentDirection = (index: number) => {
    return index % 2 === 0 ? 'left' : 'right'
  }

  const getText = () => {
    switch (apiError) {
      case ApiErrorEnum.userNotFound: {
        return <>Пользователь не найден в системе <br/>Пожалуйста, обратитесь к администратору</>
      }
      default: return 'У вас нет опросов для прохождения'
    }
  }

  const longPressHook = useLongPress(() => setIsChangeUserOpen(true), { isPreventDefault: true, delay: 300 })

  const longPressEventForRolesSwitch = isFeatureEnabled(FrontendFeature.rolesSwitch) && pagesWithUserSelection.includes(routeEnum) ? longPressHook : undefined

  const getContent = () => {
    return (<>
      <Grid container item justifyContent='center' className={cnSurveysList('header')} rowSpacing={0} columnSpacing={0}>
        <Grid container rowSpacing={0} columnSpacing={0} alignItems='center' justifyContent='center'className={cnSurveysList('header', { mobile: UIisTablet })}>
          <Grid item xs={6} sm={5} md={3} lg={2} className={cnSurveysList('header-logo', { key: isFeatureEnabled(FrontendFeature.gvk) })}>
            <Link color='inherit' component={RouterLink} to={getRedirectHybridPath('/')}>
              <img {...longPressEventForRolesSwitch} src={isFeatureEnabled(FrontendFeature.gvk) ? KeyIcon : LabssPollsIcon} alt='Опросы - логотип' loading='lazy' decoding='async'/>
            </Link>
          </Grid>
        </Grid>
        <Grid item xs={12} className={`${cnSurveysList('header-text-wrapper', { mobile: UIisTablet })} ${bem()('bg-gradient')()} --no-select`}>
          <Container maxWidth='lg'>
            <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
              <Typography variant='h2' component='h2'>
                <strong>Сегодня вам доступны следующие опросы</strong>
              </Typography>
            </Box>
            <Box display={{ xs: 'block', sm: 'block', md: 'none' }}>
              <Typography variant='h2' component='h2'>
                <strong>{`Доступно сегодня опросов - ${runningSurveys.length}`}</strong>
              </Typography>
            </Box>
          </Container>
        </Grid>
      </Grid>

      <Container maxWidth='lg' className={cnSurveysList('content', { mobile: UIisTablet })}>
        <hr className='polls-hr-custom1' />
        {runningSurveys.map((survey, index) => <div key={index}>
          <SurveyPreview
            survey={survey}
            withExpertLink={survey.withExpertPermission}
            contentDirection={getContentDirection(index)}
          />
          <hr className='polls-hr-custom1' />
        </div>)}
      </Container>
    </>)
  }

  const getNoSurveysPlaceholder = () => {
    return (
      <Grid item container xs spacing={1} direction='row' alignItems='center' justifyContent='center' className={`${cnSurveysList('no-surveys-wrapper')} --no-select`}>
        <Grid item xs={12} alignSelf='flex-end'>
          <Typography variant='h1' component='h1' align='center' color='GrayText' className={cnSurveysList('no-surveys-text')}>
            {getText()}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3} alignSelf='flex-start'>
          {!isFeatureEnabled(FrontendFeature.gvk) && <img {...longPressEventForRolesSwitch} src={ImgCapybara} className={`${cnSurveysList('no-surveys-icon', { mobile: UIisTablet })} --no-select`} alt='Иконка' loading='lazy' decoding='async'/>}
          {isFeatureEnabled(FrontendFeature.gvk) && <img {...longPressEventForRolesSwitch} src={ImgKeyMascot} className={`${cnSurveysList('no-surveys-icon', { mobile: UIisTablet, key: true })} --no-select`} alt='Иконка' loading='lazy' decoding='async'/>}
        </Grid>
      </Grid>
    )
  }

  if (loadingContext.isLoading) return <></>
  return (
    <div className={cnSurveysList()}>
      <Container maxWidth={false} className={cnSurveysList('container')}>
        {runningSurveys.length > 0 && !apiError ? getContent() : getNoSurveysPlaceholder()}
      </Container>

      <Dialog fullScreen={UIisTablet} onClose={() => setIsChangeUserOpen(false)} open={isChangeUserOpen}>
        <AuthView onClose={(login?: string) => {
          setIsChangeUserOpen(false)
        }}/>
      </Dialog>
    </div>
  )
}

export default SurveyList
