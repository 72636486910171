import { MouseEvent } from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { observer } from 'mobx-react'

import bem from '@/Helpers/BemClass'

import Counter from '../../Components/Counter'
import TruncatedText from '../../Components/TruncatedText'
import AddButton from '../../Components/AddButton'
import { AccordionSummaryContent, ActionsContainer, ActionText } from '../../Components/Accordion/styled'
import { useMst } from '../../../../../../Stores/rootStore'
import QuestionRule from '../../QuestionRule'
import QuestionFilter from '../../QuestionFilter'
import { IQuestionFilterInstance, IRuleInstance } from '../../../../../../Stores/AdminStores/SurveyInfoStores/branchingStore'
import DndSortableList from '../../../../../../Components/DragAndDrop/DndSortableList'
import { IQuestionInstance } from '../../../../../../Stores/AdminStores/SurveyInfoStores/pagesStore'
import { CopyItemType } from '../../../../../../Stores/AdminStores/SurveyInfoStores/copyLogicStore'

import { CountersContainer, QuestionContentContainer, QuestionFiltersContainer, QuestionRulesContainer } from './styled'

import '../../Components/Accordion/style.scss'

const cnBranchingTab = bem()('branching-tab')

interface IQuestionProps {
  question: IQuestionInstance
  pageId: string
  disabled: boolean
}

const Question = ({ pageId, question, disabled }: IQuestionProps) => {
  const store = useMst()
  const tabStore = store.admin.tabStore
  const surveyInfoRootStore = useMst().admin.surveyInfoRootStore
  const pageLogic = surveyInfoRootStore.branchingStore.pagesLogic.get(pageId)
  const surveyWithCards = surveyInfoRootStore.branchingStore.surveyWithCards
  const copyLogic = surveyInfoRootStore.copyLogicStore
  const canPaste = copyLogic.enableCopyMode && copyLogic.type === CopyItemType.questionRule && copyLogic.appropriateCopyQuestions().includes(question.id)
  const canCancelPaste = copyLogic.enableCopyMode && copyLogic.type === CopyItemType.questionRule && copyLogic.questionId === question.id

  if (!pageLogic) return <></>

  const questionFilters = pageLogic.filtersByQuestion(question.id)
  const questionRules = pageLogic.rulesByQuestion(question.id)

  const hasDeprecatedQuestionItem = questionFilters.some(x => x.isDeprecated) || (!surveyWithCards && questionRules.some(x => x.isDeprecated))
  const questionLogicHasErrors = pageLogic.hasQuestionRulesErrors(question.id) || pageLogic.hasQuestionFiltersErrors(question.id)

  const handleAddQuestionRule = () => {
    pageLogic.addNewQuestionRule(pageId, question.id)
    tabStore.setWasChange(true)
  }

  const handleAddQuestionFilter = () => {
    pageLogic.addNewQuestionFilter(pageId, question.id)
    tabStore.setWasChange(true)
  }

  const handleCopy = (e: MouseEvent) => {
    e.stopPropagation()
    copyLogic.setCopyQuestionRule(pageId, question.id)
  }

  const handleCancelCopy = (e: MouseEvent) => {
    e.stopPropagation()
    copyLogic.cancelCopy()
  }

  const handlePaste = (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    copyLogic.pasteRules(pageId, question.id)
    tabStore.setWasChange(true)
  }

  return <Accordion className={cnBranchingTab('styled-accordion')} TransitionProps={{ unmountOnExit: true }}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon/>}
      className={cnBranchingTab('styled-accordion__summary', {
        'error': questionLogicHasErrors,
        'warning': hasDeprecatedQuestionItem,
        'can-paste': canPaste,
      })}
    >
      <AccordionSummaryContent onClick={canPaste ? handlePaste : undefined}>
        <TruncatedText text={question.text}/>
        <ActionsContainer>
          <CountersContainer>
            {!question.isMain && <Counter text="Фильтров" count={questionFilters.length}/>}
            {!surveyWithCards && <Counter
              text="Правил"
              count={questionRules.length}
              onCopy={handleCopy}
              onCancelCopy={canCancelPaste ? handleCancelCopy : undefined}
              disabled={questionRules.length === 0 || disabled}
              visible={!disabled}
            />}
          </CountersContainer>
          {(!disabled && !question.isMain) && <ActionText>{!canPaste ? 'Добавить' : 'ВСТАВИТЬ'}</ActionText>}
        </ActionsContainer>
      </AccordionSummaryContent>
    </AccordionSummary>
    <AccordionDetails>
      <QuestionContentContainer>
        <QuestionFiltersContainer>
          <DndSortableList
            data={questionFilters}
            onDragEnd={pageLogic.moveQuestionFilter}
            renderItem={(item, i, dragHandleProps) => <QuestionFilter key={i} filter={item as IQuestionFilterInstance} index={i + 1} question={question} dragHandleProps={dragHandleProps} disabled={disabled}/>}
          />
          {(!disabled && !question.isMain) && <AddButton text="Добавить фильтр" onClick={handleAddQuestionFilter}/>}
        </QuestionFiltersContainer>
        {!surveyWithCards && <QuestionRulesContainer>
          <DndSortableList
            data={questionRules}
            onDragEnd={pageLogic.moveQuestionRule}
            renderItem={(item, i, dragHandleProps) => <QuestionRule key={i} rule={item as IRuleInstance} index={i + 1} dragHandleProps={dragHandleProps} disabled={disabled}/>}
          />
          {!disabled && <AddButton text="Добавить правило" onClick={handleAddQuestionRule}/>}
        </QuestionRulesContainer>}
      </QuestionContentContainer>
    </AccordionDetails>
  </Accordion>
}

export default observer(Question)
