import { useCallback, useEffect, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { observer } from 'mobx-react'

import { TableSkeleton } from '@/Components'
import AgTable from '@/Components/AgGridTable'
import { HeadCell } from '@/Models/headCell'
import { apiGetGlobalServices } from '@/Api/service'
import { Wait } from '@/Helpers/react-wait/hooks'
import { useWaiter } from '@/Helpers/react-wait'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnServiceListPage = bem()('service-list-page')

interface IService {
  name: string
  foreignServiceId: string
  category?: string | null
  owner?: string | null
  description?: string | null
  isGvk: string

}

const header: Array<HeadCell<IService>> = [
  {
    key: 'name',
    label: 'Название',
    isVisible: true,
    isSearchable: true,
    cellClass: 'justify-content-center',
    flex: 1.3,
    minWidth: 130,
  },
  {
    key: 'foreignServiceId',
    label: 'ID сервиса',
    isVisible: true,
    isSearchable: true,
    cellClass: 'justify-content-center',
    flex: 1,
    minWidth: 100,
  },
  {
    key: 'category',
    label: 'Категория (Функциональное направление) ',
    isVisible: true,
    isSearchable: true,
    cellClass: 'justify-content-center',
    flex: 2.2,
    minWidth: 100,
  },
  {
    key: 'owner',
    label: 'Владелец сервиса ССП',
    isVisible: true,
    isSearchable: true,
    cellClass: 'justify-content-center',
    flex: 1.3,
    minWidth: 100,
  },
  {
    key: 'description',
    label: 'Описание',
    isVisible: true,
    isSearchable: true,
    cellClass: 'justify-content-center',
    flex: 2,
    minWidth: 200,
  },
  {
    key: 'isGvk',
    label: 'ГВК',
    isVisible: true,
    isSearchable: true,
    cellClass: 'justify-content-center',
    flex: 0.5,
    minWidth: 100,
  },
]

const ServiceListPage = () => {
  const gridRef = useRef<AgGridReact>(null)
  const [services, setServices] = useState<IService[]>([])
  const { startWait, stopWait, isWaiting } = useWaiter()

  const refreshServices = useCallback(async () => {
    startWait('services-list-loading')

    const services = await apiGetGlobalServices()
    setServices(services.map(x => ({ ...x, isGvk: x.isGvk ? 'Да' : 'Нет' })))

    stopWait('services-list-loading')
  }, [startWait, stopWait])

  useEffect(() => {
    const refreshServiceCallback = () => {
      refreshServices()
    }

    (async () => {
      await refreshServices()
    })()

    document.addEventListener('service-sync-completed', refreshServiceCallback)

    return () => {
      document.removeEventListener('service-sync-completed', refreshServiceCallback)
    }
  }, [refreshServices])

  return <div className={cnServiceListPage()}>
    <Wait for="services-list-loading" with={<TableSkeleton/>}>
      <AgTable
        data={services}
        headCells={header}
        gridRef={gridRef}
      />
    </Wait>
  </div>
}

export default observer(ServiceListPage)
