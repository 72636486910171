import { ChangeEvent } from 'react'
import { Checkbox, FormControlLabel, TextField } from '@mui/material'

import { PhotoInput } from '@/Components'
import { ICreatePageModel } from '@/Pages/Admin/EditPage/Custom'
import { IServiceInstance } from '@/Stores/AdminStores/adminServicesStore'
import { getStaticsHybridUrl } from '@/Helpers/staticsHybridUrl'

import { FieldColumnContainer, PageInfoContainer } from './styled'

interface IPageInfo {
  pageModel: ICreatePageModel
  service: IServiceInstance | null
  updatePage: (field: keyof ICreatePageModel, value: unknown, withChangeTracking?: boolean) => void
  isGvk?: boolean
  disabled: boolean
}

const PageInfo = ({ pageModel, service, updatePage, isGvk, disabled }: IPageInfo) => {

  const handleChangePageModel = (field: keyof ICreatePageModel) => (e: ChangeEvent<{ value: string }>) => {
    const value = e.target.value
    updatePage(field, value)
  }

  const handlePhotoChange = (imageId: string, withChangeTracking: boolean = false) => {
    updatePage('imageId', imageId, withChangeTracking)
  }

  const handleToggleIsRequired = () => {
    updatePage('isRequired', !pageModel.isRequired, false)
  }

  return (<PageInfoContainer>
    <PhotoInput imageUrl={getStaticsHybridUrl(`/api/file/image/${pageModel.imageId ?? ''}`)} onChange={handlePhotoChange} width={125} height={125} cropHeight={125} cropWidth={125} rounded disabled={disabled}/>
    <FieldColumnContainer>
      <TextField
        placeholder="Введите название страницы"
        value={pageModel.title}
        onChange={handleChangePageModel('title')}
        disabled={(isGvk ?? false) || disabled}
        fullWidth
        multiline
        error={pageModel.validateResult?.titleError}
        variant="standard"
      />
      {service?.owner && <TextField
        label="Владелец сервиса ССП"
        value={service.owner}
        disabled={true}
        fullWidth
        multiline
        variant="standard"
      />}
      <TextField
        placeholder="Введите описание страницы"
        value={pageModel.description}
        onChange={handleChangePageModel('description')}
        disabled={(isGvk ?? false) || disabled}
        fullWidth
        multiline
        variant="standard"
      />
      {isGvk && <FormControlLabel
        value="requiredPage"
        control={<Checkbox color="primary" disabled={disabled} checked={pageModel.isRequired} onChange={handleToggleIsRequired}/>}
        label="Обязательный сервис"
        labelPlacement="end"
      />}
    </FieldColumnContainer>
  </PageInfoContainer>)
}

export default PageInfo
