export interface IAnswerValidationResult {
  imageRequiredError?: boolean
  duplicateError?: boolean
}

export interface Option {
  value: string
  imageId: string | null
  title: string | null
  isCustom: boolean
  validationResult?: IAnswerValidationResult
}

export interface IFile {
  fileId?: string | null
  fileName?: string | null
  fileType?: string | null
}

export interface IPage {
  id: string
  title: string
  description: string
  questions: IQuestion[]
  serviceId: string
}

export interface IQuestion {
  id: string
  text: string
  type: QuestionType
  options?: Option[]
  isMain: boolean
  isRequired: boolean
  hasCustomAnswer: boolean
}

export enum QuestionType {
  text = '0',
  radio = '1',
  checkbox = '2',
  rating = '3',
  slider = '4',
  radioWithImage = '5',
  checkboxWithImage = '6',
  attachment = '7',
}

export enum CommentType {
  NoComment = '0',
  Comment = '1',
  CommentIsRequired = '2',
  AlwaysVisible = '3',
  AlwaysVisibleAndRequired = '4',
  NotRequiredAndVisibleTo = '5',
  VisibleToAndRequiredTo = '6',
  AlwaysVisibleAndRequiredTo = '7',
}

export enum FileType {
  xls = '1', // 'application/vnd.ms-excel',
  xlsx = '2', // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  doc = '3', // 'application/msword',
  docx = '4', // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ppt = '5', // 'application/vnd.ms-powerpoint',
  pptx = '6', // 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  png = '7', // 'image/png',
  jpeg = '8', // 'image/jpeg',
  pdf = '9', // 'application/pdf',
  text = '10', // 'text/plain',
}
