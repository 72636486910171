import { ChangeEvent } from 'react'

import { IQuestionData } from '@/Pages/Admin/EditPage/Components/Question/SliderContainer'
import { Container } from '@/Pages/Admin/EditPage/Components/Question/SliderContainer/styled'
import SharedAdditionalSettings from '@/Pages/Admin/EditPage/Components/Question/SharedAdditionalSettings'

export interface ICheckboxContainerError {
  emptyHelperText?: boolean
}

interface ICheckboxContainerProps<TQuestionType extends IQuestionData> {
  handleChangeQuestion: (prevValue: TQuestionType, newValue: TQuestionType, e?: ChangeEvent<{ value: unknown }>) => void
  question: TQuestionType
  disabled?: boolean
}

const CheckboxContainer = <TQuestionType extends IQuestionData>({ handleChangeQuestion, question, disabled = false }: ICheckboxContainerProps<TQuestionType>) => {
  return (<Container>
    <SharedAdditionalSettings
      handleChangeQuestion={handleChangeQuestion}
      question={question}
      disabled={disabled}
    />
  </Container>)
}

export default CheckboxContainer
