import { saveAs } from 'file-saver'

import BackendAdapter from '@/Api/_adapter'
import { IFile } from '@/Models/file'

export const apiSetFile = async (model: IFile) => {
  try {
    return await BackendAdapter.post('/api/file/any', model)
      .then(resp => resp.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetFile = async (fileId: string) => {
  try {
    await BackendAdapter.get(`/api/file/any/${fileId}`, { responseType: 'blob', timeout: 0 })
      .then(resp => {
        const fileName = resp.headers['content-disposition']?.split('filename=')[1].split(';')[0] ?? 'unknown_file'
        const filteredFileName = fileName.replace(/"/g, '') // убираем из названия кавычки, они могут ломать название файла
        saveAs(new Blob([resp.data]), filteredFileName)
      })
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

// TODO: 2 ручки ниже отрефакторить убрав any
export const apiGetFileName = async (fileId: string) => {
  try {
    return await BackendAdapter.get(`/api/file/any/${fileId}/name`)
      .then(resp => resp.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetFileContentType = async (fileId: string) => {
  try {
    return await BackendAdapter.get(`/api/file/any/${fileId}/contentType`)
      .then(resp => resp.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiSaveImage = async (image: string) => {
  try {
    return await BackendAdapter.post('/api/file/image', image, { headers: { 'Content-Type': 'application/json' } })
      .then(resp => resp.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}
