import { useContext, useState, ChangeEvent } from 'react'
import { useMount } from 'react-use'
import { Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd'
import { Button, Skeleton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { flattenDeep, uniq } from 'lodash'
import { arrayMoveImmutable } from 'array-move'

import StyledSwitch from '@/Components/StyledSwitch'
import { useMst } from '@/Stores/rootStore'
import StyledButton from '@/Components/Button'
import { apiGetActivePagesBySurveyId, apiSetCustomPagesSettings } from '@/Api/page'
import DndContext from '@/Components/DragAndDrop/DndContext'
import { apiChangeSurveyMode } from '@/Api/survey'
import { useStateWithChangeTracking } from '@/Components/useStateWithChangeTracking'
import { isArchivedHelper } from '@/Helpers/isDisabledHelper'
import bem from '@/Helpers/BemClass'

import CategoryCreator from './Components/CategoryCreator'
import { ICategory, IPageModel, DropType, IShortPageInfo } from './Models/models'
import Category from './Components/Category'

import './style.scss'

const cnPagesRepresentationTab = bem()('pages-representation-tab')

interface IPagesRepresentationTab {
  surveyId: string
}

const otherCategoryName = 'Прочее'

const PagesRepresentationTab = ({ surveyId }: IPagesRepresentationTab) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isCardState, setIsCardState, setIsCardStateWithChangeTracking] = useStateWithChangeTracking<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [showAddCategoryModal, setShowAddCategoryModal] = useState<boolean>(false)
  const [categories, setCategories, setCategoriesWithChangeTracking, resetTracking] = useStateWithChangeTracking<ICategory[]>([])
  const dndContext = useContext(DndContext)
  const store = useMst().admin.surveysStore
  const trafficLightStateStore = useMst().admin.trafficLightStateStore

  useMount(() => {
    (async () => {
      await store.loadSurveyInfo(surveyId)
      const survey = store.surveys.find(x => x.surveyId === surveyId)
      setIsCardState(survey?.withCards ?? false)
      setDisabled(isArchivedHelper(survey))

      const pages: IPageModel[] = [...await apiGetActivePagesBySurveyId(surveyId)].sort((a, b) => a.order - b.order)

      const pagesWithoutCategory = pages.filter(page => !page.category || page.category === otherCategoryName).map(page => ({ ...page, category: otherCategoryName }))
      const categoryNames = uniq(pages.map(page => page.category)).filter(x => !!x && x !== otherCategoryName)

      const categories: ICategory[] = categoryNames.map((x, i) => (
        { name: x ?? '', order: i, pages: pages.filter(page => page.category === x) }
      ))
      categories.push({ pages: pagesWithoutCategory, order: categories.length, name: otherCategoryName })

      setCategories(categories)

      dndContext.subscribeToDrugEnd(DropType.page, handlePageDragEnd)
      dndContext.subscribeToDrugEnd(DropType.category, handleCategoryDragEnd)

      setIsLoading(false)
      return () => {
        dndContext.unsubscribeFromDrugEnd(DropType.page)
        dndContext.unsubscribeFromDrugEnd(DropType.category)
      }
    })()
  })


  const getExistCategoriesNameHelper = () => categories.map(category => category.name)

  const handlePageDragEnd = (result: DropResult, provided: ResponderProvided) => {
    setCategoriesWithChangeTracking(prev => {
      const sourceCategory = prev.find(x => x.name === result.source.droppableId)
      const targetCategory = prev.find(x => x.name === result.destination?.droppableId)
      const draggablePage = sourceCategory?.pages[result.source.index]
      if (!draggablePage || !sourceCategory || !targetCategory) return prev
      sourceCategory.pages.splice(result.source.index, 1)
      targetCategory.pages.splice(result.destination?.index ?? 0, 0, draggablePage)
      let pageOrder = 1
      prev.forEach(cat => cat.pages.forEach(page => { page.order = pageOrder++ }))
      return prev.map(x => ({ ...x }))
    })
  }

  const handleCategoryDragEnd = (result: DropResult, provided: ResponderProvided) => {
    setCategoriesWithChangeTracking(prev => {
      // @ts-expect-error Индекс у destination может быть undefined, если Drop был в "никуда"
      const newCategory = arrayMoveImmutable(prev, result.source.index, result.destination.index)
      let pageOrder = 1
      newCategory.forEach((cat, i) => {
        cat.order = i + 1
        cat.pages.forEach(page => { page.order = pageOrder++ })
      })
      return [...newCategory]
    })
  }

  const handleIsRequiredChange = (categoryName: string) => (pageId: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setCategoriesWithChangeTracking(prev => {
      const changedPage = prev.find(x => x.name === categoryName)?.pages.find(x => x.id === pageId)
      if (!changedPage) throw new Error('Ошибка консистентности данных')
      changedPage.isRequired = e.target.checked
      return [...prev]
    })
  }

  const handleAddCategory = (name: string) => {
    setShowAddCategoryModal(false)
    setCategoriesWithChangeTracking(prev => {
      const newCategories = [{ pages: [], name: name, order: 1 }, ...prev]
      newCategories.forEach((x, i) => { x.order = i + 1 })
      return [...newCategories]
    })
  }

  const handleDeleteCategory = (name: string) => {
    setCategoriesWithChangeTracking(prev => {
      const deletedCategory = prev.find(x => x.name === name)
      if (!deletedCategory) throw new Error('Ошибка консистентности данных, не найдена удяляемая категория')
      const otherCategory = prev[prev.length - 1]
      otherCategory.pages = [...otherCategory.pages, ...deletedCategory.pages]
      const newCategoryCollection = [...prev.filter(x => x.name !== name)]
      let pageOrder = 1
      newCategoryCollection.forEach(cat => cat.pages.forEach(page => { page.order = pageOrder++ }))
      return newCategoryCollection
    })
  }

  const handleUpdateCategory = (oldName: string, newName: string) => {
    setCategoriesWithChangeTracking(prev => prev.map(x => (x.name === oldName ? { ...x, name: newName } : x)))
  }

  const handleSave = async () => {
    setIsLoading(true)
    if (!isCardState) {
      const targetAudiencesMappingType = await apiChangeSurveyMode(surveyId, false)
      store.changeSurveyMode(surveyId, false)
      store.changeSurveyTargetAudiencesMappingType(surveyId, targetAudiencesMappingType)
      await trafficLightStateStore.createIfNotExistAndGet(surveyId).loadTrafficLightState()
      setIsLoading(false)
      resetTracking()
      return
    }

    const result: IShortPageInfo[] = flattenDeep(categories.map(x => x.pages.map(p => ({ ...p, category: x.name }))))

    if (result.length > 0) {
      await apiSetCustomPagesSettings(surveyId, result)
    } else {
      await apiChangeSurveyMode(surveyId, true)
    }

    await trafficLightStateStore.createIfNotExistAndGet(surveyId).loadTrafficLightState()

    store.changeSurveyMode(surveyId, true)
    resetTracking()
    setIsLoading(false)
  }

  const addButton = (
    isLoading ? <Skeleton variant='rectangular' className={cnPagesRepresentationTab('add-button-container-skeleton')} height={47}/> : <div className={cnPagesRepresentationTab('add-button-container')}>
      <Button color='secondary' fullWidth onClick={() => setShowAddCategoryModal(true)}>
        <AddIcon fontSize="large"/>
        <h4 className={cnPagesRepresentationTab('add-category-styled')}>Добавить категорию</h4>
      </Button>
    </div>
  )

  const saveButton = (
    <div className={cnPagesRepresentationTab('save-button-container')}>
      <StyledButton disabled={isLoading} onClick={handleSave} height="40px">Сохранить</StyledButton>
    </div>
  )

  return (
    <div className={cnPagesRepresentationTab()}>
      <StyledSwitch
        isSelected={isCardState}
        onChange={setIsCardStateWithChangeTracking}
        disabled={isLoading || disabled}
        leftText='Классическое представление'
        rightText='Карточное представление'
      />
      {<div style={{ display: isCardState ? '' : 'none' }}>
        {!disabled && addButton}
        {isLoading ? <>
          <Skeleton variant='rectangular' className={cnPagesRepresentationTab('category-skeleton')} height={195} animation="wave"/>
          <Skeleton variant='rectangular' className={cnPagesRepresentationTab('category-skeleton')} height={195} animation="wave"/>
        </> : (categories.length > 0 &&
                <>
                  <Droppable droppableId={'CategoryList'} type={DropType.category}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.droppableProps} >
                        {categories.filter(x => x.name !== otherCategoryName)
                          .map((category, idx) => <Category {...category} key={idx}
                            isOther={false}
                            index={idx}
                            onIsRequiredChange={handleIsRequiredChange}
                            onDeleteCategory={handleDeleteCategory}
                            existCategoryName={getExistCategoriesNameHelper()}
                            onUpdateCategory={handleUpdateCategory}
                            disabled={disabled}
                          />
                          )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <Category pages={categories[categories.length - 1].pages}
                    index={-1}
                    name={otherCategoryName}
                    order={-1} isOther={true}
                    onIsRequiredChange={handleIsRequiredChange}
                    onDeleteCategory={handleDeleteCategory}
                    existCategoryName={getExistCategoriesNameHelper()}
                    onUpdateCategory={(a: string, b: string) => {}}
                    disabled={disabled}
                  />
                </>)
        }
      </div>}
      {!disabled && categories.some(x => x.pages.length > 0) && saveButton}
      {showAddCategoryModal && <CategoryCreator
        existCategoryName={getExistCategoriesNameHelper()}
        isOpen={showAddCategoryModal}
        onClose={() => setShowAddCategoryModal(false)}
        onSave={handleAddCategory}
      />}
    </div>
  )
}

export default PagesRepresentationTab
