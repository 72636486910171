import { useEffect, useState, ReactNode, MouseEvent } from 'react'
import { useMount } from 'react-use'
import { Helmet } from 'react-helmet-async'
import { Grid, Button, IconButton, ListItemText, Popover } from '@mui/material'
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded'
import SyncIcon from '@mui/icons-material/Sync'
import SettingsIcon from '@mui/icons-material/Settings'

import { RouteEnum } from '@/Enums/route'
import { useNotifyUserForError } from '@/Hooks/useNotifyUserForError'
import { useRouteManager } from '@/Hooks/useRouteManager'
import { useMst } from '@/Stores/rootStore'
import { ContentLoader, Dialog, VersionLabel } from '@/Components'
import { DndContextLayout } from '@/Components/DragAndDrop/DndContext'
import { IDialog } from '@/Components/Dialog'
import { apiSyncGlobalServices } from '@/Api/service'
import { apiSyncOrganizationStructure } from '@/Api/organizationStructure'
import { useWaiter } from '@/Helpers/react-wait'
import bem from '@/Helpers/BemClass'
import Feature from '@/Components/Feature'
import DynamicConfiguration from '@/Components/DynamicConfiguration'
import { FrontendFeature } from '@/applicationConfig'

import { Container, ContentContainer, GlobalSnackbarStyled, ListStyled, ListItemStyled } from './styled'

import './style.scss'

const cnAdminLayout = bem()('admin-layout')

/*
enum AdminLayoutTabs {
  surveys = 'surveys',
  archive = 'archive',
}
*/

export enum MenuAdminPage {
  pageServices = 0,
  surveyList = 1,
  feedback = 2,
  archive = 3,
  organizationStructure = 4,
  services = 5,
}

interface IAdminLayout {
  children: ReactNode | ReactNode[]
  menuItem: number
  disableMainTabs?: boolean
  leftRightMargin?: number
}

const AdminLayout = ({ children, menuItem, disableMainTabs = false, leftRightMargin = 100 }: IAdminLayout) => {
  const [isLoading, setIsLoading] = useState(true)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  // const [tab, setTab] = useState<AdminLayoutTabs>(AdminLayoutTabs.surveys)
  const routeManager = useRouteManager()
  useNotifyUserForError()
  const { startWait, stopWait, isWaiting } = useWaiter()
  const tabStore = useMst().admin.tabStore
  const [dialogState, setDialogState] = useState<IDialog | undefined>()

  useMount(() => {
    tabStore.setWasChange(false)
    setIsLoading(false)
  })

  useEffect(() => {
    (async () => {
      await Promise.all([
        apiSyncOrganizationStructure(),
      ])
    })()
  }, [])

  const handleSyncServices = async (e: MouseEvent) => {
    e.stopPropagation()

    startWait('global-services-sync')
    await apiSyncGlobalServices()
    stopWait('global-services-sync')

    document.dispatchEvent(new CustomEvent('service-sync-completed'))
  }

  const handleRedirect = (routeEnum: RouteEnum) => {
    setDialogState(undefined)
    routeManager.redirectTo(routeEnum)
    tabStore.setWasChange(false)
  }

  const redirect = (routeEnum: RouteEnum) => {
    if (tabStore.wasChanged) {
      setDialogState({
        open: true,
        handleClose: () => setDialogState(undefined),
        title: 'На странице были изменения',
        text: 'Выйти без сохранения?',
        actions: [
          <Button key={'changes-no-btn'} onClick={() => setDialogState(undefined)}>Нет</Button>,
          <Button key={'changes-yes-btn'} onClick={() => handleRedirect(routeEnum)}>Да</Button>,
        ],
      })
    } else {
      routeManager.redirectTo(routeEnum)
      tabStore.setWasChange(false)
    }
  }

  const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSettingsClose = () => {
    setAnchorEl(null)
  }

  const settingsIsOpen = Boolean(anchorEl)
  const settingsPopoverId = settingsIsOpen ? 'settings-popover' : undefined

  if (isLoading) return <ContentLoader/>
  return (
    <>
      <DndContextLayout>
        <GlobalSnackbarStyled/>
        <Helmet>
          <title>Сервис опросов: Администратор</title>
        </Helmet>
        <Grid container direction='row' alignItems='center' justifyContent='space-between' className={cnAdminLayout('header')}>
          <Grid item>
            <Button
              size='small'
              color='secondary'
              onClick={() => window.open(`${window.location.origin}/${RouteEnum.clientSurveys}`)}
              startIcon={<LaunchRoundedIcon fontSize='inherit'/>}
            >
              Перейти к опросам
            </Button>
          </Grid>
          <Feature features={FrontendFeature.dynamicConfiguration}>
            <Grid item>
              <IconButton aria-describedby={settingsPopoverId} onClick={handleSettingsClick} aria-label='settings'>
                <SettingsIcon />
              </IconButton>
              <Popover
                id={settingsPopoverId}
                open={settingsIsOpen}
                anchorEl={anchorEl}
                onClose={handleSettingsClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <DynamicConfiguration/>
              </Popover>
            </Grid>
          </Feature>
        </Grid>
        <Container>
          <ContentContainer leftRightMargin={leftRightMargin} >
            { !disableMainTabs &&
            (<ListStyled style={{ display: 'flex', flexDirection: 'row', padding: 0, paddingBottom: '1rem' }}>
              <ListItemStyled disableRipple selected={menuItem === MenuAdminPage.surveyList}
                onClick={() => redirect(RouteEnum.survey)}>
                <ListItemText primaryTypographyProps={{ variant: 'h2', style: { fontSize: '35px' } }} primary={'Опросы'}/>
              </ListItemStyled>
              <ListItemStyled disableRipple selected={menuItem === MenuAdminPage.archive}
                onClick={() => redirect(RouteEnum.archive)}>
                <ListItemText primaryTypographyProps={{ variant: 'h2', style: { fontSize: '35px' } }} primary={'Архив опросов'}/>
              </ListItemStyled>
              <Feature features={FrontendFeature.organizationStructure}>
                <ListItemStyled disableRipple selected={menuItem === MenuAdminPage.organizationStructure}
                  onClick={() => redirect(RouteEnum.organizationStructure)}>
                  <ListItemText primaryTypographyProps={{ variant: 'h2', style: { fontSize: '35px' } }} primary={'Оргструктура'}/>
                </ListItemStyled>
              </Feature>
              <Feature features={FrontendFeature.service}>
                <ListItemStyled disableRipple selected={menuItem === MenuAdminPage.services}
                  onClick={() => redirect(RouteEnum.services)}>
                  <ListItemText primaryTypographyProps={{ variant: 'h2', style: { fontSize: '35px' } }} primary={'Сервисы'}/>
                  <IconButton
                    size='small'
                    edge='end'
                    disabled={isWaiting('global-services-sync')}
                    classes={{
                      root: cnAdminLayout('button', { spin: isWaiting('global-services-sync') }),
                    }}
                    onClick={handleSyncServices}
                  >
                    <SyncIcon fontSize='medium' className={cnAdminLayout('button-icon', { sync: true })}/>
                  </IconButton >
                </ListItemStyled>
              </Feature>
            </ListStyled>)}
            {children}
            {dialogState && <Dialog {...dialogState}/>}
          </ContentContainer>
        </Container>
        <VersionLabel/>
      </DndContextLayout>
    </>)
}

export default AdminLayout
