import { cast, flow, getParentOfType, Instance, SnapshotIn, SnapshotOut, types as t } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { forEach, uniq } from 'lodash'

import { apiGetPage } from '@/Api/page'
import { apiGetAnswer } from '@/Api/answer'
import { apiGetService } from '@/Api/service'
import { apiGetQuestionFilters } from '@/Api/rule'
import { CommentType, QuestionType } from '@/Models/page'
import { IsTrue } from '@/Helpers/rules/conditionalExpression'
import { Condition, Overlap } from '@/Models/rule'
import { Option, Payload } from '@/Stores/sharedModels/models'
import { ISurveyInstance, Survey } from '@/Stores/UserStores/userSurveysStore'
import { notEstimateValueTextPrefix } from '@/Helpers/globalConst'

export interface IServiceInstance extends Instance<typeof Service> {
}

export interface IQuestionFilter extends SnapshotOut<typeof QuestionFilter> {
}

export interface IQuestionInstance extends Instance<typeof Question> {
}

export interface IAnswerInstance extends Instance<typeof Answer> {
}

export interface IAnswerIn extends SnapshotIn<typeof Answer> {
}

export interface IAnswerOut extends SnapshotOut<typeof Answer> {
  questionIsHidden: boolean
}

export interface IUserPageOut extends SnapshotOut<typeof Page> {

}

export interface IErrorInstance extends Instance<typeof Error> {
}

export interface IErrorIn extends SnapshotIn<typeof Error> {
}

export interface IPageStoreInstance extends Instance<typeof PageStore> {
}

const Service = t.model({
  /**  Id сервиса в системе опросов */
  id: t.string,
  /**  Id сервиса в внешней системе */
  foreignServiceId: t.string,
  name: t.string,
  description: t.maybeNull(t.string),
  owner: t.maybeNull(t.string),
  isGvk: t.boolean,
  category: t.maybeNull(t.string),
})

const QuestionFilter = t.model({
  questionId: t.string,
  targetQuestionId: t.string,
  condition: t.enumeration<Condition>(Object.values(Condition)),
  overlap: t.enumeration<Overlap>(Object.values(Overlap)),
  answers: t.maybeNull(t.array(t.string)),
})

const Question = t.model({
  id: t.string,
  text: t.string,
  type: t.enumeration<QuestionType>(Object.values(QuestionType)),
  options: t.optional(t.array(Option), []),
  isMain: t.boolean,
  isRequired: t.boolean,
  hasCustomAnswer: t.boolean,
  commentType: t.enumeration<CommentType>(Object.values(CommentType)),
  payload: t.maybe(Payload),
  helperText: t.maybeNull(t.string),
  showHelperText: t.boolean,
  showIsAnonym: t.boolean,
  isVisible: t.optional(t.boolean, true),
}).actions(self => ({
  setVisible(isVisible: boolean) {
    self.isVisible = isVisible
  },
  checkVisible() {
    const parentStore = getParentOfType(self, PageStore)
    const currentPageFilters = parentStore.filters.get(parentStore.page.id)

    if (!currentPageFilters) return

    const currentQuestionFilters = currentPageFilters.filter(x => self.id === x.questionId)

    if (currentQuestionFilters.length === 0) return

    const isVisible = currentPageFilters.filter(x => self.id === x.questionId).some(x => {
      const targetQuestion = parentStore.page.questions.find(q => q.id === x.targetQuestionId)

      // Если вопрос не показан, то фильтры зависящие от него не работают
      if (!(targetQuestion?.isVisible ?? false)) return false

      const targetQuestionAnswers = parentStore.answers.find(a => a.questionId === x.targetQuestionId)

      // TODO: 09.02.2023 Было убрано в рамках уравнивания функционала gpb/main и master ветки. Т.к. поидее IsSkip не имеет значения для фильтров вопросов, убрана проверка.
      // Если изменение проживет пару месяцев, значит все ок, можно удалить коммент
      /* const isSkip = !targetQuestionAnswers || (targetQuestionAnswers.value ?? []).length === 0
      return IsTrue(x.condition, x.overlap, targetQuestionAnswers?.value ?? [], isSkip, x.answers) */

      return IsTrue(x.condition, x.overlap, targetQuestionAnswers?.value ?? [], false, x.answers)
    })

    if (self.isVisible !== isVisible) {
      self.isVisible = isVisible
      const affectedQuestionsFilters = currentPageFilters.filter(x => self.id === x.targetQuestionId)
      forEach(uniq(affectedQuestionsFilters.map(x => x.questionId)), (questionId: string) => {
        const question = parentStore.page.questions.find(x => x.id === questionId)
        question?.checkVisible()
      })
    }
  },
}))

const Page = t.model({
  id: t.string,
  surveyId: t.string,
  imageId: t.string,
  version: t.integer,
  title: t.maybeNull(t.string),
  description: t.maybeNull(t.string),
  questions: t.array(Question),
  serviceId: t.maybeNull(t.string),
})

const Answer = t.model({
  questionId: t.string,
  value: t.array(t.string),
  comment: t.maybeNull(t.string),
  isAnonym: t.optional(t.boolean, true),
}).views(self => ({
  get singleValue(): string {
    return toJS(self.value)[0] ?? ''
  },
  get fullInfo(): IAnswerOut {
    const parentStore = getParentOfType(self, PageStore)
    return { ...self, questionIsHidden: !parentStore.page.questions.find(q => q.id === self.questionId)?.isVisible ?? false }
  },
})).actions(self => ({
  setValue(value: string | string[]) {
    self.value = cast(typeof value === 'string' ? [value] : value)
    const parentStore = getParentOfType(self, PageStore)
    parentStore.getError(self.questionId).setError('')

    const currentPageFilters = parentStore.filters.get(parentStore.page.id)
    if (!currentPageFilters) return

    forEach(uniq(currentPageFilters.filter(x => x.targetQuestionId === self.questionId).map(x => x.questionId)), (questionId: string) => {
      const question = parentStore.page.questions.find(x => x.id === questionId)
      question?.checkVisible()
    })
  },
  setComment(comment: string) {
    self.comment = comment
    const parentStore = getParentOfType(self, PageStore)
    parentStore.getError(self.questionId).setError('')
  },
  setIsAnonym(value: boolean) {
    self.isAnonym = value
  },
  isNotEstimate() {
    return self.singleValue.startsWith(notEstimateValueTextPrefix)
  },
}))

const Error = t.model({
  questionId: t.string,
  error: t.string,
}).actions(self => ({
  setError(value: string) {
    self.error = value
  },
}))

export const PageStore = t.model({
  page: Page,
  service: t.maybeNull(Service),
  answers: t.array(Answer),
  errors: t.array(Error),
  currentSurvey: t.maybe(t.safeReference(Survey)),
  filters: t.map(t.array(QuestionFilter)),
}).views(self => ({
  get visibleQuestion() {
    return self.page.questions.filter(x => x.isVisible)
  },
})).actions(self => ({
  loadPage: flow(function * load(pageId: string) {
    const [pageRes, answerRes] = yield Promise.all([apiGetPage(pageId), apiGetAnswer(pageId)])
    if (pageRes.version === answerRes.version) {
      self.answers = cast(answerRes.answers ?? [])
    } else {
      self.answers = cast([])
    }

    self.page = cast(pageRes)
    self.page.questions.forEach(x => x.checkVisible())

    if (self.page.serviceId) {
      const service = yield apiGetService(self.page.serviceId)
      self.service = cast(service)
    } else {
      self.service = null
    }

    self.errors = cast([])
  }),
  loadQuestionFilters: flow(function * load() {
    if (self.currentSurvey) {
      self.filters.clear()
      const response: Map<string, any> = yield apiGetQuestionFilters(self.currentSurvey.surveyId, true)

      for (const [key, filters] of Object.entries(response)) {
        self.filters.set(key, filters)
      }
    }
  }),
  getQuestion: (questionId: string): IQuestionInstance => {
    const question = self.page.questions.find(x => x.id === questionId)
    if (!question) throw new global.Error(`question with id: ${questionId} not found`)
    return question
  },
  getAnswer: (questionId: string): IAnswerInstance => {
    let answer = self.answers.find(x => x.questionId === questionId)
    if (!answer) {
      const index = self.answers.push({ questionId: questionId, comment: '', value: [] })
      answer = self.answers[index - 1]
    }
    return answer
  },
  getError: (questionId: string): IErrorInstance => {
    let error = self.errors.find(x => x.questionId === questionId)
    if (!error) {
      const index = self.errors.push({ questionId: questionId, error: '' })
      error = self.errors[index - 1]
    }
    return error
  },
  getHasErrors: (): boolean => {
    return self.errors.some(x => x.error.length > 0)
  },
  cleanErrors: (): void => {
    self.errors.forEach(x => x.setError(''))
  },
  setSurvey: (survey: ISurveyInstance | undefined) => {
    if (survey) self.currentSurvey = survey
  },
}))
