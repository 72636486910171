import { useRef } from 'react'
import { Button, Modal } from '@mui/material'
import { Cropper as ReactCropper, ReactCropperElement } from 'react-cropper'
import 'cropperjs/dist/cropper.css'

import { CropperContainer, ImagePreview, ModalStyled, PreviewContainer } from './styled'

interface ICropper {
  isOpen: boolean
  handleClose: () => void
  src: any
  handleSaveImage: (image: HTMLCanvasElement) => void
  width?: number
  height?: number
}

const Cropper = ({ isOpen, handleClose, src, handleSaveImage, width = 215, height = 150 }: ICropper) => {
  const cropperRef = useRef<ReactCropperElement>(null)
  const cropImage = () => {
    const imageElement: ReactCropperElement | null = cropperRef.current
    const cropper: any = imageElement?.cropper
    if (!cropper) return
    const image = cropper.getCroppedCanvas({
      width: width,
      height: height,
      minWidth: 256,
      minHeight: 256,
      maxWidth: 4096,
      maxHeight: 4096,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
    })
    handleSaveImage(image)
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ModalStyled>
        <PreviewContainer>
          <ImagePreview className="img-preview"/>
          <div className="button-container">
            <Button variant="outlined" onClick={cropImage}>Обрезать изображение</Button>
          </div>
        </PreviewContainer>
        <CropperContainer>
          <ReactCropper
            style={{ maxHeight: 'calc(90vh - 200px)', maxWidth: '90vw' }}
            preview=".img-preview"
            guides={false}
            src={src}
            aspectRatio={width / height}
            ref={cropperRef}
            scalable={false}
            zoomable={false}
            modal={true}
            minCropBoxWidth={width}
            minCropBoxHeight={height}
          />
        </CropperContainer>
      </ModalStyled>
    </Modal>
  )
}

export default Cropper
