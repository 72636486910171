import { types as t } from 'mobx-state-tree'

import { FileType } from '@/Models/page'
import { QuestionStyle } from '@/Models/pageEdit'
import { AlignEnum, SizeEnum } from '@/Enums/ratingPayloadEnum'

export const Option = t.model({
  value: t.string,
  imageId: t.maybeNull(t.string),
  image: t.maybeNull(t.string),
  // отрефакторить сделать 1 общий опшенс (@Vidrochka)
  title: t.maybeNull(t.string),
  isCustom: t.boolean,
})

export const File = t.model({
  fileId: t.maybeNull(t.string),
  fileName: t.maybeNull(t.string),
  fileType: t.maybeNull(t.string),
})

export const Payload = t.model({
  helperStartText: t.string,
  helperEndText: t.string,
  showNotReadyToRate: t.boolean,
  notReadyToRateText: t.string,
  requireCommentValue: t.string,
  isEnabledLoadFileButton: t.boolean,
  maxFileCount: t.number,
  limitFileType: t.boolean,
  acceptFileTypes: t.array(t.enumeration<FileType>(Object.values(FileType))),
  adminFiles: t.array(File),
  questionStyle: t.enumeration<QuestionStyle>(Object.values(QuestionStyle)),
  size: t.enumeration<SizeEnum>(Object.values(SizeEnum)),
  align: t.enumeration<AlignEnum>(Object.values(AlignEnum)),
})
