import { ChangeEvent, ReactNode } from 'react'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'

import { defaultPayload, QuestionStyle } from '@/Models/pageEdit'
import Feature from '@/Components/Feature'
import { customImageHash, FrontendFeature } from '@/applicationConfig'

import { CheckEnumIs } from '../../../../../../Helpers/enumHelper'
import { QuestionType } from '../../../../../../Models/page'
import { IQuestion } from '../../../Models/pageModel'

import CommentSelector from './CommentSelector'
import QuestionTypeSelector from './QuestionTypeSelector'

interface IQuestionSettings {
  question: IQuestion
  isMain: boolean
  disabled: boolean
  onChangeQuestion: (prevValue: IQuestion, newValue: IQuestion, e?: ChangeEvent<{ value: unknown }>) => void
  children?: ReactNode
}

const QuestionSettings = ({ question, onChangeQuestion, isMain, disabled, children }: IQuestionSettings) => {

  const handleToggleCheckbox = (field: keyof IQuestion) => () => {
    if (field === 'hasCustomAnswer' && [QuestionType.checkboxWithImage, QuestionType.radioWithImage].includes(question.type)) {
      if (question[field]) {
        question.options = question.options.filter(x => !x.isCustom)
      } else {
        question.options = [...question.options, { value: 'Свой вариант', imageId: customImageHash, title: null, isCustom: true }]
      }
    }
    onChangeQuestion(question, { ...question, [field]: !question[field] })
  }

  const handleEditQuestionStyle = () => {
    const newPayload = question.payload ? { ...question.payload } : defaultPayload

    newPayload.questionStyle = newPayload.questionStyle === QuestionStyle.Default ? QuestionStyle.Colorized : QuestionStyle.Default

    onChangeQuestion(question, { ...question, payload: newPayload })
  }

  const { type, isRequired, hasCustomAnswer } = question // allowQuestions={nickelAllowQuestion}
  return (<div style={{ minWidth: '330px' }}>
    <QuestionTypeSelector question={question} disabled={isMain || disabled} onChangeQuestion={onChangeQuestion}/>
    {CheckEnumIs(type, QuestionType.rating, QuestionType.slider) && <CommentSelector question={question} isMain={isMain} disabled={disabled} handleChangeQuestion={onChangeQuestion}/>}
    <div>
      <FormGroup>
        <FormControlLabel componentsProps={{ typography: { variant: 'caption' } }} control={<Checkbox size='small' checked={isRequired} onChange={handleToggleCheckbox('isRequired')} disabled={isMain || disabled}/>} label='Обязательный вопрос'/>
        {CheckEnumIs(type, QuestionType.radio, QuestionType.checkbox, QuestionType.radioWithImage, QuestionType.checkboxWithImage) && <FormControlLabel componentsProps={{ typography: { variant: 'caption' } }} control={<Checkbox size='small' checked={hasCustomAnswer} onChange={handleToggleCheckbox('hasCustomAnswer')} disabled={disabled}/>} label='Добавить свой вариант ответа'/>}
        {CheckEnumIs(type, QuestionType.slider) && <Feature features={FrontendFeature.styledQuestions}>
          <FormControlLabel componentsProps={{ typography: { variant: 'caption' } }} control={<Checkbox size='small' checked={CheckEnumIs(question.payload?.questionStyle, QuestionStyle.Colorized) } onChange={handleEditQuestionStyle} disabled={question.isMain || disabled}/>} label='Цветная шкала'/>
        </Feature>}
      </FormGroup>
    </div>
  </div>)
}

export default QuestionSettings
