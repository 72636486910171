import { IPermission } from '@/Models/permission'
import BackendAdapter from '@/Api/_adapter'

export const apiCheckUserPermissions = async (permissions: IPermission[]): Promise<boolean> => {
  try {
    return await BackendAdapter.post('/api/permissions/check', permissions)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetUserPermissions = async (): Promise<IPermission[]> => {
  try {
    return await BackendAdapter.get('/api/permissions')
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}
