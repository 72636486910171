import { types as t } from 'mobx-state-tree'

import { AdminServicesStore } from '@/Stores/AdminStores/adminServicesStore'
import { SurveysStore, SurveysStoreFilter } from '@/Stores/AdminStores/surveysStore'
import { TabStore } from '@/Stores/AdminStores/tabStore'
import { SurveyInfoRootStore } from '@/Stores/AdminStores/SurveyInfoStores/surveyInfoRootStore'
import { OrganizationStructureStore } from '@/Stores/AdminStores/organizationStructureStore'
import { TrafficLightStateStore } from '@/Stores/AdminStores/trafficLightStateSore'
import { CopyItemType } from '@/Stores/AdminStores/SurveyInfoStores/copyLogicStore'

const defaultState = {
  adminServicesStore: { services: [] },
  surveysStore: { surveys: [], filter: SurveysStoreFilter.NotArchived },
  tabStore: { wasChanged: false, title: 'На странице были изменения' },
  surveyInfoRootStore: { branchingStore: { pagesLogic: {} }, pagesStore: { pages: [] }, copyLogicStore: { enableCopyMode: false, type: CopyItemType.none } },
  organizationStructureStore: {},
  trafficLightStateStore: {},
}

const model = t.model({
  adminServicesStore: AdminServicesStore,
  surveysStore: SurveysStore,
  tabStore: TabStore,
  surveyInfoRootStore: SurveyInfoRootStore,
  organizationStructureStore: OrganizationStructureStore,
  trafficLightStateStore: TrafficLightStateStore,
})

export default {
  defaultState,
  model,
}
