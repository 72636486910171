import { ReactNode } from 'react'
import { observer } from 'mobx-react'
import { Grid } from '@mui/material'
import saveAs from 'file-saver'
import bridge from 'smartapp-bridge'

import { IExpressFile } from '@/Models/express'
import { useMst } from '@/Stores/rootStore'
import { apiGetFile } from '@/Api/file'
import QuestionLayout from '@/Components/Questions/QuestionLayout'
import UserAddFiles from '@/Components/Questions/AttachmentQuestion/UserAddFiles'
import FileItem from '@/Components/FileItem'
import { isExpressSmartApp } from '@/applicationConfig'
import { parseJson } from '@/Helpers/parseJson'
import bem from '@/Helpers/BemClass'

import { Container, TextContainer } from './styled'

const cnQuestion = bem()('question')

interface IAttachmentQuestion {
  questionId: string
  onChangesCommit: () => void
}

const AttachmentQuestion = ({ questionId, onChangesCommit }: IAttachmentQuestion) => {
  const pageStore = useMst().user.pageStore
  const userStore = useMst().user
  const question = pageStore.getQuestion(questionId)
  const answer = pageStore.getAnswer(questionId)

  const handleDownloadFile = (fileId: string) => async () => {
    try {
      if (!isExpressSmartApp()) {
        await apiGetFile(fileId)
      } else {
        const expressResponse: any = await bridge?.sendBotEvent({
          method: 'FILE_UPLOAD',
          params: {
            http_request_method: 'GET',
            url: `/api/file/any/${fileId}`,
            query: JSON.stringify({ 'x-auth-user': userStore.cookieUserId }),
            body: JSON.stringify({}),
          },
        })

        const fileInfo: IExpressFile = expressResponse?.payload?.serverResponse ? parseJson(expressResponse?.payload?.serverResponse) : ''

        await bridge?.sendClientEvent({
          method: 'open_file',
          params: fileInfo,
        })
      }

      onChangesCommit()

    } catch (err: any) {
      console.error(err)
    }
  }

  return <QuestionLayout
    question={question}
  >
    {question.payload && question.payload.adminFiles.length > 0 && <Grid container spacing={2}>
      {question.payload.adminFiles.map((x, i) => x.fileId && <Grid key={x.fileId} item>
        <FileItem onCardClick={handleDownloadFile(x.fileId)} fileId={x.fileId} name={x.fileName} mimeType={x.fileType ?? undefined}/>
      </Grid>)}
    </Grid>}
    {question.payload?.isEnabledLoadFileButton && <UserAddFiles answer={answer} maxFileCount={question.payload?.maxFileCount}
      acceptList={question.payload?.limitFileType ? question.payload?.acceptFileTypes : []}/>}
  </QuestionLayout>
}

export default observer(AttachmentQuestion)
