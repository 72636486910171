import { ICounterWithDate, ICompletionStatistic } from '@/Models/counter'
import BackendAdapter from '@/Api/_adapter'

export const apiSetSurveyCompleteEntry = async (surveyId: string) => {
  try {
    return await BackendAdapter.post(`/api/surveys/${surveyId}/userStats/surveyComplete`, {})
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiSetStartSurveyEntry = async (surveyId: string, pageIds: string[]) => {
  try {
    return await BackendAdapter.post(`/api/surveys/${surveyId}/userStats/startSurvey`, pageIds)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetPageCompleteCounters = async (surveyId: string): Promise<ICounterWithDate[]> => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/userStats`, {
      params: {
        type: 'dateCumulativeCompetedCounters',
      },
    }).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetVisitsStatisticByDate = async (surveyId: string): Promise<ICounterWithDate[]> => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/userStats`, {
      params: {
        type: 'visitsStatisticByDate',
      },
    }).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetCompletionStatistic = async (surveyId: string): Promise<ICompletionStatistic> => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/userStats`, {
      params: {
        type: 'completionStatistic',
      },
    }).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetSurveyPersonalisedStatistic = async (surveyId: string) => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/statistic/personalised`).then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}
