export enum TrafficLightTabStateName {
  TargetAudience = 'target_audience',
  Branching = 'branching',
}

export enum TrafficLightElementsPreffix {
  EmptyPageTargetAudience = 'empty_target_audience_page_',
  EmptySurveyTargetAudience = 'empty_target_audience_survey',
  TargetAudienceWithFreeElements = 'empty_target_audience_survey',
}
