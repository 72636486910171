import { flow, Instance, types as t } from 'mobx-state-tree'

import { apiGetSurveyTrafficLightState, apiGetTrafficLightState } from '@/Api/survey'
import { TrafficLightState } from '@/Models/trafficLight'

export interface ITabTrafficLightState extends Instance<typeof TabTrafficLightState> {}

export interface ISurveyTrafficLightState extends Instance<typeof SurveyTrafficLightState> {}

export const TabTrafficLightState = t.model({
  elementsState: t.map(t.enumeration<TrafficLightState>(Object.values(TrafficLightState))),
}).views(self => ({
  get worstStateLevel(): TrafficLightState {
    return Array.from(self.elementsState.values() ?? []).reduce((acc, curr) => (acc < curr ? curr : acc), TrafficLightState.Green)
  },
})).actions(self => ({
  getState: (elementName: string): TrafficLightState | undefined => {
    return self.elementsState.get(elementName)
  },
  getStateRequired: (elementName: string): TrafficLightState | undefined => {
    const state = self.elementsState.get(elementName)

    if (state) {
      return state
    }

    throw new Error('Обязательное состояние не найдено')
  },
  getStateOrDefault: (elementName: string, defaultState: TrafficLightState): TrafficLightState => {
    return self.elementsState.get(elementName) ?? defaultState
  },
  addStateIfNotExist: (elementName: string, state: TrafficLightState) => {
    if (!self.elementsState.has(elementName)) {
      self.elementsState.set(elementName, state)
    }
  },
  createIfNotExistAndGet: (elementName: string, state: TrafficLightState): TrafficLightState => {
    if (!self.elementsState.has(elementName)) {
      self.elementsState.set(elementName, state)
    }

    const surveyState = self.elementsState.get(elementName)

    if (!surveyState) {
      throw new Error('Не найдено состояние атрибута')
    }

    return surveyState
  },
  updateState: (elementName: string, state: TrafficLightState) => {
    self.elementsState.set(elementName, state)
  },
  removeState: (elementName: string) => {
    self.elementsState.delete(elementName)
  },
  findByPrefix: (prefix: string): string[] => {
    return Array.from(self.elementsState.keys() ?? []).filter(x => x.startsWith(prefix))
  },
}))

export const SurveyTrafficLightState = t.model({
  surveyId: t.string,
  tabs: t.map(TabTrafficLightState),
}).views(self => ({
  get worstStateLevel(): TrafficLightState {
    return Array.from(self.tabs.values() ?? []).map(x => x.worstStateLevel).reduce((acc, curr) => (acc < curr ? curr : acc), TrafficLightState.Green)
  },
})).actions(self => ({
  loadTrafficLightState: flow(function * (withOnStartErrors?: boolean) {
    const states = yield apiGetSurveyTrafficLightState(self.surveyId, withOnStartErrors ?? false)

    const surveyTabsState = Object.entries(states)

    surveyTabsState.forEach(([tabName, tabState]: [string, any]) => {
      let tab = self.tabs.get(tabName)

      if (!tab) {
        self.tabs.set(tabName, { elementsState: {} })
        tab = self.tabs.get(tabName)
      }

      const elementsState = Object.entries(tabState.states)

      elementsState.forEach(([elementName, state]: [string, any]) => {
        tab?.updateState(elementName, state as TrafficLightState)
      })
    })
  }),
  getState: (tabName: string): ITabTrafficLightState | undefined => {
    return self.tabs.get(tabName)
  },
  addStateIfNotExist: (tabName: string) => {
    if (!self.tabs.has(tabName)) {
      self.tabs.set(tabName, { elementsState: {} })
    }
  },
  createIfNotExistAndGet: (tabName: string): ITabTrafficLightState => {
    if (!self.tabs.has(tabName)) {
      self.tabs.set(tabName, { elementsState: {} })
    }

    const state = self.tabs.get(tabName)

    if (!state) {
      throw new Error('Не найдено состояние вкладки')
    }

    return state
  },
}))

export const TrafficLightStateStore = t.model({
  surveys: t.map(SurveyTrafficLightState),
}).actions(self => ({
  loadTrafficLightState: flow(function * () {
    const states = yield apiGetTrafficLightState()

    const surveysState = Object.entries(states)

    surveysState.forEach(([surveyId, surveyState]: [string, any]) => {
      let survey = self.surveys.get(surveyId)

      if (!survey) {
        self.surveys.set(surveyId, { surveyId: surveyId, tabs: {} })
        survey = self.surveys.get(surveyId)
      }

      const surveyTabsState = Object.entries(surveyState.states)

      surveyTabsState.forEach(([tabName, tabState]: [string, any]) => {
        let tab = survey?.tabs.get(tabName)

        if (!tab) {
          survey?.tabs.set(tabName, { elementsState: {} })
          tab = survey?.tabs.get(tabName)
        }

        const elementsState = Object.entries(tabState.states)

        elementsState.forEach(([elementName, state]: [string, any]) => {
          tab?.updateState(elementName, state as TrafficLightState)
        })
      })
    })
  }),
  getState: (surveyId: string): ISurveyTrafficLightState | undefined => {
    return self.surveys.get(surveyId)
  },
  addStateIfNotExist: (surveyId: string) => {
    if (!self.surveys.has(surveyId)) {
      self.surveys.set(surveyId, { surveyId: surveyId, tabs: {} })
    }
  },
  createIfNotExistAndGet: (surveyId: string): ISurveyTrafficLightState => {
    if (!self.surveys.has(surveyId)) {
      self.surveys.set(surveyId, { surveyId: surveyId, tabs: {} })
    }

    const surveyState = self.surveys.get(surveyId)

    if (!surveyState) {
      throw new Error('Не найдено состояние опроса')
    }

    return surveyState
  },
}))

