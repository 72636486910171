import { observer } from 'mobx-react'
import { Checkbox } from '@mui/material'

import bem from '@/Helpers/BemClass'
import { IOrganizationStructureElement } from '@/Stores/AdminStores/organizationStructureStore'
import { useMst } from '@/Stores/rootStore'

import './style.scss'

const cnOrganizationStructureRow = bem()('organization-structure-info-row')

interface IOrganizationStructureInfoRow {
  element: IOrganizationStructureElement
  organizationStructureStateSelector: string
  disabled: boolean
  readonly: boolean
}

const OrganizationStructureInfoRow = ({ element, organizationStructureStateSelector, disabled, readonly }: IOrganizationStructureInfoRow) => {
  const tabStore = useMst().admin.tabStore

  const handleCheckboxChange = () => {
    if (readonly) return
    element.changeSelectStateWithCascadeEffect()
    tabStore.setWasChange(true, 'Были внесены изменения в целевую аудиторию')
  }

  return <div className={cnOrganizationStructureRow()}>
    <Checkbox
      id={`organization-structure-element-checkbox_${element.foreignId}`}
      className={cnOrganizationStructureRow('checkbox', { 'is-hidden': readonly })}
      color="primary"
      onChange={handleCheckboxChange}
      onClick={(e) => e.stopPropagation()}
      checked={element.isCascadeSelected}
      disabled={disabled}
      size='small'
    />
    <label
      htmlFor={`organization-structure-element-checkbox_${element.foreignId}`}
      className={cnOrganizationStructureRow('element-name', { 'has-selected-child': element.hasSelectedChild, 'interactive': !readonly })}
      onClick={e => e.stopPropagation()}
    >
      {element.name}
    </label>
  </div>
}

export default observer(OrganizationStructureInfoRow)
