import styled from 'styled-components'

import { device } from '@/Models/device'


export const Container = styled.div`
  padding: 5px 50px 40px;

  @media screen and ${device.mobile} {
    width: auto;
    height: 100vh;
    padding: 0;
  }

`

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 530px;
    width: 100%;

  @media screen and ${device.mobile} {
    flex-direction: column;
    & > div:first-child {
      margin-bottom: 4px;
    }
  }
`

export const ActionsContainer = styled.div`
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: start;
    position: relative;
    width: 400px;
    min-height: 530px;

  @media screen and ${device.mobile} {
    position: sticky;
    background-color: white;
    bottom: 0;
    width: auto;
    align-items: center;
    min-height: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`

export const SelectAllContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  span {
    font-size: 18px;
    line-height: 18px;
    color: #266DC2;
  }
  &.disabled {
    cursor: default;
    path{
      fill: #E6EAF0;
    }
  }

  @media screen and ${device.mobile} {
    margin: 20px;
    justify-content: center;
  }
`

export const CounterContainer = styled.div`
    margin-top: auto;
    margin-bottom: 9px;
    font-size: 18px;
    line-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #8B98A7;
`

export const SeparatorStyled = styled.div`
  border: 1px solid #E6EAF0;
  margin: 0 37px;

  @media screen and ${device.mobile} {
    display: none;
  }
`

export const SelectAllButton = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 17px !important;
  line-height: 150% !important;

  &.disabled {
    color: #babcbf !important;
  }
`
