import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`

export const ColumnContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > button {
    width: fit-content;
    margin-left: auto;
  }
`

export const QuestionContainer = styled.div`
  padding: 0 20px 20px 20px;
  width: 600px;
`

export const QuestionTypeContainer = styled.div`
  margin-left: 60px;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;

  color: #A0A0A0;
  span {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    color: #828282;
    font-size: 18px;
  }
`
