import BackendAdapter from '@/Api/_adapter'
import { IUser } from '@/Models/user'

export const apiGetAllUsers = async (): Promise<IUser[]> => {
  try {
    return await BackendAdapter.get('/api/users/all')
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

// TODO: перестать в будущем использовать или разбить на prod/dev, или поместить за префиксом `/test/`
// Ручка для работы функционала теста пользователей
export const apiGetAllUsersTest = async (): Promise<IUser[]> => {
  try {
    return await BackendAdapter.get('/api/users/all_test')
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

// TODO: перестать в будущем использовать или разбить на prod/dev, или поместить за префиксом `/test/`
// Ручка для работы функционала теста пользователей
export const apiSyncUserTest = async (foreignId: string): Promise<void> => {
  try {
    return await BackendAdapter.get(`/api/users/${foreignId}/sync`)
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}
