import { useContext, useEffect, useMemo, memo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Draggable, DraggableProvidedDragHandleProps, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd'
import { arrayMoveImmutable } from 'array-move'

import DndContext from '../DndContext'

interface IDndSortableList<T> {
  droppableId?: string
  data: T[]
  renderItem: (item: T, index: number, dragHandleProps: DraggableProvidedDragHandleProps | undefined | null) => JSX.Element
  onDragEnd: (oldIndex: number, newIndex: number) => void
}

function DndSortableList<T>({ droppableId, data, renderItem, onDragEnd }: IDndSortableList<T>) {
  const dndContext = useContext(DndContext)
  // TODO: возможно всё таки стоит указывать droppableId в зависимости:
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dndId = useMemo(() => (droppableId ?? '') || uuidv4(), [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let ids = useMemo(() => data.map(x => uuidv4()), [data.length])

  useEffect(() => {
    (async () => {
      dndContext.subscribeToDrugEnd(dndId, handleDragEnd)

      return () => dndContext.unsubscribeFromDrugEnd(dndId)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dndId, onDragEnd, data, dndContext])

  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const sourceIndex = result.source.index
    const destinationIndex = result.destination?.index
    if (sourceIndex === destinationIndex || destinationIndex === undefined) return

    ids = arrayMoveImmutable(ids, sourceIndex, destinationIndex)
    onDragEnd(sourceIndex, destinationIndex)
  }

  if (ids.length === 0) return <></>

  return (<Droppable droppableId={dndId} type={dndId}>
    {(provided, snapshot) => (
      <div ref={provided.innerRef} {...provided.droppableProps} >
        {ids.map((id, i) => <Draggable key={id} draggableId={id} index={i}>
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.draggableProps}>
              {renderItem(data[i], i, provided.dragHandleProps)}
            </div>
          )}
        </Draggable>)}
        {provided.placeholder}
      </div>
    )}
  </Droppable>)
}

export default memo(DndSortableList) as typeof DndSortableList
