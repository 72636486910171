import { memo, ChangeEvent } from 'react'
import { arrayMoveImmutable } from 'array-move'

import Answer from '../../Answer'
import { AnswerContainer } from '../styled'
import { IQuestion } from '../../../Models/pageModel'
import DndSortableList from '../../../../../../Components/DragAndDrop/DndSortableList'
import { Option, QuestionType } from '../../../../../../Models/page'
import { CheckEnumIs } from '../../../../../../Helpers/enumHelper'

interface ISelectAnswerContainer {
  question: IQuestion
  handleChangeQuestion: (prevValue: IQuestion, newValue: IQuestion, withChangeTracking?: boolean, e?: ChangeEvent<{ value: unknown }>) => void
  disabled: boolean
}

const SelectAnswerContainer = ({ question, handleChangeQuestion, disabled }: ISelectAnswerContainer) => {

  const handleAddAnswer = (option: Option) => {
    const newOptions = [
      ...(question.options ?? []),
      option,
    ]

    handleChangeQuestion(question, { ...question, options: newOptions }, true)
  }

  const handleDeleteAnswer = (option: Option) => () => {
    if (option.isCustom) {
      question.hasCustomAnswer = !question.hasCustomAnswer
    }
    handleChangeQuestion(question, { ...question, options: question.options?.filter(x => x !== option) }, true)
  }

  const handleAnswerDragEnd = (oldIndex: number, newIndex: number) => {
    const newOptions = arrayMoveImmutable(question.options, oldIndex, newIndex)
    handleChangeQuestion(question, { ...question, options: newOptions }, true)
  }

  const handleChangeOption = (prevValue: Option, newValue: Option, withChangeTracking?: boolean) => {
    const newOptions = [...question.options.map(x => (x !== prevValue ? x : { ...newValue }))]
    handleChangeQuestion(question, { ...question, options: newOptions }, withChangeTracking)
  }

  return (<AnswerContainer>
    <DndSortableList
      data={question.options}
      renderItem={(item, index, dragHandleProps) => <Answer
        option={item}
        onChange={handleChangeOption}
        onDelete={handleDeleteAnswer(item)}
        withImage={CheckEnumIs(question.type, QuestionType.checkboxWithImage, QuestionType.radioWithImage)}
        additionLine={false}
        dragHandleProps={dragHandleProps}
        disabled={disabled}
      />}
      onDragEnd={handleAnswerDragEnd}
    />
    {!disabled && <Answer
      onAdd={handleAddAnswer}
      withImage={CheckEnumIs(question.type, QuestionType.checkboxWithImage, QuestionType.radioWithImage)}
      additionLine={true}
      showActions={false}
      disabled={false}
    />}
  </AnswerContainer>)
}

export default memo(SelectAnswerContainer)
