import { getSnapshot, types as t } from 'mobx-state-tree'
import { isEqual, maxBy, sortBy } from 'lodash'

import { getAdminRootStore } from '@/Stores/rootStore'


export enum CopyItemType {
  none = '0',
  pageFilter = '1',
  questionRule = '2',
}

export const CopyLogicStore = t.model({
  enableCopyMode: t.boolean,
  type: t.enumeration<CopyItemType>(Object.values(CopyItemType)),
  pageId: t.optional(t.maybe(t.string), undefined),
  questionId: t.optional(t.maybe(t.string), undefined),
}).views(self => ({
  appropriateCopyQuestions: () => {
    if (self.type !== CopyItemType.questionRule) return []
    const pages = getAdminRootStore().surveyInfoRootStore.pagesStore.pages

    const targetPage = pages.find(x => x.id === self.pageId)
    if (!targetPage) throw new Error(`Ошибка консистентности данных. Страница с ид: ${self.pageId} не найдена`)

    const targetQuestion = targetPage.questions.find(x => x.id === self.questionId)
    if (targetQuestion === undefined) throw new Error(`Ошибка консистентности данных. Вопрос на странице ${self.pageId} с ид: ${self.questionId} не найден`)

    const resultQuestionIds: string[] = []
    pages.forEach(x => {
      const equalsQuestions = x.questions.filter(question => question.type === targetQuestion?.type && isEqual(sortBy(question.optionValues), sortBy(targetQuestion.optionValues)))
      resultQuestionIds.push(...equalsQuestions.map(question => question.id))
    })
    return resultQuestionIds.filter(x => x !== self.questionId)
  },
  appropriateCopyPages: (): string[] => {
    if (self.type !== CopyItemType.pageFilter) return []

    const surveyInfoRootStore = getAdminRootStore().surveyInfoRootStore

    const targetPageLogic = surveyInfoRootStore.branchingStore.pagesLogic.get(self.pageId ?? '')
    if (!targetPageLogic) throw new Error(`Ошибка консистентности данных. логика с ид: ${self.pageId} не найдена`)

    const targetPageIds = targetPageLogic.pageFilters.map(x => x.targetPageId)
    const targetPages = surveyInfoRootStore.pagesStore.pages.filter(x => targetPageIds.includes(x.id))

    const maxOrderFromTargetPages = maxBy(targetPages, 'order')?.order ?? 0
    const appropriatePages = surveyInfoRootStore.pagesStore.pages
      .filter(page => page.order > maxOrderFromTargetPages && page.id !== self.pageId)
    return appropriatePages.map(x => x.id)
  },
})).actions(self => ({
  cancelCopy: () => {
    self.enableCopyMode = false
    self.type = CopyItemType.none
    self.pageId = undefined
    self.questionId = undefined
  },
})).actions(self => ({
  setCopyQuestionRule: (pageId: string, questionId: string) => {
    self.enableCopyMode = true
    self.type = CopyItemType.questionRule
    self.pageId = pageId
    self.questionId = questionId

    const branchingStore = getAdminRootStore().surveyInfoRootStore.branchingStore
    const pageStore = getAdminRootStore().surveyInfoRootStore.pagesStore

    pageStore.pages.filter(page => page.questions.some(question => self.appropriateCopyQuestions().includes(question.id)))
      .forEach(page => branchingStore.pagesLogic.get(page.id)?.setIsExpanded(true))
    branchingStore.pagesLogic.get(pageId)?.setIsExpanded(true)
  },

  setCopyPageFilter: (pageId: string) => {
    self.enableCopyMode = true
    self.type = CopyItemType.pageFilter
    self.pageId = pageId

    const branchingStore = getAdminRootStore().surveyInfoRootStore.branchingStore
    self.appropriateCopyPages().forEach(pageId => branchingStore.pagesLogic.get(pageId)?.setIsExpanded(true))
    branchingStore.pagesLogic.get(pageId)?.setIsExpanded(true)
  },

  pasteRules: (pageId: string, questionId: string) => {
    const branchingStore = getAdminRootStore().surveyInfoRootStore.branchingStore
    const copiedLogic = branchingStore.pagesLogic.get(self.pageId ?? '')
    if (!copiedLogic) throw new Error(`Ошибка консистентности данных. логика с ид: ${self.pageId} не найдена`)

    const targetLogic = branchingStore.pagesLogic.get(pageId)
    if (!targetLogic) throw new Error(`Ошибка консистентности данных. логика с ид: ${self.pageId} не найдена`)

    const copiedItems = copiedLogic.questionRules.filter(x => x.questionId === self.questionId).map(x => getSnapshot(x)).map(x => ({
      ...x,
      pageId,
      questionId,
    }))
    targetLogic.pushRules(copiedItems)
    self.cancelCopy()
  },

  pasteFilters: (pageId: string) => {
    const branchingStore = getAdminRootStore().surveyInfoRootStore.branchingStore
    const copiedLogic = branchingStore.pagesLogic.get(self.pageId ?? '')
    if (!copiedLogic) throw new Error(`Ошибка консистентности данных. логика с ид: ${self.pageId} не найдена`)

    const targetLogic = branchingStore.pagesLogic.get(pageId)
    if (!targetLogic) throw new Error(`Ошибка консистентности данных. логика с ид: ${self.pageId} не найдена`)

    const copiedItems = copiedLogic.pageFilters.map(x => getSnapshot(x)).map(x => ({
      ...x,
      pageId,
    }))
    targetLogic.pushPageFilters(copiedItems)
    self.cancelCopy()
  },
}))
