/*
  Для работы MobX необходимы Proxy в движке браузера.
  В IE они отсутствуют (https://kangax.github.io/compat-table/es6/#ie11),
  а использовать полифиллы нет смысла, тк они все "урезанные", напр.: https://github.com/GoogleChrome/proxy-polyfill
  поэтому инициализируем MobX 6.x со спец.параметром ДО того,
  как начнёт исполняться какой-либо код (в том числе импорты) использующий observable.
  https://mobx.js.org/configuration.html#proxy-support
  https://michel.codes/blogs/mobx6#:~:text=IMPROVED%20BROWSER%20SUPPORT
*/
// import 'proxy-polyfill';
// eslint-disable-next-line import/order, import/newline-after-import
import { configure } from 'mobx'
configure({ useProxies: 'ifavailable' })
/* eslint-disable import/first */
import { createContext, useContext } from 'react'
import { getRoot, Instance, onSnapshot, types as t } from 'mobx-state-tree'

import UserStore from '@/Stores/UserStores/index'
import AdminStore from '@/Stores/AdminStores/index'

export const Root = t.model('Root', {
  user: UserStore.model,
  admin: AdminStore.model,
})

export const rootStore = Root.create({
  user: UserStore.defaultState,
  admin: AdminStore.defaultState,
})

// eslint-disable-next-line no-console
onSnapshot(rootStore, snapshot => console.log('Snapshot: ', snapshot))

const rootStoreContext = createContext(rootStore)

export const Provider = rootStoreContext.Provider

export const useMst = () => useContext(rootStoreContext)

export const getAdminRootStore = () => getRoot<Instance<typeof rootStore>>(rootStore).admin
