import { ReactNode, useRef } from 'react'
import { observer } from 'mobx-react'
import { CSSTransition } from 'react-transition-group'

import htmlSafeParse from '@/Helpers/htmlSafeParseHelper'

import RadioQuestion from '../RadioQuestion'
import RatingQuestion from '../RatingQuestion'
import CheckBoxQuestion from '../CheckBoxQuestion'
import TextQuestion from '../TextQuestion'
import Error from '../../Error'
import { useMst } from '../../../Stores/rootStore'
import SliderQuestion from '../SliderQuestion'
import { QuestionType } from '../../../Models/page'
import ImageRadioQuestion from '../ImageRadioQuestion'
import ImageCheckBoxQuestion from '../ImageCheckBoxQuestion'
import AttachmentQuestion from '../AttachmentQuestion'

import { Container, StyledQuestionTitle, StyledQuestionTitleContainer, TransitionContainer } from './styled'

interface IQuestionRouter {
  questionId: string
  questionType: QuestionType
  isVisible?: boolean
  onValidate?: () => boolean
  children?: ReactNode
  onSetIsSkip?: (isSkip: boolean) => void
  allowSkipMainQuestion?: boolean
}

const QuestionRouter = ({ questionId, questionType, isVisible = true, onValidate = () => true, onSetIsSkip, allowSkipMainQuestion = false }: IQuestionRouter) => {
  const questionError = useMst().user.pageStore.getError(questionId)
  const elementRef = useRef<HTMLDivElement>(null)

  const handleChangesCommit = () => {
    if (questionError.error) {
      onValidate()
    }
  }

  let displayedQuestion

  switch (questionType) {
    case QuestionType.text:
      displayedQuestion =
        <TextQuestion questionId={questionId} onChangesCommit={handleChangesCommit}/>
      break
    case QuestionType.rating:
      displayedQuestion = <RatingQuestion questionId={questionId} onChangesCommit={handleChangesCommit}/>
      break
    case QuestionType.checkbox:
      displayedQuestion =
        <CheckBoxQuestion questionId={questionId} onChangesCommit={handleChangesCommit}/>
      break
    case QuestionType.radio:
      displayedQuestion =
        <RadioQuestion questionId={questionId} onChangesCommit={handleChangesCommit}/>
      break
    case QuestionType.slider:
      displayedQuestion =
        <SliderQuestion questionId={questionId} onChangesCommit={handleChangesCommit} onSetIsSkip={onSetIsSkip} allowSkipMainQuestion={allowSkipMainQuestion}/>
      break
    case QuestionType.radioWithImage:
      displayedQuestion =
        <ImageRadioQuestion questionId={questionId} onChangesCommit={handleChangesCommit}/>
      break
    case QuestionType.checkboxWithImage:
      displayedQuestion =
        <ImageCheckBoxQuestion questionId={questionId} onChangesCommit={handleChangesCommit}/>
      break
    case QuestionType.attachment:
      displayedQuestion =
        <AttachmentQuestion questionId={questionId} onChangesCommit={handleChangesCommit}/>
      break
    default:
      displayedQuestion =
        <div>Неизвестный тип вопроса</div>
      break
  }
  return <CSSTransition
    in={isVisible}
    timeout={700}
    unmountOnExit>
    <TransitionContainer height={elementRef.current?.getBoundingClientRect().height ?? 0}>
      <Container ref={elementRef}>
        {displayedQuestion}
        <Error isVisible={!!questionError.error}>{questionError.error}</Error>
      </Container>
    </TransitionContainer>
  </CSSTransition>
}
// Этот вопрос является обязательным

export default observer(QuestionRouter)
