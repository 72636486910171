import { MouseEvent } from 'react'
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'

import DragLabel from '@/Components/DragAndDrop/DragLabel'
import { ICreatePageModel } from '@/Pages/Admin/EditPage/Custom'

import { ActionsContainer, HeaderContainer, PageOrderTextStyled, PageTitleStyled, PageTitleSpaceStyled } from './styled'

interface IPageHeader {
  pageModel: ICreatePageModel
  updatePage: (field: keyof ICreatePageModel, value: unknown) => void
  copyPage?: (page: ICreatePageModel) => void
  deletePage?: (page: ICreatePageModel) => void
  dragHandleProps: DraggableProvidedDragHandleProps | undefined | null
  isDraggable: boolean
  disabled: boolean
}

const CustomPageHeader = ({ pageModel, updatePage, copyPage, deletePage, dragHandleProps, isDraggable, disabled }: IPageHeader) => {
  const handleToggleIsRequired = (event: MouseEvent<HTMLButtonElement>) => {
    !disabled && updatePage('isRequired', !pageModel.isRequired)
    event.stopPropagation()
  }

  const handleDeletePage = () => deletePage?.(pageModel)
  const handleCopyPage = () => copyPage?.(pageModel)

  return (<HeaderContainer>
    <PageOrderTextStyled>Страница {pageModel.order}</PageOrderTextStyled>
    <PageTitleSpaceStyled>
      <Tooltip title={pageModel.title}>
        <PageTitleStyled>
          {pageModel.title}
        </PageTitleStyled>
      </Tooltip>
    </PageTitleSpaceStyled>

    <ActionsContainer disabled={disabled}>
      <FormControlLabel
        value="requiredPage"
        control={<Checkbox color="primary" disabled={disabled} checked={pageModel.isRequired} onClick={(event) => handleToggleIsRequired(event)}/>}
        label="Обязательная страница"
        labelPlacement="start"
      />

      {!disabled && <DeleteOutlineOutlinedIcon onClick={handleDeletePage}/>}
      {!disabled && <FileCopyOutlinedIcon onClick={handleCopyPage}/>}
      <DragLabel isVisible={isDraggable && !disabled} dragHandleProps={dragHandleProps}/>
    </ActionsContainer>
  </HeaderContainer>)
}

export default CustomPageHeader
