export enum RouteEnum {
  pageEdit = 'pageEdit',
  feedback = 'feedback',
  estimate = 'estimate',
  survey = 'survey',
  archive = 'archive',
  organizationStructure = 'organizationStructure',
  services = 'services',
  videoPlayer = 'videoPlayer',
  questionPage = 'questionPage',
  statistic = 'statistic',
  startPage = 'startPage',
  directLink = 'directLink',
  auth = 'auth',
  clientSurveys = '',
}

