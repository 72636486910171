import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import { Skeleton } from '@mui/material'

import { useMst } from '@/Stores/rootStore'
import { Button, Error } from '@/Components'
import { apiSetRules } from '@/Api/rule'
import { IPageFilterSnapshotIn, IQuestionFilterSnapshotIn, IRuleSnapshotIn } from '@/Stores/AdminStores/SurveyInfoStores/branchingStore'
import { SurveyStatus, SurveyType } from '@/Stores/AdminStores/surveysStore'
import { isArchivedHelper } from '@/Helpers/isDisabledHelper'
import bem from '@/Helpers/BemClass'

import Page from './Page'
import './style.scss'

const cnBranchingTab = bem()('branching-tab')

interface IBranchingTabProps {
  surveyId: string
}

const BranchingTab = ({ surveyId }: IBranchingTabProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const store = useMst()
  const trafficLightStateStore = store.admin.trafficLightStateStore
  const survey = store.admin.surveysStore.surveys.find(x => x.surveyId === surveyId)
  const surveyInfoStore = store.admin.surveyInfoRootStore
  const servicesStore = store.admin.adminServicesStore
  const isArchived = isArchivedHelper(survey)
  const tabStore = store.admin.tabStore

  useEffect(() => {
    (async () => {
      if (survey?.type === SurveyType.Gvk) {
        await servicesStore.loadServices(surveyId)
        await surveyInfoStore.pagesStore.loadWithSortByService(surveyId, servicesStore.filteredServices(surveyId).map(x => x.id))
      } else {
        await surveyInfoStore.pagesStore.load(surveyId)
      }


      await surveyInfoStore.branchingStore.load(surveyId)
      surveyInfoStore.branchingStore.validate()
      setIsLoading(false)
    })()
  }, [surveyId, surveyInfoStore.branchingStore, surveyInfoStore.pagesStore])

  const handleSave = async () => {
    surveyInfoStore.branchingStore.validate()
    if (surveyInfoStore.branchingStore.hasErrors) return
    setIsLoading(true)
    const questionRules: IRuleSnapshotIn[] = []
    const pageFilters: IPageFilterSnapshotIn[] = []
    const questionFilters: IQuestionFilterSnapshotIn[] = []
    surveyInfoStore.branchingStore.pagesLogic.forEach(x => {
      questionRules.push(...getSnapshot<IRuleSnapshotIn[]>(x.questionRules))
      questionFilters.push(...getSnapshot<IQuestionFilterSnapshotIn[]>(x.questionFilters))
      pageFilters.push(...getSnapshot<IPageFilterSnapshotIn[]>(x.pageFilters))
    })

    await apiSetRules(surveyId, questionRules, pageFilters, questionFilters)
    await surveyInfoStore.branchingStore.load(surveyId)
    tabStore.setWasChange(false)
    await trafficLightStateStore.createIfNotExistAndGet(surveyId).loadTrafficLightState()
    setIsLoading(false)
  }

  return <div className={cnBranchingTab()}>
    {isLoading ? <>
      <Skeleton variant='rectangular' className={cnBranchingTab('skeleton')} height={48} animation="wave"/>
      <Skeleton variant='rectangular' className={cnBranchingTab('skeleton')} height={48} animation="wave"/>
    </> : surveyInfoStore.pagesStore.pages.map((page, i) => <Page key={page.id} page={page} index={i + 1} isFirstPage={i === 0} disabled={isArchived}/>)}
    <div className={cnBranchingTab('buttons')}>
      <Error isVisible={surveyInfoStore.branchingStore.hasErrors}>Заполните все поля</Error>
      {survey && survey.status !== SurveyStatus.Archived && !isLoading && surveyInfoStore.pagesStore.pages.length > 0 && <Button disabled={isLoading || surveyInfoStore.branchingStore.hasErrors} height="40px" onClick={handleSave}>Сохранить</Button>}
    </div>
  </div>
}

export default observer(BranchingTab)
