import { ChangeEvent, useState } from 'react'
import { observer } from 'mobx-react'
import { Autocomplete, Skeleton, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { IServiceIn } from '@/Stores/AdminStores/adminServicesStore'
import { ISurveyInstance } from '@/Stores/AdminStores/surveysStore'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnSurveyServiceSelector = bem()('survey-service-selector')

interface ISurveyServiceSelector {
  survey: ISurveyInstance
  services: IServiceIn[]
  isLoading?: boolean
  disabled?: boolean
  onSave: (serviceId?: string) => void
}

const SurveyServiceSelector = ({ survey, services, isLoading = false, disabled = false, onSave }: ISurveyServiceSelector) => {
  const [serviceId, setServiceId] = useState<string | undefined>(survey.targetServiceId ?? undefined)

  const handleServiceChange = (e: ChangeEvent<{}>, value: IServiceIn | null) => {
    setServiceId(value?.id ?? undefined)
  }

  const handleSave = () => {
    onSave(serviceId)
  }

  return <div className={cnSurveyServiceSelector()}>
    <div className={cnSurveyServiceSelector('service-card')}>
      <Typography>{isLoading ? <Skeleton variant='rounded'/> : 'Выбран сервис:'}</Typography>
      {isLoading ? <Skeleton variant='rounded'/> : <Autocomplete
        className={cnSurveyServiceSelector('service')}
        selectOnFocus
        clearOnBlur
        value={services.find(x => x.id === serviceId)}
        options={services}
        getOptionLabel={(service) => service?.name}
        renderOption={(props, option, { selected }) => <li {...props} key={option.id}>
          {option.name}
        </li>}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='standard'
            placeholder='Выберите сервис'
          />
        )}
        onChange={handleServiceChange}
        disabled={disabled}
      />}
    </div>
    <div className={cnSurveyServiceSelector('button-container')}>
      {<LoadingButton
        variant='contained'
        disabled={disabled}
        loading={isLoading}
        onClick={handleSave}
        className={cnSurveyServiceSelector('button-element')}
      >
        Сохранить
      </LoadingButton>}
    </div>
  </div>
}

export default observer(SurveyServiceSelector)
