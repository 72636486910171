import { useCallback, ChangeEvent, useState, memo } from 'react'
import { Card, CardContent } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'

import { IQuestion } from '@/Pages/Admin/EditPage/Models/pageModel'
import { QuestionType } from '@/Models/page'
import { CheckEnumIs } from '@/Helpers/enumHelper'
import TextEditor from '@/Components/TextEditor'
import DragLabel from '@/Components/DragAndDrop/DragLabel'

import { ActionContainer, QuestionContainer, QuestionContent, QuestionStyled, QuestionText } from './styled'
import SelectAnswerContainer from './SelectAnswerContainer'
import SliderContainer from './SliderContainer'
import QuestionSettings from './QuestionSettings'
import AttachmentContainer from './AttachmentContainer'
import RatingContainer from './RatingContainer'
import TextContainer from './TextContainer'
import RadioContainer from './RadioContainer'
import CheckboxContainer from './CheckboxContainer'
import RadioWithImageContainer from './RadioWithImageContainer'
import CheckboxWithImageContainer from './CheckboxWithImageContainer'


interface IQuestionProps {
  question: IQuestion
  handleChangeQuestion: (prevValue: IQuestion, newValue: IQuestion, withChangeTracking?: boolean, e?: ChangeEvent<{ value: unknown }>) => void
  handleDeleteQuestion: (question: IQuestion) => void
  handleCopyQuestion: (question: IQuestion) => void
  additionLine: boolean
  draggableProvided: DraggableProvidedDragHandleProps | undefined | null
  disabled: boolean
  isNewQuestion?: boolean
}

const Question = ({ question, handleChangeQuestion, handleDeleteQuestion, handleCopyQuestion, additionLine, draggableProvided, disabled, isNewQuestion = false }: IQuestionProps) => {
  const [editorIsOpen, setEditorIsOpen] = useState(false)

  const handleChangeQuestionText = useCallback((value: string) => {
    const newQuestion = { ...question, text: value }

    if (newQuestion.validationResult) {
      newQuestion.validationResult.emptyQuestionText = false
    }

    handleChangeQuestion(question, newQuestion, true)
  }, [question, handleChangeQuestion])

  const handleChangeQuestionWithTracking = useCallback((prevValue: IQuestion, newValue: IQuestion, e?: ChangeEvent<{ value: unknown }>) => {
    handleChangeQuestion(prevValue, newValue, true, e)
  }, [handleChangeQuestion])

  const OpenEditor = () => {
    setEditorIsOpen(true)
  }
  const CloseEditor = () => {
    setEditorIsOpen(false)
  }

  const getQuestionContainer = () => {
    // TODO: отрефакторить (from Тёма)
    switch (type) {
      case QuestionType.slider: return <SliderContainer handleChangeQuestion={handleChangeQuestionWithTracking} question={question} disabled={question.isMain || disabled}/>
      case QuestionType.rating: return <RatingContainer handleChangeQuestion={handleChangeQuestionWithTracking} question={question} disabled={question.isMain || disabled} isNewQuestion={isNewQuestion}/>
      case QuestionType.attachment: return <AttachmentContainer handleChangeQuestion={handleChangeQuestionWithTracking} question={question} disabled={question.isMain || disabled}/>
      case QuestionType.text: return <TextContainer handleChangeQuestion={handleChangeQuestionWithTracking} question={question} disabled={question.isMain || disabled}/>
      case QuestionType.radio: return <RadioContainer handleChangeQuestion={handleChangeQuestionWithTracking} question={question} disabled={question.isMain || disabled}/>
      case QuestionType.checkbox: return <CheckboxContainer handleChangeQuestion={handleChangeQuestionWithTracking} question={question} disabled={question.isMain || disabled}/>
      case QuestionType.radioWithImage: return <RadioWithImageContainer handleChangeQuestion={handleChangeQuestionWithTracking} question={question} disabled={question.isMain || disabled}/>
      case QuestionType.checkboxWithImage: return <CheckboxWithImageContainer handleChangeQuestion={handleChangeQuestionWithTracking} question={question} disabled={question.isMain || disabled}/>
      default: <></>
    }
  }

  const { text, type } = question
  return (<Card style={{ marginBottom: '20px', overflow: 'visible' }}>
    <CardContent>
      <QuestionContainer>
        <QuestionStyled>
          <QuestionContent>
            <QuestionText>
              <TextEditor
                title='Редактирование вопроса'
                placeholder='Введите текст вопроса'
                value={text}
                isOpen={editorIsOpen}
                onOpen={() => setEditorIsOpen(true)}
                onClose={CloseEditor}
                onChange={handleChangeQuestionText}
                error={!!question.validationResult?.emptyQuestionText}
                disabled={disabled || question.isMain}
              />
            </QuestionText>
            {getQuestionContainer()}
            {CheckEnumIs(type,
              QuestionType.checkbox,
              QuestionType.radio,
              QuestionType.checkboxWithImage,
              QuestionType.radioWithImage
            ) && <SelectAnswerContainer question={question} handleChangeQuestion={handleChangeQuestion} disabled={disabled}/>}
          </QuestionContent>
          <QuestionSettings question={question} isMain={question.isMain} disabled={disabled} onChangeQuestion={handleChangeQuestionWithTracking}/>
        </QuestionStyled>
        <ActionContainer isVisible={!additionLine && !question.isMain}>
          {!disabled && <DeleteOutlineIcon onClick={() => handleDeleteQuestion(question)}/>}
          {!disabled && <FileCopyOutlinedIcon onClick={() => handleCopyQuestion(question)}/>}
          {!disabled && <DragLabel dragHandleProps={draggableProvided}/>}
        </ActionContainer>
      </QuestionContainer>
    </CardContent>
  </Card>)
}

export default memo(Question)
