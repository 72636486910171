import { CommentType, QuestionType, Option, FileType, IFile } from '@/Models/page'
import { IQuestionValidationResult } from '@/Pages/Admin/EditPage/Models/pageModel'
import { AlignEnum, SizeEnum } from '@/Enums/ratingPayloadEnum'

export interface IMainQuestion {
  id?: string
  text: string
  type: QuestionType
  options: Option[]
  hasCustomAnswer: boolean
  commentType?: CommentType
  helperText?: string
  showHelperText?: boolean
  showIsAnonym: boolean
  payload?: IPayload
  validationResult?: IQuestionValidationResult
}

export interface IQuestion {
  id?: string
  text: string
  helperText?: string
  type: QuestionType
  commentType?: CommentType
  payload?: IPayload
  showHelperText?: boolean
  showIsAnonym: boolean
  hasCustomAnswer: boolean
  options: Option[]
  isMain: boolean
  isRequired: boolean
  mainQuestionId?: string
}

export enum QuestionStyle {
  Default = '0',
  Colorized = '1',
}

export interface IPayload {
  helperStartText: string
  helperEndText: string
  showNotReadyToRate: boolean
  notReadyToRateText: string
  requireCommentValue: string
  isEnabledLoadFileButton: boolean
  maxFileCount: number
  limitFileType: boolean
  acceptFileTypes: FileType[]
  adminFiles: IFile[]
  questionStyle: QuestionStyle
  size: SizeEnum
  align: AlignEnum
}

export const defaultPayload: IPayload = {
  helperEndText: '',
  helperStartText: '',
  notReadyToRateText: '',
  showNotReadyToRate: false,
  requireCommentValue: '',
  isEnabledLoadFileButton: false,
  maxFileCount: 1,
  limitFileType: false,
  acceptFileTypes: [],
  adminFiles: [],
  questionStyle: QuestionStyle.Default,
  size: SizeEnum.medium,
  align: AlignEnum.left,
}
