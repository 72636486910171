import { Checkbox } from '@mui/material'
import { observer } from 'mobx-react'
import { range } from 'lodash'
import StarIcon from '@mui/icons-material/Star'

import { useMst } from '@/Stores/rootStore'
import { customValueTextPrefix, notEstimateValueTextPrefix } from '@/Helpers/globalConst'

import { QuestionType } from '@/Models/page'
import { IBaseLogicItemInstance } from '@/Stores/AdminStores/SurveyInfoStores/branchingStore'
import { LabelContainer } from '@/Pages/Admin/SurveyInfoPage/BranchingTab/Components/SharedStyle'
import Error from '@/Components/Error'
import { IQuestionInstance } from '@/Stores/AdminStores/SurveyInfoStores/pagesStore'
import { CheckEnumIs } from '@/Helpers/enumHelper'
import { Condition, Overlap } from '@/Models/rule'
import CustomSelect from '../CustomSelect'
import { createOverlapsMenuItemArray } from '@/Helpers/branchingHelper'

import { AnswerLabelStyled, AnswersContainer, AnswersSelectorContainer } from './styled'

import './style.scss'

interface IAnswersSelectorProps {
  question?: IQuestionInstance
  logicItem: IBaseLogicItemInstance
  disabled: boolean
}

const AnswersSelector = ({ question, logicItem, disabled }: IAnswersSelectorProps) => {
  const tabStore = useMst().admin.tabStore
  const showAnswersBlock = question && question.type !== QuestionType.text && CheckEnumIs(logicItem.condition, Condition.Choose, Condition.NotChoose)

  if (!showAnswersBlock) return <></>

  const createStart = (count: string) => range(0, Number.parseInt(count)).map(x => <StarIcon key={x}/>)

  const handleToggleSelectAnswer = (value: string) => {
    logicItem.toggleSelectAnswer(value)
    tabStore.setWasChange(true)
  }

  const handleOverlapChange = (value: Overlap) => {
    logicItem.setOverlap(value)
    tabStore.setWasChange(true)
  }

  const GenerateAnswers = () => {
    let options: string[]
    switch (question.type) {
      case QuestionType.radio:
      case QuestionType.checkbox:
        options = question.optionValues ?? []
        break
      case QuestionType.checkboxWithImage:
      case QuestionType.radioWithImage:
        options = question.options?.filter(x => !x.isCustom).map(x => x.value) ?? []
        break
      case QuestionType.slider: {
        const questionOptions = question.optionValues ?? ['0', '10']
        options = range(Number.parseInt(questionOptions[0]), Number.parseInt(questionOptions[1]) + 1).map(x => x.toString())
        break
      }
      case QuestionType.rating:
        options = question.options?.map(x => (Number(x.value) + 1).toString()) ?? []
        break
      default:
        options = []
        break
    }

    let answers = options.map((x, i) => <AnswerLabelStyled key={i}>
      <Checkbox
        checked={logicItem.answerHasSelected(x)}
        value={x}
        onChange={(event) => handleToggleSelectAnswer(event.target.value)}
        disabled={disabled}
      />
      {question.type === QuestionType.rating ? createStart(x) : (<div className='branching-answer-variant-text'>{x}</div>)}
    </AnswerLabelStyled>)

    if (question.payload?.showNotReadyToRate) {
      answers = [
        ...answers,
        <AnswerLabelStyled key={question.options?.length ?? 0}>
          <Checkbox
            checked={logicItem.answerHasSelected(notEstimateValueTextPrefix)}
            value={question.payload.notReadyToRateText}
            onChange={(_) => handleToggleSelectAnswer(notEstimateValueTextPrefix)}
            disabled={disabled}
          />
          {question.payload.notReadyToRateText}
        </AnswerLabelStyled>,
      ]
    }

    if (question.hasCustomAnswer) {
      answers = [
        ...answers,
        <AnswerLabelStyled key={question.options?.length ?? 0}>
          <Checkbox
            checked={logicItem.answerHasSelected(customValueTextPrefix)}
            value='Свой вариант'
            onChange={(_) => handleToggleSelectAnswer(customValueTextPrefix)}
            disabled={disabled}
          />
          Свой вариант
        </AnswerLabelStyled>,
      ]
    }

    return answers
  }

  return <>
    {CheckEnumIs(question.type, QuestionType.checkbox, QuestionType.checkboxWithImage) && <CustomSelect
      label="Условие"
      value={logicItem.overlap}
      onChange={e => handleOverlapChange(e.target.value as Overlap)}
      error={logicItem.errors.has('overlap')}
      errorText={logicItem.errors.get('overlap')}
      data={createOverlapsMenuItemArray}
      valueField="value"
      textField="text"
      disabled={disabled}
    />}
    <AnswersSelectorContainer>
      <LabelContainer>Ответы:</LabelContainer>
      <AnswersContainer>
        {GenerateAnswers()}
        <Error isVisible={logicItem.errors.has('answers')}>{logicItem.errors.get('answers')}</Error>
      </AnswersContainer>
    </AnswersSelectorContainer>
  </>
}

export default observer(AnswersSelector)
