import { observer } from 'mobx-react'
import { Grid, IconButton, ListItem, Stack, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'

import { OrganizationStructureStateStage } from '@/Stores/AdminStores/organizationStructureStore'
import { useMst } from '@/Stores/rootStore'
import { IPageModel } from '@/Pages/Admin/SurveyInfoPage/TargetAudienceMappingTab/Models/pageModel'
import { getOrganizationStructureStageName } from '@/Helpers/organizationStructureStageName'
import { useWaiter } from '@/Helpers/react-wait'
import bem from '@/Helpers/BemClass'
import { TrafficLightState } from '@/Models/trafficLight'
import { TrafficLightElementsPreffix, TrafficLightTabStateName } from '@/Enums/trafficLight'
import { ColorTheme } from '@/Models/colorTheme'
import { ToolTip } from '@/Components'

import './style.scss'

const cnOrganizationStructureCollectionRow = bem()('organization-structure-collection-row')

interface IMultiViewRow {
  surveyId: string
  page: IPageModel
  onPageEdit: (organizationStructureStateSelector: string) => void
  highlightDraftOrEmpty?: boolean
}

const OrganizationStructureCollectionRow = ({ surveyId, page, onPageEdit, highlightDraftOrEmpty = false }: IMultiViewRow) => {
  const { isWaiting } = useWaiter()
  const store = useMst()
  const organizationStructureStore = store.admin.organizationStructureStore
  const trafficLightStateStore = store.admin.trafficLightStateStore

  const state = trafficLightStateStore.getState(surveyId)
    ?.getState(TrafficLightTabStateName.TargetAudience)
    ?.getState(`${TrafficLightElementsPreffix.EmptyPageTargetAudience}${page.id}`) ?? TrafficLightState.Green

  const organizationStructureState = organizationStructureStore.getOrganizationStructureState(page.id ?? '')

  return <ListItem key={page.id} className={cnOrganizationStructureCollectionRow({ 'with-data': organizationStructureState?.stage !== OrganizationStructureStateStage.Empty })}>
    <Grid container alignItems='center'>
      <Grid className={cnOrganizationStructureCollectionRow('order')} item>
        <Typography fontWeight='normal' className={cnOrganizationStructureCollectionRow('order-text')}>
          {`Страница ${page.order}`}
        </Typography>
      </Grid>
      <Grid className={cnOrganizationStructureCollectionRow('title')} item xs={5}>
        <Typography fontWeight='bold' className={cnOrganizationStructureCollectionRow('title-text')}>
          <ToolTip title={page.title} arrow theme={ColorTheme.dark}>
            <div>{page.title}</div>
          </ToolTip>
        </Typography>
      </Grid>
      <Grid item>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          spacing={2}
          className={cnOrganizationStructureCollectionRow('actions')}
        >
          <Typography className={cnOrganizationStructureCollectionRow('actions-stage', { empty: organizationStructureState?.stage === OrganizationStructureStateStage.Empty, error: !isWaiting(`change-state-${surveyId}`) && highlightDraftOrEmpty && state !== TrafficLightState.Green })}>
            {getOrganizationStructureStageName(organizationStructureState)}
          </Typography>
          <IconButton className={cnOrganizationStructureCollectionRow('actions-button')} size='small' onClick={() => onPageEdit(page.id)} >
            {organizationStructureState?.stage !== OrganizationStructureStateStage.Empty ? <EditIcon color='primary'/> : <AddIcon color='primary'/>}
          </IconButton>
        </Stack>
      </Grid>
    </Grid>
  </ListItem>
}

export default observer(OrganizationStructureCollectionRow)
