import { useHistory, useRouteMatch } from 'react-router'

import { RouteEnum } from '@/Enums/route'

// TODO: убрать после обновления до react-router@6
export type Params<Key extends string = string> = {
  readonly [key in Key]: string /* | undefined */
}

export interface IRouteManager {
  baseUrl: string
  goBack: () => void
  redirectTo: (page: RouteEnum) => void
  redirectToUrl: (page: RouteEnum, url: string) => void
  replaceTo: (page: RouteEnum) => void
}

export const useRouteManager = (): IRouteManager => {
  const history = useHistory()
  let baseUrl = (useRouteMatch().params as Params)[0]
  baseUrl = baseUrl.replace('index.html', '')
  if (baseUrl?.endsWith('/')) baseUrl = baseUrl.slice(0, -1)

  const goBack = () => history.goBack()
  const redirectTo = (page: RouteEnum) => history.push(`${baseUrl}/${page}`)
  const redirectToUrl = (page: RouteEnum, url: string) => history.push(`${baseUrl}/${page}/${url}`)
  const replaceTo = (page: RouteEnum) => history.replace(`${baseUrl}/${page}`)
  return {
    baseUrl,
    goBack,
    redirectTo,
    redirectToUrl,
    replaceTo,
  }
}
