import { memo, ChangeEvent } from 'react'
import { TextField, Typography } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'

import { getStaticsHybridUrl } from '@/Helpers/staticsHybridUrl'

import DragLabel from '@/Components/DragAndDrop/DragLabel'
import { PhotoInput } from '@/Components'
import { Option } from '@/Models/page'

import { ActionContainer, AnswerStyled, ImageContainer, BodyContainer, PhotoImageContainer, AddPhotoAlternateIconStyled } from './styled'

export interface IAnswerProps {
  option?: Option
  onChange?: (prevValue: Option, newValue: Option, withChangeTracking?: boolean) => void
  onAdd?: (option: Option) => void
  onDelete?: () => void
  showActions?: boolean
  withImage: boolean
  additionLine: boolean
  dragHandleProps?: DraggableProvidedDragHandleProps | undefined | null
  disabled: boolean
}

const Answer = ({ option, onChange, onAdd, onDelete, showActions = true, withImage, additionLine, dragHandleProps, disabled }: IAnswerProps) => {

  const handleBlur = (e: ChangeEvent<{ value: string }>) => {
    if (!e.target.value && !option?.imageId) onDelete?.()
  }

  const handlePhotoChange = (imageId: string, withChangeTracking?: boolean) => {
    if (option) {
      onChange?.(option, { ...option, imageId: imageId, validationResult: undefined }, withChangeTracking)
    } else {
      onAdd?.({ value: '', imageId: imageId, title: '', isCustom: false })
    }
  }

  const handleValueChange = async (e: ChangeEvent<{ value: string }>) => {
    if (option) {
      onChange?.(option, { ...option, value: e.target.value })
    } else {
      onAdd?.({ value: e.target.value, imageId: null, title: '', isCustom: false })
      e.target.value = ''
    }
  }

  const photoImage = <PhotoImageContainer> <AddPhotoAlternateIconStyled/> </PhotoImageContainer>

  const imageRequiredError = option?.validationResult?.imageRequiredError
  const duplicateError = option?.validationResult?.duplicateError

  const hasError = imageRequiredError || duplicateError

  return (<AnswerStyled>
    {withImage &&
        <ImageContainer>
          <PhotoInput
            imageUrl={getStaticsHybridUrl(`/api/file/image/${option?.imageId ?? ''}`)}
            onChange={handlePhotoChange}
            width={60}
            height={60}
            cropHeight={60}
            cropWidth={60}
            disabled={disabled}
          >
            {!option?.imageId ? photoImage : undefined}
          </PhotoInput>
        </ImageContainer>
    }
    <BodyContainer>
      {option?.isCustom ? <TextField
        variant='standard'
        value='Свой вариант'
        disabled
      >
      </TextField> : <TextField
        className={hasError ? 'error' : ''}
        label="Вариант ответа"
        placeholder="Введите текст ответа"
        value={option?.value}
        onChange={handleValueChange}
        onFocus={e => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
        autoFocus={showActions}
        onBlur={handleBlur}
        disabled={disabled}
        variant="standard"
        error={hasError}
        helperText={hasError && (imageRequiredError ? 'Обязательное поле' : 'Дублирующееся поле')}
        inputProps={{ maxLength: 200 }}
      />}
      <ActionContainer isVisible={showActions}>
        {!disabled && <DeleteOutlineIcon onClick={onDelete}/>}
        {!disabled && <DragLabel dragHandleProps={dragHandleProps}/>}
      </ActionContainer>
    </BodyContainer>
  </AnswerStyled>)
}

export default memo(Answer)
