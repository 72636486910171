import { useState, useMemo, memo, ReactNode, CSSProperties, ChangeEvent } from 'react'
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined'
import { v4 } from 'uuid'

import { apiSaveImage } from '@/Api/file'
import { Cropper } from '@/Components/index'

import { Container, ContainerStyled, InputStyle, PhotoInputImageContainer, PhotoLabelStyled } from './styled'

interface IPhotoInput {
  onChange: ((imageId: string, withChangeTracking?: boolean) => void) | ((imageId: string, withChangeTracking?: boolean) => Promise<void>)
  imageUrl?: string
  defaultImage?: string
  width?: number
  height?: number
  cropWidth?: number
  cropHeight?: number
  preLoadImage?: boolean
  iconColor?: string
  rounded?: boolean
  containerStyle?: CSSProperties
  imageStyle?: CSSProperties
  imageContainerStyle?: CSSProperties
  children?: ReactNode
  disabled?: boolean
}

const PhotoInput = ({ imageUrl, defaultImage, onChange, width = 215, height = 150, cropWidth = 215, cropHeight = 150, preLoadImage = true, iconColor = 'black', rounded = false, containerStyle = {}, imageStyle, imageContainerStyle = {}, children, disabled = false, ...other }: IPhotoInput) => {
  const [cropper, setCropper] = useState<undefined | any>()
  const id = useMemo(() => v4(), [])
  const handlePhotoChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const { files } = e.target
    if (files === null) return
    const reader = new FileReader()
    const file = files[0]
    e.target.value = ''

    reader.onloadend = () => {
      setCropper(reader.result)
    }
    reader.readAsDataURL(file)
  }

  const handleCropImage = async (image: HTMLCanvasElement) => {
    const blob = await new Promise(resolve => image.toBlob((blob: Blob | null) => resolve(blob)))
    if (!blob) return
    const imageId = await apiSaveImage(image.toDataURL('image/jpeg', 1).replace(/^data:image\/(png|jpg|jpeg);base64,/, ''))
    await onChange(imageId, true)
    setCropper(undefined)
  }

  return (<>
    <Container style={{ width, height, ...containerStyle }} className='phot-imp' disabled={disabled} {...other}>
      <ContainerStyled>
        <InputStyle disabled={disabled} id={id} type='file' accept='image/jpeg,image/png,image/jpg,image/gif;' onChange={handlePhotoChange}/>
        {
          children ?? <label htmlFor={id}>
            <PhotoInputImageContainer style={ imageContainerStyle } className={rounded ? 'rounded' : ''} width={width} height={height}>
              <img
                src={imageUrl ?? defaultImage}
                className='photoInputImage'
                alt='Изображение'
                decoding='async'
                loading='lazy'
                style={imageStyle ?? { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', height: '100%', width: 'auto' }}
              />
            </PhotoInputImageContainer>
          </label>
        }
      </ContainerStyled>
      {!disabled && <PhotoLabelStyled htmlFor={id} style={{ color: iconColor }}>
        <AddAPhotoOutlinedIcon style={{ maxWidth: '60%', maxHeight: '60%' }} className={'prompt'} fontSize='large'/>
      </PhotoLabelStyled>}
    </Container>
    {cropper && <Cropper
      isOpen={cropper !== undefined}
      handleClose={() => setCropper(undefined)}
      src={cropper}
      handleSaveImage={handleCropImage}
      width={cropWidth}
      height={cropHeight}
    />}
  </>)
}

export default memo(PhotoInput)
