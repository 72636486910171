import { MouseEvent } from 'react'
import { IHeaderParams } from 'ag-grid-community'
import { Box, TableSortLabel } from '@mui/material'

import bem from '@/Helpers/BemClass'
import SearchInput from '@/Components/SearchInput'
import './style.scss'

const cnAgHeaderCell = bem()('ag-header-cell')

const CustomHeader = (props: IHeaderParams) => {
  const field = props.column.getColId()
  const onSortRequested = (event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLSpanElement>) => {
    props.progressSort(event.shiftKey)
    props.api.onSortChanged()
  }

  const getSortIndexLabel = () => {
    return props.column.getSort() && props.column.getSortIndex() !== null && props.column.getSortIndex() !== undefined ? Number(props.column.getSortIndex()) + 1 : ''
  }

  const handleSearch = (property: any, filter: string | string[]) => {
    const filterInstance = props.api.getFilterInstance(field)
    if (filterInstance) {
      filterInstance.setModel({
        filterType: 'text',
        type: 'contains',
        filter: filter,
      })
    }
    props.api.onFilterChanged()
  }

  return (<Box className={cnAgHeaderCell('custom')}>
    <SearchInput
      id={`search-${field}`}
      property={props.column}
      label={props.displayName}
      handleSearch={handleSearch}
    />
    <div className={cnAgHeaderCell('sort')}>
      <TableSortLabel
        active={!!props.column.getSort()}
        direction={props.column.getSort() ?? 'asc' }
        onClick={(event) => onSortRequested(event)}
      />
      <div className={cnAgHeaderCell('sort-direction')}>{getSortIndexLabel()}</div>
    </div>
  </Box>)
}

export default CustomHeader
