import { useEffect, useState } from 'react'
import { Redirect, Route, RouteProps } from 'react-router'
import { useSessionStorage } from 'usehooks-ts'

import { PermissionType } from '@/Models/permission'
import { apiCheckUserPermissions } from '@/Api/permission'
import { ISessionStorageErrors } from '@/Enums/apiError'
import { useMst } from '@/Stores/rootStore'

const PrivateRoute = ({ children, ...other }: RouteProps) => {
  const UserStore = useMst().user
  const accessDeniedError = `Ошибка авторизации: Для пользователя ${UserStore.cookieUserId} не найден доступ`
  const [status, setStatus] = useState<'pending' | 'success' | 'denied'>('pending')
  const [sessionStorageErrors, setSessionStorageErrors] = useSessionStorage<ISessionStorageErrors[]>('errors', [])

  useEffect(() => {
    (async () => {
      const res = await apiCheckUserPermissions([{ type: PermissionType.admin }])
      if (res) setStatus('success')
      else setStatus('denied')
    })()
  }, [])

  const createError = () => {
    if (!sessionStorageErrors.map(x => x.text).includes(accessDeniedError)) {
      sessionStorageErrors.push({ text: accessDeniedError, autoHideDuration: 8000, variant: 'error' })
      setSessionStorageErrors(sessionStorageErrors)
    }
  }

  if (status === 'pending') return <></>
  // TODO: убрать хардкод префикса урла
  if (status === 'denied') {
    createError()
    return <Redirect to={'/'}/>
  }
  return (<Route {...other}>
    {children}
  </Route>)
}

export default PrivateRoute
