import { useEffect, useRef, useState } from 'react'
import { useMount } from 'react-use'
import { Link as RouterLink } from 'react-router-dom'
import { Button, Link, Tooltip } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import AddIcon from '@mui/icons-material/Add'
import ArchiveIcon from '@mui/icons-material/Archive'
import EditIcon from '@mui/icons-material/Edit'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import ScheduleIcon from '@mui/icons-material/Schedule'
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize'
import TableRowsIcon from '@mui/icons-material/TableRows'
import { observer } from 'mobx-react'
import { ApplyColumnStateParams, ColumnState, SortChangedEvent } from 'ag-grid-community'
import { orderBy } from 'lodash'
import { AgGridReact } from 'ag-grid-react'
import { useQueryString } from 'use-route-as-state'
import { useLocalStorage, useSessionStorage } from 'usehooks-ts'

import { TableSkeleton } from '@/Components'
import { useRouteManager } from '@/Hooks'
import { useWaiter } from '@/Helpers/react-wait'
import { RouteEnum } from '@/Enums/route'
import { useMst } from '@/Stores/rootStore'
import { ISurveyInstance, SurveyStatus, SurveysStoreFilter } from '@/Stores/AdminStores/surveysStore'
import Dialog, { IDialog } from '@/Components/Dialog'
import { useStartStopDialog } from '@/Components/StartStopDialog'
import AgTable from '@/Components/AgGridTable'
import { IAction } from '@/Components/SpeedDial'
import PhotoInput from '@/Components/PhotoInput'
import { ReactComponent as PlayIcon } from '@/Images/Play.svg'
import { ReactComponent as StopIcon } from '@/Images/stop.svg'
import { ReactComponent as PinIcon } from '@/Images/pin.svg'
import ImageDefault from '@/Images/default.jpg'
import { HeadCell } from '@/Models/headCell'
import { TrafficLightState } from '@/Models/trafficLight'
import bem from '@/Helpers/BemClass'

import SurveyModal from './SurveyModal'

import './style.scss'

const cnSurveyListPage = bem()('survey-list-page')

export enum SurveyListFilter {
  NotArchived,
  Archived,
}

interface ISurveyListPage {
  surveyListFilter: SurveyListFilter
  showAddSurveyButton?: boolean
}

const SurveyListPage = ({ surveyListFilter, showAddSurveyButton }: ISurveyListPage) => {
  const gridRef = useRef<AgGridReact>(null)
  const [editingSurvey, setEditingSurvey] = useState<ISurveyInstance | undefined>()
  const [addSurvey, setAddSurvey] = useState<boolean>(false)
  const routeManager = useRouteManager()
  const store = useMst()
  const surveyStore = store.admin.surveysStore
  const trafficLightStateStore = store.admin.trafficLightStateStore
  const [dialogState, setDialogState] = useState<IDialog | undefined>()
  const { startWait, stopWait, isWaiting } = useWaiter()
  const [tableState, setTableState] = useState<ApplyColumnStateParams>({})
  const startStopDialog = useStartStopDialog(setDialogState)
  const [{ sort }, setSortQueryParams] = useQueryString()
  const [sessionStorageAdminSurveysListColumnsSort, setSessionStorageAdminSurveysListColumnsSort] =
    useSessionStorage<ColumnState[]>(`admin_surveys-list${surveyListFilter === SurveyListFilter.Archived ? '-archived' : ''}_columns-sort`, [])
  const [localStorageAdminPinnedSurveys, setLocalStorageAdminPinnedSurveys] =
    useLocalStorage<ColumnState[]>(`admin_surveys-list${surveyListFilter === SurveyListFilter.Archived ? '-archived' : ''}_survey-pin`, [])
  // const [sessionStorageCurrentSessionSurveyIds] = useSessionStorage('current_session_survey_ids', [''])

  useMount(() => {
    (async () => {
      await loadData()
      setDialogState(undefined)
    })()
  })

  useEffect(() => {
    surveyStore.setFilter(surveyListFilter === SurveyListFilter.Archived ? SurveysStoreFilter.Archived : SurveysStoreFilter.NotArchived)
  }, [surveyListFilter, surveyStore])

  const loadData = async () => {
    startWait('survey-list-loading')
    await surveyStore.loadSurveys()

    surveyStore.sortByCreateAt()

    let columnState: ColumnState[] | null = null

    if (sort) {
      const sortQuery = JSON.parse(sort?.toString())
      if (sortQuery.length > 0) {
        setSessionStorageAdminSurveysListColumnsSort(sortQuery)
        columnState = sortQuery
      }
    }

    if (!columnState) {
      columnState = sessionStorageAdminSurveysListColumnsSort
    }

    if (columnState.length > 0) {
      gridRef.current?.columnApi.applyColumnState({ state: columnState })
      setTableState({ state: columnState })
    }

    await trafficLightStateStore.loadTrafficLightState()
    stopWait('survey-list-loading')
  }

  const handleCloseEditSurveyModal = async (surveyId?: string) => {
    setEditingSurvey(undefined)
  }

  const handleDuplicateSurvey = async (surveyId: string) => {
    startWait('survey-list-loading')
    await surveyStore.duplicateSurvey(surveyId)
    stopWait('survey-list-loading')
  }

  const handleArchivingSurvey = async (surveyId: string) => {
    const handleConfirmArchivingClick = async () => {
      try {
        startWait('survey-list-loading')
        await surveyStore.archiveSurvey(surveyId)
      } finally {
        setDialogState(undefined)
        stopWait('survey-list-loading')
      }
    }

    setDialogState({
      open: true,
      title: 'Архивирование опроса',
      text: 'Вы действительно хотите архивировать опрос?',
      handleClose: () => setDialogState(undefined),
      actions: [
        <Button onClick={() => setDialogState(undefined)} key="no">Нет</Button>,
        <Button onClick={handleConfirmArchivingClick} key="yes">Да</Button>,
      ],
    })
  }

  const handleStartSurvey = (survey: ISurveyInstance) => () => {
    startStopDialog.startSurvey(survey)
  }

  const handleStopSurvey = (survey: ISurveyInstance) => async () => {
    startStopDialog.stopSurvey(survey)
  }

  const getStatusPriority = (status: any) => {
    switch (status) {
      case 'Запущен': return 1
      case 'Создан': return 2
      case 'Завершен': return 3
      case 'Отменен': return 4
      default: return 5
    }
  }

  const header: Array<HeadCell<ISurveyInstance>> = [
    {
      key: 'title',
      label: 'Название',
      createControl: obj => {
        const worstStateLevel = trafficLightStateStore.getState(obj.surveyId)?.worstStateLevel

        return (<div className={cnSurveyListPage('column-title', {
          'yellow': worstStateLevel === TrafficLightState.Yellow,
          'red': worstStateLevel === TrafficLightState.Red,
          'no-state': [undefined, TrafficLightState.Green].includes(worstStateLevel),
        })}>
          {surveyListFilter === SurveyListFilter.NotArchived && (isWaiting(`change-state-${obj.surveyId}`) ? <Button disabled><ScheduleIcon width={22} height={22}/></Button> : (obj.status === SurveyStatus.Surveyed ? <Button key={obj.surveyId} onClick={handleStopSurvey(obj)}>
            <StopIcon width={22} height={22}/>
          </Button> : <Button key={obj.surveyId} onClick={handleStartSurvey(obj)}>
            <PlayIcon width={22} height={22}/>
          </Button>))}
          <div>
            <PhotoInput
              disabled={surveyListFilter === SurveyListFilter.Archived}
              rounded
              width={38}
              height={38}
              cropWidth={256}
              cropHeight={149}
              onChange={obj.ChangeImage}
              imageUrl={obj.imageUrl}
              defaultImage={ImageDefault}
              containerStyle={{ border: '2px solid #E6EAF0', borderRadius: '50%' }}
            />
          </div>
          <div className={cnSurveyListPage('survey-title')}>
            <Link
              color='black'
              underline='none'
              component={RouterLink}
              to={`${routeManager.baseUrl}/${surveyListFilter === SurveyListFilter.NotArchived ? RouteEnum.survey : RouteEnum.archive}/${obj.surveyId}`}
              style={{ fontWeight: 'bold', marginBottom: '0', marginTop: '0' }}
            >
              {obj.title}
            </Link>
          </div>
        </div>)
      },
      isVisible: true,
      isSearchable: true,
      flex: 6,
      minWidth: 215,
      comparator: (valueA: any, valueB: any): boolean => {
        const firstValue = (valueA as string).trim()
        const secondValue = (valueB as string).trim()

        const firstValueLoverCase = firstValue.toLowerCase()
        const secondValueLoverCase = secondValue.toLowerCase().trim()

        // Сначала проверяем общий порядок без учета регистра
        if (firstValueLoverCase !== secondValueLoverCase) {
          return firstValueLoverCase > secondValueLoverCase
        }

        // Если значения равны без учета регистра, сортируем по регистру
        if (firstValue === secondValue) {
          return false
        } else {
          return firstValue > secondValue
        }
      },
    },
    {
      key: 'textType',
      label: 'Тип',
      isVisible: true,
      isSearchable: true,
      flex: 1,
      minWidth: 80,
      cellClass: 'justify-content-center',
    },
    {
      key: 'start',
      label: 'Начало',
      createControl: obj => <>{obj.start.getTime() < 0 ? '' : obj.start.toLocaleDateString()}</>,
      isVisible: true,
      isSearchable: true,
      flex: 1,
      minWidth: 80,
      cellClass: 'justify-content-center',
    },
    {
      key: 'createdAt',
      label: 'Создан',
      createControl: obj => <>{obj.createdAt.getTime() < 0 ? '' : `${obj.createdAt.toLocaleDateString()} ${obj.createdAt.toLocaleTimeString()}`}</>,
      isVisible: true,
      isSearchable: true,
      flex: 1.6,
      minWidth: 72,
      cellClass: 'justify-content-center',
    },
    {
      key: 'end',
      label: 'Окончание',
      createControl: obj => <>{obj.end.toLocaleDateString()}</>,
      isVisible: true,
      isSearchable: true,
      flex: 1.4,
      minWidth: 110,
      cellClass: 'justify-content-center',
    },
    {
      key: 'author',
      label: 'Автор',
      createControl: obj => <div className={cnSurveyListPage('column-author')}>{obj.author}</div>,
      isVisible: true,
      isSearchable: true,
      flex: 1.5,
      minWidth: 72,
      cellClass: 'justify-content-center',
    },
    {
      key: 'textStatus',
      label: 'Статус',
      createControl: obj => (obj.status === SurveyStatus.Surveyed ? <div style={{ color: '#219653' }}>{obj.textStatus}</div> : <div>{obj.textStatus}</div>),
      isVisible: true,
      isSearchable: true,
      comparator: (valueA: any, valueB: any): boolean => {
        const num1 = getStatusPriority(valueA)
        const num2 = getStatusPriority(valueB)
        return num1 === num2 ? false : num1 > num2
      },
      flex: 0.9,
      minWidth: 70,
      cellClass: 'justify-content-center',
    },
    {
      key: 'textSurveyType',
      label: 'Представление',
      createControl: obj => (obj.withCards ? <Tooltip title="Карточное" placement='right'>
        <div><DashboardCustomizeIcon className={cnSurveyListPage('survey-view')} width={38} height={38}/></div>
      </Tooltip> : <Tooltip title="Классическое" placement='right'>
        <div><TableRowsIcon className={cnSurveyListPage('survey-view')} width={38} height={38}/></div>
      </Tooltip>),
      isVisible: true,
      isSearchable: true,
      flex: 1.7,
      minWidth: 71,
      cellClass: 'justify-content-center',
    },
    {
      key: 'version',
      label: 'Запусков',
      createControl: obj => <>{obj.version + 1}</>,
      isVisible: false,
      isSearchable: true,
      flex: 1.1,
      minWidth: 92,
      cellClass: 'justify-content-center',
    },
    {
      key: 'startedCount',
      label: 'Начали',
      isVisible: true,
      isSearchable: true,
      flex: 0.9,
      minWidth: 72,
      cellClass: 'justify-content-center',
    },
    {
      key: 'completedCount',
      label: 'Завершили',
      isVisible: true,
      isSearchable: true,
      flex: 1.3,
      minWidth: 107,
      cellClass: 'justify-content-center',
    },
  ]

  const [visibleColumns, setVisibleColumns] = useLocalStorage('survey-list-visible-columns', header.map(x => x.label).filter(x => x !== 'Номер запуска'))

  if (visibleColumns.length === 0) {
    const defaultHeaderCollection = header.map(x => x.label).filter(x => x !== 'Номер запуска')
    setVisibleColumns(defaultHeaderCollection)
    header.forEach(x => { x.isVisible = defaultHeaderCollection.includes(x.label) })
  } else {
    header.forEach(x => { x.isVisible = visibleColumns.includes(x.label) })
  }

  const actions: IAction[] = [
    ...(surveyListFilter === SurveyListFilter.NotArchived ? [{
      visible: true,
      disabled: false,
      icon: <EditIcon/>,
      title: 'Редактировать',
      onClick: (item: ISurveyInstance) => setEditingSurvey(item),
    },
    {
      visible: true,
      disabled: false,
      icon: <FileCopyIcon/>,
      title: 'Дублировать запуск',
      onClick: async (item: ISurveyInstance) => await handleDuplicateSurvey(item.surveyId),
    },
    {
      visible: true,
      disabled: false,
      icon: <ArchiveIcon/>,
      title: 'Архивировать',
      onClick: async (item: ISurveyInstance) => await handleArchivingSurvey(item.surveyId),
    }] : []),
    {
      visible: true,
      disabled: false,
      icon: (obj) => <PinIcon width={22} height={22} className={cnSurveyListPage('pin', { unpinned: !obj.isPinned })}/>,
      title: (obj) => (!obj.isPinned ? 'Закрепить' : 'Открепить'),
      onClick: async (item: ISurveyInstance) => await item.togglePin(),
    },
  ]

  const handleSetAddSurvey = (isOpen: boolean) => async (surveyId?: string) => {
    setAddSurvey(isOpen)
    if (!isOpen) {
      setTableState(prev => ({
        defaultState: { sort: null },
      }))
    }
  }

  const onSortChanged = (event: SortChangedEvent) => {
    const columnState = gridRef.current?.columnApi.getColumnState()
    if (columnState) {
      setSessionStorageAdminSurveysListColumnsSort(columnState)
      const columnStateQuery = JSON.stringify(columnState.filter(x => x.sort != null).map(x => ({ colId: x.colId, sort: x.sort, sortIndex: x.sortIndex })))
      setSortQueryParams({ sort: columnStateQuery })
    }
  }

  const handleColumnsHasChanging = (columns: Array<HeadCell<ISurveyInstance>>) => {
    const visibleElements = columns.filter(x => x.isVisible).map(x => x.label)
    setVisibleColumns(visibleElements)
  }

  const handleGetCustomRowClass = (index: number, data: ISurveyInstance) => {
    return `${cnSurveyListPage(`survey-row-${data.surveyId}`)} ${cnSurveyListPage('survey-row', { pinned: data.isPinned })}`
  }

  return (<div
    className={cnSurveyListPage({
      'archived': surveyListFilter === SurveyListFilter.Archived,
      'not-archived': surveyListFilter === SurveyListFilter.NotArchived,
    })}
  >
    <div className={cnSurveyListPage('button-container', { hidden: !showAddSurveyButton })}>
      <LoadingButton
        className={cnSurveyListPage('button', { uploading: true })}
        loading={isWaiting('survey-list-loading')}
        onClick={() => setAddSurvey(true)}
        variant='contained'
        component='label'
      >
        <AddIcon/>Создать
      </LoadingButton>
    </div>
    {isWaiting('survey-list-loading') ? <TableSkeleton/> : <AgTable
      data={surveyStore.filterNotPinned}
      pinnedData={surveyStore.filterPinned}
      headCells={header}
      rowActions={actions}
      state={tableState}
      gridRef={gridRef}
      onSortChanged={onSortChanged}
      onColumnsHasChanging={handleColumnsHasChanging}
      getCustomRowClass={handleGetCustomRowClass}
    />}
    {addSurvey && <SurveyModal isOpen={addSurvey} handleClose={handleSetAddSurvey(false)}/>}
    {dialogState && <Dialog {...dialogState}/>}
    {editingSurvey && <SurveyModal
      survey={editingSurvey}
      isOpen={!!editingSurvey}
      handleClose={handleCloseEditSurveyModal}
    />}
  </div>)
}

export default observer(SurveyListPage)
