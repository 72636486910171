import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

interface ISelectWithNumbers {
  label?: string
  start: number
  end: number
  value: any
  onChange: any
  disabled?: boolean
}

const SelectWithNumbers = ({ label, start, end, onChange, value, disabled }: ISelectWithNumbers) => {
  return (<>
    <FormControl variant="standard">
      <InputLabel shrink id="select-with-numbers">
        {label}
      </InputLabel>
      <Select
        labelId="select-with-numbers"
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {createMenuItemArray(start, end)}
      </Select>
    </FormControl>
  </>)
}

const createMenuItemArray = (start: number, end: number) => {
  const result = []
  for (let i = start; i <= end; i++) {
    result.push(<MenuItem key={i} value={i.toString()}>{i}</MenuItem>)
  }
  return result
}

export default SelectWithNumbers
