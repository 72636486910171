import { useState, ChangeEvent } from 'react'
import { FormGroup, TextField, useMediaQuery, useTheme } from '@mui/material'
import { observer } from 'mobx-react'

import QuestionLayout from '@/Components/Questions/QuestionLayout'
import { useMst } from '@/Stores/rootStore'
import CheckBox from '@/Components/CheckBox'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnQuestion = bem()('question')

interface ICheckBoxQuestion {
  questionId: string
  onChangesCommit: () => void
}

const CheckBoxQuestion = ({ questionId, onChangesCommit }: ICheckBoxQuestion) => {
  const muiTheme = useTheme()
  const UIisMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))
  const pageStore = useMst().user.pageStore
  const question = pageStore.getQuestion(questionId)
  const answer = pageStore.getAnswer(questionId)

  const [customValue, setCustomValue] = useState<string>(answer.value?.find(x => x.includes('custom;'))?.substring('custom;'.length) ?? '')

  const handleChangeSelectOption = (value: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    if (!checked) {
      answer.setValue(answer.value.filter(x => x !== value) ?? [])
    } else {
      answer.setValue([...answer.value ?? [], value])
    }
    onChangesCommit()
  }

  const handleChangeCustomAnswer = (e: ChangeEvent<{ value: string }>) => {
    const value = e.target.value
    setCustomValue(value)
    if (answer.value?.some(x => x.includes('custom;'))) answer.setValue(answer.value?.map(x => (x.includes('custom;') ? `custom;${value}` : x)))
    else answer.setValue([...answer.value, `custom;${value}`])
  }

  return <QuestionLayout
    question={question}
  >
    <div className={cnQuestion({ checkbox: true })}>
      <FormGroup className={cnQuestion('answers')}>
        {question.options?.map((option, i) => <CheckBox
          key={option.value}
          id={`quest_${questionId}_answ_${i}`}
          text={option.value}
          checked={answer.value.some(x => x === option.value)}
          onChange={handleChangeSelectOption(option.value)}
          padding='0 8px 0 0'
        />
        )}
        {question.hasCustomAnswer && <div className={cnQuestion('custom-answer', { mobile: UIisMobile })}>
          <CheckBox
            id={`quest_${questionId}_self_version_answer`}
            checked={answer.value?.some(x => x.includes('custom;'))}
            onChange={handleChangeSelectOption(`custom;${customValue}`)}
            padding='0 8px 0 0'
          />
          <TextField
            className={cnQuestion('custom-answer__input')}
            placeholder="Введите свой вариант ответа"
            value={customValue}
            onChange={handleChangeCustomAnswer}
            variant="standard"
          />
        </div>}
      </FormGroup>
    </div>
  </QuestionLayout>
}


export default observer(CheckBoxQuestion)
