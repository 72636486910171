import { Checkbox, FormControlLabel } from '@mui/material'

import { FileType } from '@/Models/page'

interface ICheckBoxList {
  checked: FileType[]
  onChange: (item: FileType) => void
}

const CheckBoxList = ({ checked, onChange }: ICheckBoxList) => {
  const items = Object.values(FileType)

  return (<>
    {items.map((x, i) => <>
      <FormControlLabel
        componentsProps={{ typography: { variant: 'subtitle2' } }}
        control={<Checkbox checked={checked?.includes(x)} onChange={() => onChange(x)}/>}
        label={Object.keys(FileType)[i]}
      />
    </>)}
  </>)
}

export default CheckBoxList
