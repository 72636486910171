import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'

interface ISubscriber {
  id: string
  notifyHandler: (message: string) => void
  filters: ErrorType[]
}

export enum ErrorType {
  Err400,
  Err401,
  Other,
}

const allErrorTypes = Object.values(ErrorType).filter(x => typeof x !== 'string').map(x => x as ErrorType)

class ErrorNotifier {
  private subscribers: ISubscriber[] = []

  public subscribe = (notifyHandler: (message: string) => void, filters: ErrorType[] = allErrorTypes): string => {
    const id: string = uuidv4()
    this.subscribers = [...this.subscribers, { id, notifyHandler, filters }]
    return id
  }

  public unsubscribe = (id: string): void => {
    this.subscribers = this.subscribers.filter(x => x.id !== id)
  }

  public notify = (errorType: ErrorType, message: string): void => {
    if (message?.toLowerCase().startsWith('warning')) return
    this.subscribers.forEach(x => x.filters.includes(errorType) && x.notifyHandler(message))
  }
}

export const errorNotifier = new ErrorNotifier()

export const configureErrorLogging = (): void => {

  // configure axios error
  axios.interceptors.response.use(res => res, async error => {
    if (error.response?.status === 400) {
      errorNotifier.notify(ErrorType.Err400, (error.response.data))
    } else if (error.response?.status === 401) {
      errorNotifier.notify(ErrorType.Err401, `Ошибка авторизации: ${error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error.response)}`)
    // } else if (error.response.status === 404) errorNotifier.notify(ErrorType.Other, `Объект не найден:  ${error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error.response)}`)
    } else if (error.response?.status !== 404) {
      errorNotifier.notify(ErrorType.Other, `Ошибка сервера: ${error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error.response)}`)

    // если запрос был отменён/прерван, например по таймауту, то ничего не делаем
    } else if (error.code === 'ECONNABORTED' && !error.response) {
      return
    }
    return await Promise.reject(error.response)
  })
}
