import { observer } from 'mobx-react'
import { Dialog, Grid, GridSize, IconButton } from '@mui/material'

import { ReactComponent as ZipDocument } from '@/Images/zipFile.svg'
import { apiGetAnswersReport, apiGetAnswersWithFilesReport, apiGetFeedbackReportSurvey, apiGetFullReport } from '@/Api/manage'
import bem from '@/Helpers/BemClass'

import './style.scss'

const cnGetReportModal = bem()('get-report-modal')

export enum ReportType {
  XlsxAnswersReport,
  CsvAnswersReport,
  CsvAnswersReportWithFiles,
  CsvFeedbackReport,
  ZipFullReport,
}

interface IGetReportModal {
  surveyId: string
  reportsType: ReportType[]
  onClose: () => void
  onStartDownload: () => void
  onEndDownload: () => void
}

const GetReportModal = ({ surveyId, reportsType, onClose, onStartDownload, onEndDownload }: IGetReportModal) => {
  const handleGetAnswersReport = async () => {
    onStartDownload()
    await apiGetAnswersReport(surveyId)
    onEndDownload()
  }

  const handleGetReportWithFile = async () => {
    onStartDownload()
    await apiGetAnswersWithFilesReport(surveyId)
    onEndDownload()
  }

  const handleGetFeedbackReport = async () => {
    onStartDownload()
    await apiGetFeedbackReportSurvey(surveyId)
    onEndDownload()
  }

  const handleGetFullReport = async () => {
    onStartDownload()
    await apiGetFullReport(surveyId)
    onEndDownload()
  }

  const columnSize: boolean | GridSize = reportsType.length === 1 ? 12 : 'auto'

  return (<>
    <Dialog className={cnGetReportModal()} onClose={onClose} open={true}>
      <div className={cnGetReportModal('container')}>
        <h3>Скачать отчёт</h3>
        <span>Выберите вариант отчёта</span>
        <Grid className={cnGetReportModal('button-grid-container')} container>
          {reportsType.includes(ReportType.CsvAnswersReport) && <Grid
            className={cnGetReportModal('button__container')}
            xl={columnSize}
            lg={columnSize}
            md={columnSize}
            sm={columnSize}
            xs={columnSize}
          >
            <IconButton className={cnGetReportModal('button__icon')} onClick={handleGetAnswersReport}>
              <ZipDocument height='100%' width='100%'/>
            </IconButton>
            <div className={cnGetReportModal('button__text')}>
              Ответы респондентов без вложений <br/>
            </div>
          </Grid>}
          {reportsType.includes(ReportType.CsvAnswersReportWithFiles) && <Grid
            className={cnGetReportModal('button__container')}
            xl={columnSize}
            lg={columnSize}
            md={columnSize}
            sm={columnSize}
            xs={columnSize}
          >
            <IconButton className={cnGetReportModal('button__icon')} onClick={handleGetReportWithFile}>
              <ZipDocument height='100%' width='100%' />
            </IconButton>
            <div className={cnGetReportModal('button__text')}>
              Ответы респондентов с вложениями
            </div>
          </Grid>}
          {reportsType.includes(ReportType.CsvFeedbackReport) && <Grid
            className={cnGetReportModal('button__container')}
            xl={columnSize}
            lg={columnSize}
            md={columnSize}
            sm={columnSize}
            xs={columnSize}
          >
            <IconButton className={cnGetReportModal('button__icon')} onClick={handleGetFeedbackReport}>
              <ZipDocument height='100%' width='100%'/>
            </IconButton>
            <div className={cnGetReportModal('button__text')}>
              Обратная <br/>
              связь
            </div>
          </Grid>}
          {reportsType.includes(ReportType.ZipFullReport) && <Grid
            className={cnGetReportModal('button__container')}
            xl={columnSize}
            lg={columnSize}
            md={columnSize}
            sm={columnSize}
            xs={columnSize}
          >
            <IconButton className={cnGetReportModal('button__icon')} onClick={handleGetFullReport}>
              <ZipDocument height='100%' width='100%' />
            </IconButton>
            <div className={cnGetReportModal('button__text')}>
              Отчёт по респондентам
            </div>
          </Grid>}
        </Grid>
      </div>
    </Dialog>
  </>)
}

export default observer(GetReportModal)
