export interface IPermission {
  id?: string
  type: PermissionType
  surveyId?: string
}

export enum PermissionType {
  expert = '0',
  admin = '1',
  shortAnswersView = '2',
}

export interface ISurveyPermissions {
  permissions: IPermissionsWithUsers[]
}

export interface IPermissionsWithUsers {
  // задаем ид чтобы обновить существующий permission
  id?: string
  type: PermissionType
  surveyId?: string
  organizationStructureElementsId: string[]
}
