import { CommentType, QuestionType, Option } from '@/Models/page'
import { IPayload } from '@/Models/pageEdit'

export interface IQuestionValidationResult {
  emptyQuestionText?: boolean
  emptyHelperText?: boolean
  emptySkipText?: boolean
}

export interface IQuestion {
  id?: string
  questionId: string
  text: string
  type: QuestionType
  options: Option[]
  isMain: boolean
  hasCustomAnswer: boolean
  isRequired: boolean
  commentType?: CommentType
  helperText?: string
  showHelperText: boolean
  showIsAnonym: boolean
  payload?: IPayload
  validationResult?: IQuestionValidationResult
}

export interface IPageModel {
  id?: string
  pageId?: string
  serviceId?: string
  title: string
  description: string
  category?: string
  imageId?: string
  isRequired: boolean
  order: number
  questions: IQuestion[]
  version?: number
}

export const EmptyPage: IPageModel = {
  id: undefined,
  imageId: '',
  order: 0,
  title: '',
  isRequired: false,
  description: '',
  questions: [],
}
