import { cast, flow, Instance, SnapshotIn, types as t } from 'mobx-state-tree'
import { orderBy } from 'lodash'

import { SurveyStatus, SurveyType } from '@/Stores/AdminStores/surveysStore'
import { apiGetRunningSurvey, apiGetRunningSurveys } from '@/Api/survey'

export interface ISurveyInstance extends Instance<typeof Survey> {
}

export interface ISurveyIn extends SnapshotIn<typeof Survey> {

}

export interface IUserPageInstance extends Instance<typeof UserPage> {
}

export const UserPage = t.model({
  id: t.identifier,
  title: t.string,
  imageId: t.string,
  description: t.maybeNull(t.string),
  category: t.maybeNull(t.string), // TODO заменить на идентификатор
  owner: t.maybeNull(t.string),
  isComplete: false,
  isRequired: false,
  order: t.integer,
})

export const Survey = t.model({
  surveyId: t.identifier,
  version: t.number,
  title: t.string,
  description: t.maybeNull(t.string),
  start: t.Date,
  end: t.Date,
  imageId: t.string,
  status: t.enumeration<SurveyStatus>('SurveyStatus', Object.values(SurveyStatus)),
  type: t.enumeration<SurveyType>(Object.values(SurveyType)),
  withCards: t.boolean,
  pages: t.array(UserPage),
  isCompleted: false,
  withExpertPermission: t.boolean,
  withShortAnswersViewPermission: t.boolean,
})

export const UserSurveysStore = t.model({
  runningSurveys: t.array(Survey),
}).views(self => ({})).actions(self => ({
  getUserSurveys: (flow(function * () {
    const state = yield apiGetRunningSurveys()
    self.runningSurveys = cast(state)
  })),
  getRunningSurveysSortedBy: (flow(function * (field: string, order: 'asc' | 'desc') {
    const state = yield apiGetRunningSurveys()
    self.runningSurveys = cast(orderBy(state, [field], [order]))
  })),
  getSurvey: (flow(function * (surveyId: string) {
    const survey = yield apiGetRunningSurvey(surveyId)
    if (survey) self.runningSurveys = cast([survey])
  })),
  syncSurvey: (flow(function * (surveyId: string) {
    const state = yield apiGetRunningSurvey(surveyId)
    self.runningSurveys = cast([...self.runningSurveys.filter(x => x.surveyId !== surveyId), state])
    return state as ISurveyInstance
  })),
}))
