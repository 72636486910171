import { useEffect, useState, ChangeEvent } from 'react'
import { Button, Link, Tab, Tabs, Typography } from '@mui/material'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet-async'
import { observer } from 'mobx-react'

import { ReactComponent as PlayIcon } from '@/Images/Play.svg'
import { ReactComponent as StopIcon } from '@/Images/stop.svg'
import { IDialog } from '@/Components/Dialog'
import { useWaiter } from '@/Helpers/react-wait'
import { useStartStopDialog } from '@/Components/StartStopDialog'
import { useRouteManager } from '@/Hooks'
import { RouteEnum } from '@/Enums/route'
import ExitWithoutSaveDialog from '@/Components/DialogExitWithoutSave'
import { ISurveyInstance, SurveyStatus, SurveyType } from '@/Stores/AdminStores/surveysStore'
import { useMst } from '@/Stores/rootStore'
import { ContentLoader, Dialog } from '@/Components'
import CustomEditPage from '@/Pages/Admin/EditPage/Custom'
import bem from '@/Helpers/BemClass'
import { TrafficLightState } from '@/Models/trafficLight'
import { TrafficLightTabStateName } from '@/Enums/trafficLight'
import GeneralInformationTab from '@/Pages/Admin/SurveyInfoPage/GeneralInformationTab'
import { Container, BreadcrumbsStyled } from '@/Pages/Admin/SurveyInfoPage/styled'
import FeedbackTab from '@/Pages/Admin/SurveyInfoPage/FeedbackTab'
import ServicesTab from '@/Pages/Admin/SurveyInfoPage/ServicesTab'
import PagesRepresentationTab from '@/Pages/Admin/SurveyInfoPage/PagesRepresentationTab'
import BranchingTab from '@/Pages/Admin/SurveyInfoPage/BranchingTab'
import { AnswerTab } from '@/Pages/Admin/SurveyInfoPage/AnswerTab'
import PeriodCompareTab from '@/Pages/Admin/SurveyInfoPage/PeriodCompareTab'
import TargetAudienceMappingTab from '@/Pages/Admin/SurveyInfoPage/TargetAudienceMappingTab'
import { DisplayMode } from '@/Pages/Admin/SurveyInfoPage/AnswerTab/Components/PageAnswers'
import RespondentActivitiesTab from '@/Pages/Admin/SurveyInfoPage/RespondentActivitiesTab'

import './style.scss'

// Тема сорян, я смешал стили, но тут слишком удобен bem для светофора))
// TODO: перевести стилевые компоненты в bem
const cnSurveyTabs = bem()('survey-tabs')

enum SurveyInfoTabsEnum {
  generalInformation = '',
  pageEdit = 'pageEdit',
  servicesList = 'services',
  pagesRepresentation = 'pagesRepresentation',
  branching = 'branching',
  feedback = 'feedback',
  answers = 'answers',
  targetAudienceMapping = 'targetAudienceMapping',
  periodCompare = 'periodCompare',
  respondentActivities = 'respondentActivities',
}

const SurveyInfoPage = () => {
  const [survey, setSurvey] = useState<ISurveyInstance>()
  const [isLoading, setIsLoading] = useState(true)
  const { surveyId, selectedTab } = useParams<{ surveyId: string, selectedTab: string }>()
  const routeManager = useRouteManager()
  const store = useMst()
  const surveysStore = store.admin.surveysStore
  const tabStore = store.admin.tabStore
  const trafficLightStateStore = store.admin.trafficLightStateStore
  const [dialogWindowIsOpen, setDialogWindowIsOpen] = useState<boolean>(false)
  const [goToUrl, setGoToUrl] = useState<string>(SurveyInfoTabsEnum.generalInformation)
  const [dialogState, setDialogState] = useState<IDialog | undefined>()
  const { isWaiting, Wait } = useWaiter()
  const startStopDialog = useStartStopDialog(setDialogState)
  const surveyTrafficLightState = trafficLightStateStore.getState(surveyId)

  useEffect(() => {
    (async () => {
      await surveysStore.loadSurveyInfo(surveyId)
      const survey = surveysStore.surveys.find(x => x.surveyId === surveyId)
      setSurvey(survey)
      tabStore.setWasChange(false)
      setDialogWindowIsOpen(false)

      await trafficLightStateStore.createIfNotExistAndGet(surveyId).loadTrafficLightState(false)

      setIsLoading(false)
    })()
  }, [surveyId, surveysStore, tabStore])

  const handleChangeTab = (e: ChangeEvent<{}>, tab: string) => {
    if (!tabStore.wasChanged || survey?.status === SurveyStatus.Archived) {
      routeManager.redirectToUrl(survey?.status === SurveyStatus.Archived ? RouteEnum.archive : RouteEnum.survey, `${surveyId}/${tab}`)
      return
    }
    setGoToUrl(`${surveyId}/${tab}`)
    setDialogWindowIsOpen(tabStore.wasChanged)
  }

  const redirectToParentPage = () => {
    if (!tabStore.wasChanged) {
      routeManager.redirectTo(survey?.status === SurveyStatus.Archived ? RouteEnum.archive : RouteEnum.survey)
      return
    }
    setGoToUrl('')
    setDialogWindowIsOpen(tabStore.wasChanged)
  }

  const handleRedirectToMainAdminPage = () => {
    setDialogState(undefined)
    routeManager.redirectTo(RouteEnum.survey)
    tabStore.setWasChange(false)
  }

  const redirectToMainAdminPage = () => {
    if (tabStore.wasChanged) {
      setDialogState({
        open: true,
        handleClose: () => setDialogState(undefined),
        title: 'На странице были изменения',
        text: 'Выйти без сохранения?',
        actions: [
          <Button key={'changes-no-btn'} onClick={() => setDialogState(undefined)}>Нет</Button>,
          <Button key={'changes-yes-btn'} onClick={() => handleRedirectToMainAdminPage()}>Да</Button>,
        ],
      })
    } else {
      routeManager.redirectTo(RouteEnum.survey)
      tabStore.setWasChange(false)
    }
  }

  const getStartStopButton = (survey: ISurveyInstance) => {
    if (survey.status === SurveyStatus.Surveyed) {
      return (<Button onClick={() => startStopDialog.stopSurvey(survey)}>
        <Wait for={`change-state-${survey.surveyId}`} with={<ScheduleIcon width={22} height={22}/>}>
          <StopIcon width={22} height={22}/>
        </Wait>
        Остановить
      </Button>)
    } else {
      return (<Button onClick={() => startStopDialog.startSurvey(survey)}>
        <Wait for={`change-state-${survey.surveyId}`} with={<ScheduleIcon width={22} height={22}/>}>
          <PlayIcon width={22} height={22}/>
        </Wait>
        Запустить
      </Button>)
    }
  }

  if (isLoading) {
    return (
      <Container id="survey_info_container">
        <ContentLoader/>
      </Container>
    )
  }

  if (!survey) {
    redirectToMainAdminPage()
    return <></>
  }

  const targetAudienceTabTrafficLightWorstStateLevel = surveyTrafficLightState?.getState(TrafficLightTabStateName.TargetAudience)?.worstStateLevel
  const branchingTabTrafficLightWorstStateLevel = surveyTrafficLightState?.getState(TrafficLightTabStateName.Branching)?.worstStateLevel

  return (<Container id="survey_info_container">
    <Helmet>
      <title>{survey?.title ?? 'Прохождение опроса'}</title>
    </Helmet>
    <div className="header">
      <BreadcrumbsStyled separator="›" aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="#" onClick={redirectToParentPage}>{survey.status === SurveyStatus.Archived ? 'Архив опросов' : 'Опросы'}</Link>
        <Typography color="textPrimary">{survey.title}</Typography>
      </BreadcrumbsStyled>
      {!survey.archivedDate && <div>
        {getStartStopButton(survey)}
      </div>}
    </div>
    <Tabs
      value={selectedTab ?? SurveyInfoTabsEnum.generalInformation}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleChangeTab}
      scrollButtons="auto"
    >
      <Tab value={SurveyInfoTabsEnum.generalInformation} label="Общая информация"/>
      {survey.type === SurveyType.Gvk && <Tab value={SurveyInfoTabsEnum.servicesList} label="Список сервисов"/>}
      {survey.type !== SurveyType.Gvk && <Tab value={SurveyInfoTabsEnum.pageEdit} label="Создание страниц"/>}
      {survey.type !== SurveyType.Gvk && <Tab value={SurveyInfoTabsEnum.pagesRepresentation} label="Представление страниц"/>}
      {survey.type !== SurveyType.Gvk && <Tab
        value={SurveyInfoTabsEnum.targetAudienceMapping}
        className={cnSurveyTabs('tab')}
        label={<Typography variant='inherit' fontWeight='bold' >Респонденты</Typography>}
        classes={{
          root: cnSurveyTabs('tab-button', {
            'yellow': targetAudienceTabTrafficLightWorstStateLevel === TrafficLightState.Yellow,
            'red': targetAudienceTabTrafficLightWorstStateLevel === TrafficLightState.Red,
            // 'green': targetAudienceTabTrafficLightWorstStateLevel === TrafficLightState.Green,
          }),
        }}
      />}
      {[SurveyType.Custom, SurveyType.Gvk].includes(survey.type) && <Tab
        value={SurveyInfoTabsEnum.branching}
        className={cnSurveyTabs('tab')}
        label={<Typography variant='inherit' fontWeight='bold' >Ветвление</Typography>}
        classes={{
          root: cnSurveyTabs('tab-button', {
            'yellow': branchingTabTrafficLightWorstStateLevel === TrafficLightState.Yellow,
            'red': branchingTabTrafficLightWorstStateLevel === TrafficLightState.Red,
            // 'green': branchingTabTrafficLightWorstStateLevel === TrafficLightState.Green,
          }),
        }}
      />}
      {<Tab value={SurveyInfoTabsEnum.answers} label="Ответы"/>}
      <Tab value={SurveyInfoTabsEnum.feedback} label="Обратная связь"/>
      {survey.type === SurveyType.Repeatable && <Tab value={SurveyInfoTabsEnum.periodCompare} label="Сравнение периодов"/>}
      {survey.type !== SurveyType.Gvk && <Tab value={SurveyInfoTabsEnum.respondentActivities} label="Прохождение"/>}
    </Tabs>

    {(selectedTab === SurveyInfoTabsEnum.generalInformation || selectedTab === undefined) && <GeneralInformationTab surveyId={surveyId}/>}
    {selectedTab === SurveyInfoTabsEnum.servicesList && <ServicesTab surveyId={surveyId}/>}
    {selectedTab === SurveyInfoTabsEnum.pageEdit && <CustomEditPage isCardState={survey.withCards}/>}
    {selectedTab === SurveyInfoTabsEnum.pagesRepresentation && <PagesRepresentationTab surveyId={surveyId}/>}
    {selectedTab === SurveyInfoTabsEnum.targetAudienceMapping && <TargetAudienceMappingTab surveyId={surveyId}/>}
    {selectedTab === SurveyInfoTabsEnum.branching && <BranchingTab surveyId={surveyId}/>}
    {selectedTab === SurveyInfoTabsEnum.answers && <AnswerTab surveyId={surveyId} displayMode={DisplayMode.Admin}/>}
    {selectedTab === SurveyInfoTabsEnum.feedback && <FeedbackTab surveyId={surveyId}/>}
    {selectedTab === SurveyInfoTabsEnum.respondentActivities && <RespondentActivitiesTab surveyId={surveyId}/>}
    {dialogWindowIsOpen && <ExitWithoutSaveDialog
      onConfirm={() => routeManager.redirectToUrl(RouteEnum.survey, goToUrl)}
      onClose={() => setDialogWindowIsOpen(false)}
    />}
    {dialogState && <Dialog {...dialogState}/>}
    {selectedTab === SurveyInfoTabsEnum.periodCompare && <PeriodCompareTab surveyId={surveyId}/>}
  </Container>)
}

export default observer(SurveyInfoPage)
