import { observer } from 'mobx-react'

import MultilineTextField from '@/Components/MultilineTextField'
import QuestionLayout from '@/Components/Questions/QuestionLayout'
import { useMst } from '@/Stores/rootStore'

import '@/Components/Questions/style.scss'

interface ITextQuestion {
  questionId: string
  onChangesCommit: () => void
}

const TextQuestion = ({ questionId, onChangesCommit }: ITextQuestion) => {
  const pageStore = useMst().user.pageStore
  const question = pageStore.getQuestion(questionId)
  const answer = pageStore.getAnswer(questionId)

  return <QuestionLayout question={question}>
    <MultilineTextField
      placeholder="Введите ответ"
      label="Ответ"
      value={answer.singleValue}
      onChange={e => answer.setValue(e.target.value)}
      onBlur={onChangesCommit}
    />
  </QuestionLayout>
}

export default observer(TextQuestion)
