import { ChangeEvent } from 'react'
import { Checkbox, FormControl, TextField } from '@mui/material'
import { cloneDeep } from 'lodash'

import { defaultPayload, IPayload } from '@/Models/pageEdit'
import { KeysOfType } from '@/Models/headCell'
import SelectWithNumbers from '@/Components/SelectWithNumbers'
import { ToolTip } from '@/Components'
import { Option } from '@/Models/page'
import { IQuestionValidationResult } from '@/Pages/Admin/EditPage/Models/pageModel'
import SharedAdditionalSettings from '@/Pages/Admin/EditPage/Components/Question/SharedAdditionalSettings'

import { CheckBoxContainer } from '../styled'

import { Container, ScaleSelectValueContainer } from './styled'

export interface IQuestionData {
  text: string
  showHelperText?: boolean
  showIsAnonym: boolean
  payload?: IPayload
  options: Option[]
  helperText?: string
  validationResult?: IQuestionValidationResult
}

interface ISliderContainerProps<TQuestionType extends IQuestionData> {
  handleChangeQuestion: (prevValue: TQuestionType, newValue: TQuestionType, e?: ChangeEvent<{ value: unknown }>) => void
  question: TQuestionType
  disabled?: boolean
  showNotReadyToRateBlock?: boolean
  showRequireCommentValueBlock?: boolean
  showAnonymBlock?: boolean
}

const SliderContainer = <TQuestionType extends IQuestionData>({ handleChangeQuestion, question, disabled = false, showNotReadyToRateBlock = true, showRequireCommentValueBlock = false, showAnonymBlock = false }: ISliderContainerProps<TQuestionType>) => {


  const handleChangeOption = (index: number) => (e: ChangeEvent<{ value: unknown }>) => {
    const newOptions = cloneDeep(question.options)
    newOptions[index].value = e.target.value as string
    handleChangeQuestion(question, { ...question, options: newOptions }, e)
    if (question.validationResult) {
      handleChangeQuestion(question, {
        ...question,
        options: newOptions,
        validationResult: {
          ...question.validationResult,
        },
      }, e)
    } else {

    }
  }

  const handleToggleShowNotReadyToRate = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newPayload = question.payload ? { ...question.payload, showNotReadyToRate: checked } : undefined
    handleChangeQuestion(question, { ...question, payload: newPayload }, e)
  }

  const handleEditPayload = (field: KeysOfType<IPayload, string>) => (e: ChangeEvent<{ value: unknown }>) => {
    const newPayload = question.payload ? { ...question.payload } : defaultPayload
    newPayload[field] = e.target.value as never

    handleChangeQuestion(question, { ...question, payload: newPayload }, e)
  }

  return (<Container>
    <ToolTip arrow title={'Окончание интервала шкалы обязательного комментария'}>
      <SharedAdditionalSettings
        handleChangeQuestion={handleChangeQuestion}
        question={question}
        disabled={disabled}
        showAnonymBlock={showAnonymBlock}
      />
    </ToolTip>

    {showNotReadyToRateBlock && <CheckBoxContainer>
      <TextField
        className={question.validationResult?.emptySkipText ? 'error' : ''}
        style={{ width: '350px' }}
        disabled={disabled}
        placeholder='Введите текст для варианта не оценивать'
        onFocus={e => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
        value={question.payload?.notReadyToRateText ?? ''}
        name={'notReadyToRateText'}
        onChange={handleEditPayload('notReadyToRateText')}
        error={question.validationResult?.emptySkipText ?? false}
        variant='standard'
      />
      <Checkbox checked={question.payload?.showNotReadyToRate ?? false} onChange={handleToggleShowNotReadyToRate} disabled={disabled}/>
    </CheckBoxContainer>}

    <ScaleSelectValueContainer>
      <FormControl>
        <SelectWithNumbers
          label={'Начало шкалы'}
          value={question.options[0].value}
          onChange={handleChangeOption(0)}
          disabled={disabled}
          start={0}
          end={8}
        />
        <TextField
          label="Начало шкалы"
          placeholder="Введите текст подсказки"
          value={question.payload?.helperStartText}
          onChange={handleEditPayload('helperStartText')}
          fullWidth
          onFocus={e => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
          disabled={disabled}
          variant="standard"
        />
      </FormControl>

      <FormControl>
        <SelectWithNumbers
          label={'Окончание шкалы'}
          value={question.options[1].value}
          onChange={handleChangeOption(1)}
          disabled={disabled}
          start={2}
          end={10}
        />
        <TextField
          label="Окончание шкалы"
          placeholder="Введите текст подсказки"
          value={question.payload?.helperEndText}
          onChange={handleEditPayload('helperEndText')}
          fullWidth
          onFocus={e => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
          disabled={disabled}
          variant="standard"
        />
      </FormControl>
    </ScaleSelectValueContainer>
    {showRequireCommentValueBlock &&
        <FormControl className={'requireCommentValue'} fullWidth style={{ marginTop: '25px' }}>
          <SelectWithNumbers
            label={'Обязательный комментарий для значений от начала шкалы до выбранного значения (включая его)'}
            value={question.payload?.requireCommentValue}
            onChange={handleEditPayload('requireCommentValue')}
            disabled={disabled}
            start={0}
            end={10}
          />
        </FormControl>}
  </Container>)
}

export default SliderContainer
