import { ChangeEvent, MouseEvent, useState } from 'react'
import { useMount } from 'react-use'
import {
  Button,
  Checkbox,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter'
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import StarIcon from '@mui/icons-material/Star'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { ToolTip } from '@/Components'
import { KeysOfType } from '@/Models/headCell'
import { defaultPayload, IPayload } from '@/Models/pageEdit'
import { ColorTheme } from '@/Models/colorTheme'
import { IQuestionData } from '@/Pages/Admin/EditPage/Components/Question/SliderContainer'
import { AlignEnum, SizeEnum } from '@/Enums/ratingPayloadEnum'
import SharedAdditionalSettings from '@/Pages/Admin/EditPage/Components/Question/SharedAdditionalSettings'
import { Option } from '@/Models/page'
import { notReadyToRateText } from '@/Helpers/globalConst'
import bem from '@/Helpers/BemClass'

import './style.scss'

interface IRatingContainerProps<TQuestionType extends IQuestionData> {
  handleChangeQuestion: (prevValue: TQuestionType, newValue: TQuestionType, e?: ChangeEvent<{ value: unknown }>) => void
  question: TQuestionType
  isNewQuestion?: boolean
  disabled?: boolean
}

const cnRating = bem()('rating')

const RatingContainer = <TQuestionType extends IQuestionData>({ handleChangeQuestion, question, isNewQuestion = false, disabled = false }: IRatingContainerProps<TQuestionType>) => {
  const [starTitles, setStarTitles] = useState<Option[]>([])
  const [starCount, setStarCount] = useState<number>(question.options.length > 0 ? question.options.length : 5)

  useMount(() => {
    (async () => {
      // Если вопрос старый, то у него нет options. Для такого вопроса создаём 5 пустых options
      if (question.text && question.options.length === 0 && !isNewQuestion) {
        handleChangeStarCount(5)()
      }
      const emptyArray: Option[] = Array.from(Array(10), (x, i) => ({ value: i.toString(), imageId: null, title: '', isCustom: false }))
      emptyArray.splice(0, question.options.length, ...question.options)
      setStarTitles(emptyArray)
    })()
  })

  const handleEditPayload = (field: KeysOfType<IPayload, string>) => (e: ChangeEvent<{ value: unknown }>) => {
    const newPayload = question.payload ? { ...question.payload } : defaultPayload
    newPayload[field] = e.target.value as never
    handleChangeQuestion(question, { ...question, payload: newPayload }, e)
  }

  const handleToggleShowNotReadyToRate = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newPayload = question.payload ? { ...question.payload, showNotReadyToRate: checked } : undefined
    handleChangeQuestion(question, { ...question, payload: newPayload }, e)
  }

  const handleChangeStarCount = (newValue?: number) => () => {
    if (!newValue || newValue < 2 || newValue > 10) return
    if (question.payload && question.payload.size === SizeEnum.large && newValue > 5) return
    setStarCount(newValue)
    const newOptions = starTitles.slice(0, newValue)
    handleChangeQuestion(question, { ...question, options: newOptions })
  }

  const handleChangeStar = (field: string) => (e: MouseEvent<HTMLElement>, value: unknown) => {
    if (!value) return
    const newPayload = question.payload ? { ...question.payload, [field]: value } : undefined
    handleChangeQuestion(question, { ...question, payload: newPayload })
  }

  const handleChangeStarSize = (e: MouseEvent<HTMLElement>, value: string) => {
    if (!value) return

    if (value === SizeEnum.large && question.payload && starCount > 5) {
      handleChangeStarCount(5)()
    }

    const newPayload = question.payload ? { ...question.payload, size: value } : undefined
    handleChangeQuestion(question, { ...question, payload: newPayload })
  }

  const handleChangeStarTitle = (starNumber: number) => (event: ChangeEvent<{ value: string }>) => {
    const newTitles = starTitles.map((x, i) => ((i === starNumber) ? { ...x, title: event.target.value } : x))
    setStarTitles(newTitles)
    handleChangeQuestion(question, { ...question, options: newTitles.slice(0, starCount) })
  }

  return (<>{ !isNewQuestion && <Grid container direction='column' rowSpacing={2} className={cnRating()}>
    <Grid item>
      <SharedAdditionalSettings
        handleChangeQuestion={handleChangeQuestion}
        question={question}
        disabled={disabled}
      />
    </Grid>

    <Grid item container alignItems='center'>
      <TextField
        variant='standard'
        disabled={disabled}
        name='notReadyToRateText'
        placeholder='Введите текст для варианта не оценивать'
        className={cnRating('textfield-not-ready-to-rate')}
        value={question.payload?.notReadyToRateText ?? notReadyToRateText}
        error={question.validationResult?.emptySkipText ?? false}
        onFocus={e => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
        onChange={handleEditPayload('notReadyToRateText')}
      />
      <Checkbox checked={question.payload?.showNotReadyToRate ?? false} onChange={handleToggleShowNotReadyToRate} disabled={disabled}/>
    </Grid>
    <Grid item container xs justifyContent='center' spacing={1}>
      {/* Размер звёзд */}
      <Grid item container xs={12} sm direction='column' alignItems='center' spacing={1}>
        <Grid item>
          <Typography variant='body1' component='div' align='center'>
            <strong>Размер звёзд</strong>
          </Typography>
        </Grid>
        <Grid item container alignItems='center' justifyContent='center'>
          <ToggleButtonGroup value={question.payload?.size} onChange={handleChangeStarSize} exclusive>
            <ToggleButton className={cnRating('toggle-button', { starSize: true })} value={SizeEnum.large} key='80'>
              <ToolTip arrow theme={ColorTheme.dark} title='Большой' enterDelay={0}>
                <StarIcon className={cnRating('star-icon', { large: true })}/>
              </ToolTip>
            </ToggleButton>
            <ToggleButton className={cnRating('toggle-button', { starSize: true })} value={SizeEnum.medium} key='60'>
              <ToolTip arrow theme={ColorTheme.dark} title='Средний' enterDelay={0}>
                <StarIcon className={cnRating('star-icon', { medium: true })}/>
              </ToolTip>
            </ToggleButton>
            <ToggleButton className={cnRating('toggle-button', { starSize: true })} value={SizeEnum.small} key='40'>
              <ToolTip arrow theme={ColorTheme.dark} title='Малый' enterDelay={0}>
                <StarIcon className={cnRating('star-icon', { small: true })}/>
              </ToolTip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      {/* Выравнивание звёзд */}
      <Grid item container xs={12} sm direction='column' alignItems='center' spacing={1}>
        <Grid item>
          <Typography variant='body1' component='div' align='center'>
            <strong>Выравнивание звёзд</strong>
          </Typography>
        </Grid>
        <Grid item container flex={1} alignItems='center' justifyContent='center'>
          <ToggleButtonGroup value={question.payload?.align} onChange={handleChangeStar('align')} size='large' exclusive>
            <ToggleButton className={cnRating('toggle-button', { starAlign: true })} value={AlignEnum.left} key='left'>
              <ToolTip arrow theme={ColorTheme.dark} title='Слева' enterDelay={0}>
                <FormatAlignLeftIcon fontSize='large'/>
              </ToolTip>
            </ToggleButton>
            <ToggleButton className={cnRating('toggle-button', { starAlign: true })} value={AlignEnum.center} key='center'>
              <ToolTip arrow theme={ColorTheme.dark} title='По центру' enterDelay={0}>
                <FormatAlignCenterIcon fontSize='large'/>
              </ToolTip>
            </ToggleButton>
            <ToggleButton className={cnRating('toggle-button', { starAlign: true })} value={AlignEnum.right} key='right'>
              <ToolTip arrow theme={ColorTheme.dark} title='Справа' enterDelay={0}>
                <FormatAlignRightIcon fontSize='large'/>
              </ToolTip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      {/* Количество звёзд */}
      <Grid item container xs={12} xxl direction='column' alignItems='center' spacing={1}>
        <Grid item>
          <Typography variant='body1' component='div' align='center'>
            <strong>Количество звёзд</strong>
            <ToolTip arrow theme={ColorTheme.dark} placement='top' title='Для количества звёзд более 5 доступен только средний и мелкий размер. На экранах с небольшим разрешением мелкий размер будет отображаться как средний.'>
              <InfoOutlinedIcon fontSize='small' htmlColor='grey'/>
            </ToolTip>
          </Typography>
        </Grid>
        <Grid item container xs alignItems='center' justifyContent='center' columnSpacing={2}>
          <Grid item container xs={2} justifyContent='end'>
            <Button variant='outlined' size='large' color='secondary' onClick={handleChangeStarCount(starCount - 1)} fullWidth><RemoveIcon/></Button>
          </Grid>
          <Grid item container xs={2} sm={3} md={3} lg={3} xl={2} xxl={2} justifyContent='center'>
            <TextField
              className={cnRating('number-picker-field')}
              value={starCount}
              onChange={e => handleChangeStarCount(Number(e.target.value))()}
              variant='outlined'
              InputProps={{
                className: cnRating('number-picker-field', { styled: true }),
              }}
            />
          </Grid>
          <Grid item container xs={2} justifyContent='start'>
            <Button variant='outlined' size='large' color='secondary' onClick={handleChangeStarCount(starCount + 1)} fullWidth><AddIcon/></Button>
          </Grid>
        </Grid>
      </Grid>
      {/* Подпись для звёзд */}
      <Grid item container xs={12} direction='column' alignItems='center' spacing={1}>
        <Grid item>
          <Typography variant='body1' component='div' align='center'>
            <strong>Подпись для звёзд</strong>
          </Typography>
        </Grid>
        <Grid item container justifyContent='center' spacing={2}>
          {question.options?.map((x, i: number) => (i < starCount && <Grid item key={i}>
            <TextField
              value={x.title}
              helperText={`Звезда ${i + 1}`}
              onChange={handleChangeStarTitle(i)}
              variant='outlined'
              size='small'
              inputProps={{ maxLength: 30 }}
              FormHelperTextProps={{
                variant: 'standard',
              }}
            />
          </Grid>))}
        </Grid>
      </Grid>
    </Grid>

  </Grid>}</>)
}

export default RatingContainer
