import { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { Grid, CardMedia, Skeleton, Tooltip } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import bridge from 'smartapp-bridge'

import { useMst } from '@/Stores/rootStore'
import { FileMimeType, fileTypeNumToMime } from '@/Helpers/fileTypeNumToMime'
import { getColorByFileMimeType } from '@/Helpers/fileCardsColor'
import { getStaticsHybridUrl } from '@/Helpers/staticsHybridUrl'
import fileToBase64 from '@/Helpers/fileToBase64'
import { apiGetFileContentType, apiGetFileName } from '@/Api/file'
import BackendAdapter from '@/Api/_adapter'
import { FileType } from '@/Models/page'
import { IExpressFile } from '@/Models/express'
import { IFile } from '@/Models/file'
import { isExpressSmartApp } from '@/applicationConfig'

import { AttachFileIconStyled, CardActionsStyled, CardContentStyled, CardData, CardStyled, DeleteButtonStyled, ExtensionMark, AddFileTextStyled, CardNameStyled } from './styled'

export enum DisplayFileMode {
  File,
  PlaceHolder,
  Add,
}

export interface IFileItem {
  fileId?: string | null
  name?: string | null
  displayFileMode?: DisplayFileMode
  onDelete?: () => void
  onInputClick?: ((file: IFile) => void) | ((file: IFile) => Promise<void>)
  onCardClick?: () => void
  mimeType?: string
  acceptMimeList?: FileType[]
}

const imageDisplayFile = [FileMimeType.jpeg, FileMimeType.png] as string[]

const FileItem = ({ fileId, name, displayFileMode = DisplayFileMode.File, onDelete, onInputClick, onCardClick, mimeType, acceptMimeList }: IFileItem) => {
  const [fileName, setFileName] = useState<string>()
  const [fileExtension, setFileExtension] = useState<string>()
  const [fileContentType, setFileContentType] = useState<string | undefined>(mimeType)
  const [isLoading, setIsLoading] = useState(false)
  const [showActions, setShowActions] = useState(false)
  const UserStore = useMst().user

  useEffect(() => {
    (async () => {
      if (!fileId) return

      setIsLoading(true)
      let fullName = ''

      fullName = name ?? await apiGetFileName(fileId)

      const dotIndex = fullName.lastIndexOf('.')

      setFileName(fullName.slice(0, dotIndex))
      setFileExtension(fullName.slice(dotIndex + 1))

      setFileContentType(await apiGetFileContentType(fileId))

      setIsLoading(false)
    })()
  }, [fileId, name])

  const handleDelete = (event: MouseEvent<HTMLElement>) => {
    onDelete?.()

    event.stopPropagation()
  }

  const handleChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (isExpressSmartApp() || !e.target.files) {
      return
    }

    const formFile: File = e.target.files[0]

    const base64String = (await fileToBase64(formFile)
      .then(base64String => base64String)
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
      })).replace(/^data:([^;]+);base64,/, '')

    const file: IFile = {
      data: base64String,
      contentType: formFile.type,
      fileName: formFile.name,
      size: formFile.size,
    }

    await onInputClick?.(file)
  }

  const handleClickFileUpload = async () => {
    try {
      if (!isExpressSmartApp()) {
        return
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await bridge?.sendClientEvent({
        method: 'upload_file',
        params: {
          type: acceptMimeList?.map(x => fileTypeNumToMime(x)).toString(),
        },
      })

      if (!response.payload?.record) {
        return
      }

      const expressFile: IExpressFile = {
        ...response.payload.record,
      }

      const fileResponse = await BackendAdapter.get('/download_file', {
        params: {
          group_chat_id: UserStore.groupChatId,
          file_id: expressFile.fileId,
          is_preview: false,
        },
      }).then(x => x.data)

      const serverResponse = (fileResponse as string).split(',')[1].replaceAll('_', '/').replaceAll('-', '+')

      const file: IFile = {
        data: serverResponse,
        contentType: expressFile.fileMimeType ?? 'text/plain',
        fileName: expressFile.fileName ?? 'unnamed.txt',
        size: expressFile.fileSize ?? 0,
      }

      await onInputClick?.(file)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // eslint-disable-next-line no-console, @typescript-eslint/restrict-template-expressions
      console.error(`Ошибка загрузки файла: ${e}`)
    }
  }

  if (displayFileMode !== DisplayFileMode.Add && (fileContentType === undefined || displayFileMode === DisplayFileMode.PlaceHolder || isLoading)) return <Skeleton variant='rounded' width={124} height={80} />

  const viewWithImage = imageDisplayFile.includes(fileContentType ?? '')

  const cardColor = getColorByFileMimeType(fileContentType ?? '')

  const fileCardData = <>
    {viewWithImage && <CardMedia
      component='img'
      width={124}
      height={80}
      image={getStaticsHybridUrl(`/api/file/image/${fileId ?? ''}`)}
      alt='card'
      className={showActions ? 'blur-image' : ''}
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        height: '100%',
        width: '100%',
      }}
    />}
    <CardContentStyled>
      <ExtensionMark style={{ backgroundColor: cardColor[0] }} className={showActions || viewWithImage ? 'hidden' : ''}>{fileExtension?.toUpperCase()}</ExtensionMark>
      <CardNameStyled variant='caption' className={viewWithImage && !showActions ? 'hidden' : ''}>{fileName}</CardNameStyled>
      {onDelete && <CardActionsStyled className={!showActions ? 'hidden' : ''} disableSpacing>
        <DeleteButtonStyled disableRipple onClick={handleDelete}>
          <DeleteOutlineIcon sx={{ color: '#FFFFFF' }} fontSize='small' />
        </DeleteButtonStyled>
      </CardActionsStyled>}
    </CardContentStyled>
  </>

  const addCardData = <Grid container direction='column' alignItems='center' justifyContent='center' gap={1} height='100%'>
    <AttachFileIconStyled fontSize='inherit' />
    <Tooltip title='Выберите файл для загрузки'>
      <AddFileTextStyled variant='caption'>Загрузить файл</AddFileTextStyled>
    </Tooltip>
  </Grid>

  return <CardStyled
    variant={ onInputClick ? 'outlined' : 'elevation' }
    className={ onInputClick ? 'action-card' : '' }
    onMouseOver={() => setShowActions(true)}
    onMouseLeave={() => setShowActions(false)}
    onClick={onCardClick ?? (onInputClick && handleClickFileUpload)}
  >
    <CardData
      className={ !!onInputClick || !!onCardClick ? 'action-card-data' : '' }
      sx={{ backgroundColor: displayFileMode === DisplayFileMode.File ? cardColor[1] : '#FFFFFF' }}
    >
      <input
        disabled={!onInputClick || isExpressSmartApp()}
        style={{ width: '124px', height: '80px' }}
        hidden
        type='file'
        accept={acceptMimeList?.map(x => fileTypeNumToMime(x)).toString()}
        multiple={false}
        onChange={handleChangeFile}
      />
      {displayFileMode === DisplayFileMode.File ? fileCardData : addCardData}
    </CardData>
  </CardStyled>
}

export default FileItem
