import { ICreatePageModel } from '@/Pages/Admin/EditPage/Custom'

export const checkValidationState = (pageModel: ICreatePageModel) => {
  return !pageModel.validateResult?.titleError &&
      !pageModel.validateResult?.emptyQuestionCollectionError &&
      pageModel.questions.every(q => !q.validationResult?.emptyHelperText &&
        !q.validationResult?.emptyQuestionText &&
        !q.validationResult?.emptySkipText &&
        q.options.every(x => !x.validationResult?.duplicateError && !x.validationResult?.imageRequiredError))
}
