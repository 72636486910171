import { IShortPageInfo } from '@/Pages/Admin/SurveyInfoPage/PagesRepresentationTab/Models/models'
import BackendAdapter from '@/Api/_adapter'

export const apiGetPage = async (pageId: string) => {
  try {
    return await BackendAdapter.get(`/api/pages/${pageId}`).then(resp => resp.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetPageByServiceId = async (surveyId: string, serviceId: string) => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/pages/${serviceId}`, {})
      .then(resp => castToPageModel(resp.data))
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetActivePagesBySurveyId = async (surveyId: string) => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/pages/active`)
      .then(resp => castToPagesModel(resp.data))
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiSetPageLogo = async (surveyId: string, serviceId: string, imageId: string) => {
  try {
    return await BackendAdapter.post('/api/pages/logo', { surveyId, serviceId, imageId })
      .then(resp => resp.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiSetGvkPage = async (page: any) => {
  try {
    return await BackendAdapter.post('/api/pages/gvk', page)
      .then(resp => resp.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiSetCustomPages = async (surveyId: string, pages: any) => {
  try {
    return await BackendAdapter.put(`/api/surveys/${surveyId}/pages`, {
      pages,
    }, {
      params: {
        type: 'custom',
      },
    }).then(resp => resp.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiSetCustomPagesSettings = async (surveyId: string, pageSettings: IShortPageInfo[]) => {
  try {
    return await BackendAdapter.post(`/api/surveys/${surveyId}/pages/custom/settings`, pageSettings)
      .then(resp => resp.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiFastGvkPageFilling = async (surveyId: string, defaultImageId: string, serviceIds: string[]): Promise<boolean> => {
  try {
    return await BackendAdapter.post('/api/page/gvk/fastFilling', { surveyId, defaultImageId, serviceIds })
      .then(res => res.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiSyncPagesWithService = async (surveyId: string) => {
  try {
    return await BackendAdapter.post(`/api/surveys/${surveyId}/pages/syncWithService`, {})
      .then(resp => resp.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiRemoveListPage = async (surveyId: string, serviceIds: string[]) => {
  try {
    return await BackendAdapter.delete(`/api/surveys/${surveyId}/pages`, {
      params: {
        serviceIds,
      },
    }).then(resp => resp.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiChangeServices = async (surveyId: string, serviceIdByPageId: Array<{ pageId: string, serviceId: string | null }>) => {
  try {
    return await BackendAdapter.put('/api/pages/changeServices', { surveyId, serviceIdByPageId })
      .then(resp => resp.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

export const apiGetUserPageHistory = async (surveyId: string) => {
  try {
    return await BackendAdapter.get(`/api/surveys/${surveyId}/pages/history`)
      .then(resp => resp.data)
  } catch (err: any) {
    console.log(err)
    throw err
  }
}

// Избавиться от any
const castToPagesModel = (models: any[]) => {
  return models.map(castToPageModel)
}

const castToPageModel = (page: any) => ({
  ...page,
  questions: page.questions?.map((question: any) => ({
    ...question,
    payload: question.payload,
  })),
})
