import { ChangeEvent, useEffect, useState } from 'react'
import {
  Autocomplete,
  Checkbox,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material'
import { ru } from 'date-fns/locale'
import { v4 as uuidv4 } from 'uuid'
import { CSSTransition } from 'react-transition-group'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers'
import { useSessionStorage } from 'usehooks-ts'
import { sortBy } from 'lodash'

import { useRouteManager } from '@/Hooks'
import { htmlTagsIsEmpty } from '@/Helpers/htmlToTextCustom'
import { getStaticsHybridUrl } from '@/Helpers/staticsHybridUrl'
import { IPermissionsWithUsers, PermissionType } from '@/Models/permission'
import { apiGetSurveyPermissions, apiSetSurveyPermissions } from '@/Api/survey'
import { apiGetGlobalServices } from '@/Api/service'
import { Button, PhotoInput } from '@/Components'
import { useMst } from '@/Stores/rootStore'
import {
  CreteOrUpdateSurveyInterface,
  ISurveyInstance,
  SurveyType,
  TargetAudiencesMappingType,
  SurveyStatus,
} from '@/Stores/AdminStores/surveysStore'
import { IServiceIn } from '@/Stores/AdminStores/adminServicesStore'
import Error from '@/Components/Error'
import { IMainQuestion, IQuestion, QuestionStyle } from '@/Models/pageEdit'
import { CommentType, QuestionType } from '@/Models/page'
import { applicationConfig, defaultImageHash, FrontendFeature } from '@/applicationConfig'
import TextLink from '@/Components/TextLink'
import TextEditor from '@/Components/TextEditor'
import ImageDefault from '@/Images/default.jpg'
import { IOrganizationStructureElementModel, IOrganizationStructureUser } from '@/Models/organizationStructure'
import { ResultAccess as UserShortResultAccess } from '@/Models/targetAudience'
import { RouteEnum } from '@/Enums/route'
import { AlignEnum, SizeEnum } from '@/Enums/ratingPayloadEnum'

import { MainQuestionBlock } from './MainQuestionBlock'
import {
  MainContainer,
  MainContainerCalendar,
  MainQuestionsContainer,
  PageContainer,
  TextContainer,
  TitleContainer,
} from './styled'

import './style.scss'

interface IFields {
  title: string
  description: string
  type: SurveyType
  end: Date
  imageId?: string
  targetServiceId?: string
  targetAudiencesMappingType?: TargetAudiencesMappingType
  version?: number
}

interface ISurveyModal {
  isOpen: boolean
  handleClose: ((surveyId?: string) => void) | ((surveyId?: string) => Promise<void>)
  survey?: ISurveyInstance
}

const defaultQuestion: IQuestion = {
  text: '<p><span style="font-size: 21px"><strong>​</strong></span></p>',
  type: QuestionType.slider,
  commentType: CommentType.VisibleToAndRequiredTo,
  options: [
    { value: '0', imageId: null, title: null, isCustom: false },
    { value: '10', imageId: null, title: null, isCustom: false },
  ],
  isMain: true,
  helperText: undefined,
  showHelperText: false,
  showIsAnonym: false,
  isRequired: true,
  hasCustomAnswer: false,
  payload: {
    helperStartText: 'Точно не порекомендую',
    helperEndText: 'Точно порекомендую',
    notReadyToRateText: '',
    showNotReadyToRate: false,
    requireCommentValue: '6',
    isEnabledLoadFileButton: false,
    maxFileCount: 0,
    limitFileType: false,
    acceptFileTypes: [],
    adminFiles: [],
    questionStyle: QuestionStyle.Default,
    size: SizeEnum.medium,
    align: AlignEnum.left,
  },
}

const defaultGvkQuestion: IQuestion = {
  text: '<p><span style="font-size: 21px"><strong>Оцените, насколько вы удовлетворены предоставляемым сервисом?</strong></span></p>',
  type: QuestionType.slider,
  commentType: CommentType.VisibleToAndRequiredTo,
  options: [
    { value: '0', imageId: null, title: null, isCustom: false },
    { value: '10', imageId: null, title: null, isCustom: false },
  ],
  isMain: true,
  helperText: 'Оцените по шкале от 0 до 10, где 0 – совершенно неудовлетворён, 10 – полностью удовлетворён',
  showHelperText: true,
  showIsAnonym: false,
  isRequired: true,
  hasCustomAnswer: false,
  payload: {
    helperStartText: 'Совершенно неудовлетворён',
    helperEndText: 'Полностью удовлетворён',
    notReadyToRateText: '',
    showNotReadyToRate: false,
    requireCommentValue: '6',
    isEnabledLoadFileButton: false,
    maxFileCount: 0,
    limitFileType: false,
    acceptFileTypes: [],
    adminFiles: [],
    questionStyle: QuestionStyle.Default,
    size: SizeEnum.medium,
    align: AlignEnum.left,
  },
}

const surveyModalOrganizationStructureStateSelector = 'survey-modal-organization-structure-state-selector'

const getDefaultTargetAudienceMappingType = (feature: FrontendFeature[]) => {
  const targetAudienceByService = feature.includes(FrontendFeature.service)
  const targetAudienceByOrganizationStructure = feature.includes(FrontendFeature.organizationStructure)

  if (targetAudienceByService && !targetAudienceByOrganizationStructure) {
    return TargetAudiencesMappingType.BySurveyService
  }

  return TargetAudiencesMappingType.BySurveyTargetAudience
}

const SurveyModal = ({ isOpen, handleClose, survey }: ISurveyModal) => {
  const store = useMst()
  const surveysStore = store.admin.surveysStore
  const targetAudienceStore = store.admin.organizationStructureStore
  const [serverFetching, setServerFetching] = useState(false) // TODO: заменить на useWaiter
  const [questions, setQuestions] = useState<IMainQuestion[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [errors, setErrors] = useState<any>({ err: false })
  const [users, setUsers] = useState<IOrganizationStructureElementModel[]>([])
  const [permissions, setPermissions] = useState<IPermissionsWithUsers[]>([])
  const [globalServices, setGlobalServices] = useState<IServiceIn[]>([])
  const routeManager = useRouteManager()
  const [editorIsOpen, setEditorIsOpen] = useState(false)

  const [userShortResultAccess, setUserShortResultAccess] = useState<UserShortResultAccess>(UserShortResultAccess.None)

  const [currentSurvey, setCurrentSurvey] = useState<IFields>({
    title: survey?.title ?? '',
    description: survey?.description ?? '',
    type: survey?.type ?? SurveyType.Custom,
    end: survey?.end ?? new Date(),
    imageId: survey?.imageId ?? undefined,
    targetServiceId: survey?.targetServiceId ?? undefined,
    targetAudiencesMappingType: survey?.targetAudiencesMappingType ?? getDefaultTargetAudienceMappingType(applicationConfig?.frontendFeatures ?? []),
    version: survey?.version,
  })

  const [endDate, setEndDate] = useState(currentSurvey.end)

  const showServiceSelection = ([TargetAudiencesMappingType.BySurveyService, TargetAudiencesMappingType.ByPageService] as Array<TargetAudiencesMappingType | undefined>).includes(currentSurvey?.targetAudiencesMappingType)
  const showExpertSelection = permissions.some(x => x.type === PermissionType.expert)
  const [sessionStorageCurrentSessionSurveyIds, setSessionStorageCurrentSessionSurveyIds] = useSessionStorage('current_session_survey_ids', [''])

  useEffect(() => {
    (async () => {
      // await apiSyncUsers()

      if (survey) {
        const permissions = await apiGetSurveyPermissions(survey.surveyId)
        setPermissions(permissions)

        const elementsWithUserShortStatisticPermission = permissions.find(x => x.type === PermissionType.shortAnswersView)?.organizationStructureElementsId ?? []

        await targetAudienceStore.loadWithSelected(surveyModalOrganizationStructureStateSelector, elementsWithUserShortStatisticPermission)

        if (elementsWithUserShortStatisticPermission.includes(targetAudienceStore.rootElement ?? '')) {
          setUserShortResultAccess(UserShortResultAccess.All)
        } else {
          setUserShortResultAccess(UserShortResultAccess.None)
        }
      } else {
        await targetAudienceStore.loadWithSelected(surveyModalOrganizationStructureStateSelector, [])
      }

      setUsers(targetAudienceStore.getAllOrganizationStructureElements(surveyModalOrganizationStructureStateSelector).filter(x => x.isUser))

      setQuestions(survey?.getMainQuestionsAsIMainQuestion() ?? [])

      const services = await apiGetGlobalServices()
      setGlobalServices(sortBy(services, ['name']))

      setIsLoading(false)
    })()
  }, [survey])

  const handleChangeQuestion = (prevValue: IMainQuestion, newValue: IMainQuestion, e?: ChangeEvent<{ value: unknown }>) => {
    const newQuestions = [...questions.map(x => (x !== prevValue ? x : { ...newValue }))]
    setQuestions(newQuestions)
    let newErrors = { ...errors }
    if (prevValue.text !== newValue.text) newErrors = { ...newErrors, text: false }
    setErrors({ ...newErrors })
  }

  const handleAddQuestion = () => {
    setQuestions(prev => [...prev, { ...defaultQuestion, id: uuidv4() }])
  }

  const validate = (): boolean => {
    let newErrors = {}
    if (!currentSurvey?.title) {
      newErrors = { ...newErrors, 'title': true }
    }
    if (!currentSurvey?.description) {
      newErrors = { ...newErrors, 'description': true }
    }
    questions.map((x, i) => {
      let questionError = {}

      if (htmlTagsIsEmpty(x.text)) questionError = { ...questionError, emptyQuestionText: true }
      if (x.showHelperText && !x.helperText) questionError = { ...questionError, emptyHelperText: true }
      if (x.payload?.showNotReadyToRate && !x.payload?.notReadyToRateText) questionError = { ...questionError, emptySkipText: true }

      if (Object.keys(questionError).length > 0) newErrors = { ...newErrors, [i]: questionError }
    })

    if (currentSurvey?.type === SurveyType.Repeatable && questions.length === 0) newErrors = { ...newErrors, zeroMainQuestions: true }

    setErrors({ ...newErrors })

    return Object.keys(newErrors).length === 0
  }

  const getDefaultSurveyTargetAudiencesMappingTypeBySyrveyType = (type: SurveyType) => {
    switch (type) {
      case SurveyType.Gvk: return TargetAudiencesMappingType.ByPageService
      case SurveyType.Custom: return TargetAudiencesMappingType.BySurveyTargetAudience
      case SurveyType.Repeatable: return TargetAudiencesMappingType.ByPageTargetAudience
      default: return TargetAudiencesMappingType.BySurveyTargetAudience
    }
  }

  const handleSave = (openAfterSave: boolean) => async () => {
    if (!validate()) {
      return
    }
    setServerFetching(true)
    const newSurveyModel: CreteOrUpdateSurveyInterface = {
      ...currentSurvey,
      surveyId: survey?.surveyId,
      imageId: currentSurvey?.imageId ?? defaultImageHash,
      withCards: [SurveyType.Repeatable, SurveyType.Gvk].includes(currentSurvey.type),
      targetAudiencesMappingType: currentSurvey.targetAudiencesMappingType ?? getDefaultSurveyTargetAudiencesMappingTypeBySyrveyType(currentSurvey.type),
      mainQuestions: [...questions.map(x => ({
        ...x,
        commentType: x.commentType ?? CommentType.AlwaysVisibleAndRequired,
        helperText: x.helperText ?? '',
        showHelperText: x.showHelperText ?? false,
        showIsAnonym: x.showIsAnonym ?? false,
        payload: !x.payload ? undefined : {
          ...x.payload,
          acceptFileTypes: [...x.payload.acceptFileTypes],
          adminFiles: x.payload.adminFiles.map(f => ({ ...f })),
        },
      }))],
    }
    let createdSurveyId
    if (survey) await surveysStore.updateSurvey(newSurveyModel)
    else createdSurveyId = await surveysStore.addSurvey(newSurveyModel)

    let allPermissions = permissions
    switch (userShortResultAccess) {
      case UserShortResultAccess.None: {
        allPermissions = [...permissions.filter(x => x.type !== PermissionType.shortAnswersView)]
        break
      }
      case UserShortResultAccess.All: {
        if (targetAudienceStore.rootElement) {
          allPermissions = [...permissions.filter(x => x.type !== PermissionType.shortAnswersView), { type: PermissionType.shortAnswersView, organizationStructureElementsId: [targetAudienceStore.rootElement] }]
        }
        break
      }
    }

    // store.last != null т.к. в store сохраняется survey ранее
    const surveyId = survey?.surveyId ?? surveysStore.last?.surveyId ?? ''
    await apiSetSurveyPermissions(surveyId, { permissions: allPermissions.map(x => ({ ...x, surveyId })) })

    await handleClose(surveyId)

    if (openAfterSave) {
      routeManager.redirectToUrl(RouteEnum.survey, surveyId)
    }

    if (!createdSurveyId) return
    const newCurrentSessionSurveyIds = sessionStorageCurrentSessionSurveyIds
    newCurrentSessionSurveyIds.push(createdSurveyId)
    setSessionStorageCurrentSessionSurveyIds(newCurrentSessionSurveyIds)
  }

  const handleChangeString = (text: string, field: keyof IFields, minLength?: number, maxLength?: number) => {
    if (((minLength ?? 0) && text.length > (minLength ?? 0)) || !minLength) setErrors((prev: any) => ({ ...prev, [field]: false }))
    if (!maxLength || text.length <= maxLength) {
      if (field === 'type') {
        setCurrentSurvey(prev => ({ ...prev, [field]: text as SurveyType, targetServiceId: undefined }))
      } else {
        setCurrentSurvey(prev => ({ ...prev, [field]: text }))
      }
    }
  }

  const handleChangeSurveyType = (e: SelectChangeEvent) => {
    handleChangeString(e.target.value, 'type')

    // Гвк не может быть с ца по орг структуре, если переключили на гвк, ставить ца с сервисом на весь опрос
    if ((e.target.value as SurveyType) === SurveyType.Gvk) {
      setCurrentSurvey(prev => ({ ...prev, targetAudiencesMappingType: TargetAudiencesMappingType.ByPageService }))
      setQuestions([{ ...defaultGvkQuestion, id: uuidv4() }])
    } else if ((e.target.value as SurveyType) === SurveyType.Repeatable) {
      setQuestions([{ ...defaultQuestion, id: uuidv4() }])
    } else {
      setQuestions([])
    }
  }

  const handleChangeEnd = (date: Date | null) => {
    const endDate = new Date(date ?? new Date())
    setCurrentSurvey(prev => ({
      ...prev,
      end: endDate,
    }))
    setErrors({ ...errors, end: false })
    setEndDate(endDate)
  }

  const onPhotoChange = (imageId: string) => {
    setCurrentSurvey(prev => ({ ...prev, imageId }))
  }

  const handleDeleteQuestion = (question: IMainQuestion) => () => {
    setQuestions(questions.filter(x => x !== question))
  }

  const handleExpertPermissionsCheckbox = () => {
    if (!permissions.some(x => x.type === PermissionType.expert)) {
      setPermissions(prev => [...prev, { type: PermissionType.expert, organizationStructureElementsId: [] }])
    } else {
      setPermissions(prev => [...prev.filter(x => x.type !== PermissionType.expert)])
    }
  }

  const handleExpertPermissionsChange = (e: ChangeEvent<{}>, value: IOrganizationStructureUser[]) => {
    setPermissions(prev => [...prev.map(x => (x.type !== PermissionType.expert ? x : { ...x, organizationStructureElementsId: [...value.map(x => x.id)] }))])
  }

  const handleTargetAudienceMappingTypeCheckbox = () => {
    if (currentSurvey.targetAudiencesMappingType !== TargetAudiencesMappingType.BySurveyService) {
      setCurrentSurvey(prev => ({ ...prev, targetAudiencesMappingType: TargetAudiencesMappingType.BySurveyService }))
      setPermissions(prev => [...prev.filter(x => x.type !== PermissionType.expert)])
      setUserShortResultAccess(UserShortResultAccess.None)
    } else {
      setCurrentSurvey(prev => ({ ...prev, targetAudiencesMappingType: TargetAudiencesMappingType.BySurveyTargetAudience, targetServiceId: undefined }))
    }
  }

  return (<Dialog onClose={async () => { await handleClose() }} open={isOpen} className='polls-survey-dialog'>
    <MainContainer>
      <PageContainer>
        <h2>{survey ? 'Редактирование опроса' : 'Создание опроса'}</h2>
      </PageContainer>
      <MainContainerCalendar>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '100%' }}>
            <TitleContainer>
              <TextField
                variant='standard'
                label='Название'
                placeholder='Введите название'
                value={currentSurvey.title}
                onChange={e => handleChangeString(e.target.value, 'title', 0, 101)}
                error={errors.title}
                helperText={`${currentSurvey.title.length}/100`}
                disabled={!!survey?.archivedDate}
              />
            </TitleContainer>
            <TitleContainer>
              <TextEditor
                title='Введите описание'
                placeholder='Описание'
                value={currentSurvey.description}
                isOpen={editorIsOpen}
                onOpen={() => setEditorIsOpen(true)}
                onClose={() => setEditorIsOpen(false)}
                onChange={(value) => handleChangeString(value, 'description', 0)}
                error={errors.description}
                disabled={!!survey?.archivedDate}
                disableImage
              />
            </TitleContainer>
            <TitleContainer>
              <FormControl variant='standard' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <InputLabel shrink id="type-label">
                  Тип запуска
                </InputLabel>
                <Select
                  labelId="type-label"
                  value={currentSurvey.type}
                  onChange={handleChangeSurveyType}
                  disabled={!!survey}
                >
                  <MenuItem value={SurveyType.Custom}>Кастомный</MenuItem>
                  {applicationConfig?.frontendFeatures.includes(FrontendFeature.gvk) && <MenuItem value={SurveyType.Gvk}>ГВК</MenuItem>}
                  <MenuItem value={SurveyType.Repeatable}>Постоянный</MenuItem>
                </Select>
              </FormControl>
            </TitleContainer>
            <TitleContainer>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
                <DatePicker
                  inputFormat="dd.MM.yyyy"
                  label="Дата завершения"
                  disablePast={true}
                  value={endDate}
                  disabled={!!survey && !!survey.archivedDate}
                  onOpen={() => setEndDate(endDate < new Date() ? new Date() : currentSurvey.end)}
                  onChange={handleChangeEnd}
                  renderInput={(params) => <TextField id="date-picker-inline" {...params} variant="standard" />}
                />
              </LocalizationProvider>
            </TitleContainer>
            <TitleContainer className='target-audience-mapping-type soft-show-hide-animation'>
              {(applicationConfig?.frontendFeatures.includes(FrontendFeature.organizationStructure) && applicationConfig?.frontendFeatures.includes(FrontendFeature.service)) && currentSurvey.type !== SurveyType.Gvk && <div className='polls-checkbox-container'>
                {isLoading ? <Skeleton variant='circular' className='polls-checkbox-skeleton polls-checkbox' /> : <Checkbox
                  id='target-audience-mapping-type-checkbox'
                  className='polls-checkbox'
                  checked={showServiceSelection}
                  onChange={handleTargetAudienceMappingTypeCheckbox}
                  disabled={!!survey}
                />}
                <label className={`polls-checkbox-label ${survey ? 'disabled' : ''}`} htmlFor='target-audience-mapping-type-checkbox'><Typography>Ца по сервисам</Typography></label>
              </div>}
            </TitleContainer>
            <div className='soft-show-hide-animation'>
              <CSSTransition
                in={!showServiceSelection}
                timeout={300}
                unmountOnExit
              >
                <TitleContainer className='permission-target-audience-mapping'>
                  {isLoading ? <Skeleton variant='rectangular' height='48px' /> : <FormControl variant='standard' fullWidth>
                    <InputLabel id="result-access">Результаты доступны</InputLabel>
                    <Select
                      labelId="result-access"
                      value={userShortResultAccess}
                      label="Результаты доступны"
                      onChange={(e: SelectChangeEvent) => { setUserShortResultAccess(e.target.value as UserShortResultAccess) }}
                      disabled={!!survey?.archivedDate}
                    >
                      {Object.values(UserShortResultAccess).map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)}
                    </Select>
                  </FormControl>}
                </TitleContainer>
              </CSSTransition>
            </div>
            <div className='soft-show-hide-animation'>
              <CSSTransition
                in={!showServiceSelection}
                timeout={300}
                unmountOnExit
              >
                <TitleContainer className='expert-settings soft-show-hide-animation'>
                  <div className='polls-checkbox-container'>
                    {isLoading ? <Skeleton variant='circular' className='polls-checkbox-skeleton polls-checkbox' /> : <Checkbox id='expertsCheckbox'
                      className='polls-checkbox'
                      checked={showExpertSelection}
                      onChange={handleExpertPermissionsCheckbox}
                      disabled={!!survey?.archivedDate}
                    />}
                    <label className='polls-checkbox-label' htmlFor='expertsCheckbox'><Typography>Полные отчеты доступны следующим Экспертам</Typography></label>
                  </div>
                  <CSSTransition
                    in={showExpertSelection}
                    timeout={300}
                    unmountOnExit
                  >
                    <Autocomplete
                      className='experts-autocomplete'
                      multiple
                      value={(permissions.find(x => x.type === PermissionType.expert)?.organizationStructureElementsId.map(organizationStructureElementId => users.find(user => user.id === organizationStructureElementId)).filter(x => !!x) ?? []) as IOrganizationStructureElementModel[]}
                      options={users}
                      getOptionLabel={(user) => user?.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='standard'
                          label='Выбрать эксперта'
                          placeholder='Выбрать эксперта'
                        />
                      )}
                      onChange={handleExpertPermissionsChange}
                      disabled={!!survey?.archivedDate}
                    />
                  </CSSTransition>
                </TitleContainer>
              </CSSTransition>
            </div>
          </div>
          <TitleContainer>
            <PhotoInput
              imageUrl={currentSurvey.imageId ? getStaticsHybridUrl(`/api/file/image/${currentSurvey.imageId}`) : undefined}
              defaultImage={ImageDefault}
              onChange={onPhotoChange}
              cropWidth={256}
              cropHeight={149}
              width={256}
              height={149}
              disabled={!!survey?.archivedDate}
            />
          </TitleContainer>
        </div>
      </MainContainerCalendar>
      <MainQuestionsContainer>
        {isLoading && survey && questions.length ? <Skeleton variant='rectangular' width='100%' height={401} style={{ marginBottom: '10px' }}/> : questions.map((x, i) => <MainQuestionBlock
          key={i}
          handleChangeQuestion={handleChangeQuestion}
          onDelete={handleDeleteQuestion(x)}
          question={x}
          // В архиве - точно нельзя редактировать, если опрос имел запуски или запущен - нельзя редактировать
          disabled={!!survey?.archivedDate || (!!survey && (currentSurvey.version !== 0 || survey.status === SurveyStatus.Surveyed))}
          surveyType={currentSurvey.type}
          errors={errors[i]}
          showDeleteButton={currentSurvey.type === SurveyType.Repeatable && questions.length > 1}
          questionLabel={`Вопрос ${i + 1}`}
        />)}
        {/* Характерно только для периодических опросов, не в архиве, и до первого запуска (т.к. не в состоянии "запущено" и не с версией больше 0),  */}
        {currentSurvey.type === SurveyType.Repeatable && !survey?.archivedDate && (!survey || (survey.status !== SurveyStatus.Surveyed && currentSurvey.version === 0)) && <TextLink
          disabled={isLoading && survey && currentSurvey.type === SurveyType.Repeatable}
          text='Добавить основной вопрос'
          color="#266DC2"
          style={{ marginTop: '17px', lineHeight: '18px' }}
          onClick={isLoading && survey && currentSurvey.type === SurveyType.Repeatable ? () => {} : handleAddQuestion}
        />}
      </MainQuestionsContainer>
      <div className='bottom'>
        <TextContainer>
          <Error isVisible={Object.values(errors).some(x => x)}>Заполните все поля</Error>
        </TextContainer>
        <div className='button-container'>
          <Button
            onClick={handleSave(!survey)}
            disabled={serverFetching || !!survey?.archivedDate}
            color='primary'
          >
            {survey ? 'Сохранить' : 'Далее'}
          </Button>
        </div>
      </div>
    </MainContainer>
  </Dialog>)
}

export default SurveyModal
