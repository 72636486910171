import { useParams } from 'react-router'

import { useMst } from '../../../Stores/rootStore'
import { SurveyType } from '../../../Stores/AdminStores/surveysStore'
import { useRouteManager } from '../../../Hooks/useRouteManager'
import { RouteEnum } from '../../../Enums/route'

import CustomEditPage from './Custom'
import GvkEditPage from './Gvk'

const EditPage = () => {
  const { surveyId } = useParams<{ surveyId: string }>()
  const survey = useMst().admin.surveysStore.getSurvey(surveyId)
  const routeManager = useRouteManager()

  if (!survey) {
    routeManager.redirectTo(RouteEnum.survey)
    return <></>
  }

  if (survey.type === SurveyType.Gvk) return <GvkEditPage />

  return <CustomEditPage isCardState={survey.withCards} />
}

export default EditPage
