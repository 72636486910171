import { ChangeEvent, useCallback, useState } from 'react'
import { Card, CardContent, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { IMainQuestion } from '@/Models/pageEdit'
import { SurveyType } from '@/Stores/AdminStores/surveysStore'
import { IQuestionValidationResult } from '@/Pages/Admin/EditPage/Models/pageModel'
import SliderContainer from '@/Pages/Admin/EditPage/Components/Question/SliderContainer'
import TextEditor from '@/Components/TextEditor'

import { ColumnContentContainer, Container, QuestionContainer, QuestionTypeContainer } from './styled'

export interface IMainQuestionBlock {
  handleChangeQuestion: (prevValue: IMainQuestion, newValue: IMainQuestion, e?: ChangeEvent<{ value: unknown }>) => void
  onDelete: () => void
  question: IMainQuestion
  disabled: boolean
  surveyType: SurveyType
  errors?: IQuestionValidationResult
  questionLabel?: string
  showDeleteButton?: boolean
}

export const MainQuestionBlock = ({ question, handleChangeQuestion, onDelete, disabled, surveyType, errors = {}, questionLabel = 'Вопрос', showDeleteButton = false }: IMainQuestionBlock) => {
  const [editorIsOpen, setEditorIsOpen] = useState(false)

  const handleChangeQuestionText = useCallback((value: string) => {
    const newQuestion = { ...question, text: value }

    if (newQuestion.validationResult) {
      newQuestion.validationResult.emptyQuestionText = false
    }

    handleChangeQuestion(question, newQuestion)
  }, [question, handleChangeQuestion])

  question.validationResult = errors

  return <Card style={{ marginBottom: '20px' }}>
    <CardContent>
      <Container>
        <QuestionContainer>
          <TextEditor
            title='Редактирование вопроса'
            placeholder='Введите текст вопроса'
            value={question.text}
            isOpen={editorIsOpen}
            onOpen={() => setEditorIsOpen(true)}
            onClose={() => setEditorIsOpen(false)}
            onChange={handleChangeQuestionText}
            error={!!errors.emptyQuestionText}
            disabled={disabled}
          />
          <SliderContainer
            question={question}
            handleChangeQuestion={handleChangeQuestion}
            showNotReadyToRateBlock={true}
            showRequireCommentValueBlock={true}
            disabled={disabled}
            showAnonymBlock={surveyType === SurveyType.Gvk}
          />
        </QuestionContainer>
        <ColumnContentContainer>
          <IconButton sx={{ visibility: showDeleteButton ? '' : 'hidden' }} disabled={disabled} onClick={onDelete}>
            <CloseIcon/>
          </IconButton>
          <QuestionTypeContainer>
            Тип обязательного вопроса
            <br/>
            <span>Шкала</span>
          </QuestionTypeContainer>
        </ColumnContentContainer>
      </Container>
    </CardContent>
  </Card>
}

export default MainQuestionBlock
